import React, {forwardRef} from 'react'
import Feedback from './Feedback'
import {VirtuosoGrid, Virtuoso} from 'react-virtuoso'
import Footer from './Footer'

const gridComponents = {
  List: forwardRef(({style, children, ...props}, ref) => (
    <div
      ref={ref}
      {...props}
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        ...style,
      }}
    >
      {children}
    </div>
  )),
  Item: ({children, ...props}) => (
    <div
      {...props}
      style={{
        padding: '0.5rem',
        display: 'flex',
        flex: 'none',
        alignContent: 'stretch',
        boxSizing: 'border-box',
      }}
    >
      {children}
    </div>
  ),
}

const ItemWrapper = ({children, ...props}) => (
  <div
    {...props}
    style={{
      display: 'flex',
      flex: 1,
      width: '250px',
      maxWidth: '250px',
      //      border: '1px solid gray',
    }}
  >
    {children}
  </div>
)

const FeedbacksList = ({feedbacks = [], onLoadMore = () => {}}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
      }}
    >
      {/*
        <VirtuosoGrid
          style={{ height: 500, width: '100%' }}
          totalCount={feedbacks.length}
          components={gridComponents}
          data={feedbacks}
          endReached={() => {
            console.log('end reached')
            console.log(onLoadMore)
            if (typeof onLoadMore === 'function') onLoadMore()
          }}
          itemContent={(index, feedback) => {
            return (
              <ItemWrapper>
                <Feedback
                  key={feedback._id}
                  name={feedback?.userId?.displayName || 'User'}
                  feedback={feedback.feedback}
                  rating={feedback.rating}
                  date={feedback.createdAt}
                />
                {/*
              <Feedback
                key={feedback._id}
                name={feedback?.userId?.displayName || 'User'}
                feedback={feedback.feedback}
                rating={feedback.rating}
                date={feedback.createdAt}
              />
              }
              </ItemWrapper>
            )
          }}
        />
       */}

      {
        <Virtuoso
          totalCount={feedbacks.length}
          style={{
            height: '100vh',
            width: '100vw',
            display: 'flex',
            flexWrap: 'wrap',
          }}
          endReached={() => {
            if (typeof onLoadMore === 'function') onLoadMore()
          }}
          data={feedbacks}
          itemContent={(key, feedback) => {
            return (
              <Feedback
                key={feedback._id}
                name={feedback?.userId?.displayName || 'User'}
                feedback={feedback.feedback}
                rating={feedback.rating}
                date={feedback.createdAt}
              />
            )
          }}
        />
      }
    </div>
  )
}

export default FeedbacksList
