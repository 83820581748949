import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
// routes
import AppRoute from './routes'
// theme
import ThemeConfig from './theme'
// components
// import Settings from './components/settings';
import RtlLayout from './components/RtlLayout'
import ScrollToTop from './components/ScrollToTop'
import NotistackProvider from './components/NotistackProvider'
import ThemePrimaryColor from './components/ThemePrimaryColor'
import ThemeLocalization from './components/ThemeLocalization'
import { MainProvider } from './utils/context/main'
import { UsersProvider } from './utils/context/users'
import { SocketProvider } from './utils/context/socket'
import useNetworkSpeedCheck from './utils/hooks/useNetworkSpeedCheck';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  DialogTitle,
  Typography
} from '@material-ui/core'
// ----------------------------------------------------------------------

import settings_api from './utils/api/settings';
import storage from './utils/storage';

export default function App() {
  const navigate = useNavigate();

  // Auto-refresh on tab visibility change to fix white screen issue
/*  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        window.location.reload();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => document.removeEventListener('visibilitychange', handleVisibilityChange);
  }, []);*/

  const { open, message, setOpen } = useNetworkSpeedCheck();

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          <Typography variant="h6" fontWeight="bold">
            Network Alert
          </Typography>
        </DialogTitle>
        <DialogContent>{message}</DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <ThemeConfig>
        <ThemePrimaryColor>
          <ThemeLocalization>
            <MainProvider>
              <UsersProvider>
                <SocketProvider>
                  <RtlLayout>
                    <NotistackProvider>
                      <ScrollToTop />
                      <AppRoute />
                    </NotistackProvider>
                  </RtlLayout>
                </SocketProvider>
              </UsersProvider>
            </MainProvider>
          </ThemeLocalization>
        </ThemePrimaryColor>
      </ThemeConfig>
    </>
  )
}
