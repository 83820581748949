import {useState, useCallback, useEffect} from 'react'
import user_api from 'utils/api/users'

const useFeedbacksHook = () => {
  let [feedbacks, setFeedback] = useState({
    status: 'done', //loading, failed, done, end
    data: [],
  })
  let [feedbacksPage, setFeedbacksPage] = useState(1) //feedbacks page
  let [ratingsFeedbackPage, setRatingsFeedbackPage] = useState('all') //filter by rating

  useEffect(() => {
    let mounted = true

    if (mounted) {
      if (ratingsFeedbackPage === 'all') {
        getAllFeedbacksByPage()
      } else getFeedbacksByRating()
    }

    return () => {
      mounted = false
    }
  }, [ratingsFeedbackPage])

  let getAllFeedbacksByPage = async () => {
    console.log('all feedbacks by page', feedbacksPage)
    if (feedbacks.status === 'loading' || feedbacks.status === 'end') return

    try {
      setFeedback((feedback) => {
        return {
          ...feedback,
          status: 'loading',
        }
      })
      let feedbacksRequest = await user_api.get_feedbacks(feedbacksPage)
      console.log(feedbacksRequest)
      if (feedbacksRequest.status === 200) {
        let status = feedbacksRequest.data.data.length === 0 ? 'end' : 'done'
        console.log('new status would be', status)
        if (feedbacksPage === 1) {
          setFeedback((feedback) => {
            return {
              ...feedback,
              status: status,
              data: feedbacksRequest.data.data,
            }
          })
        } else {
          setFeedback((feedback) => {
            return {
              ...feedback,
              status: status,
              data: [...feedback.data, ...feedbacksRequest.data.data],
            }
          })
        }

        setFeedbacksPage((page) => page + 1)
      } else {
        setFeedback((feedbacks) => {
          return {
            ...feedbacks,
            status: 'error',
          }
        })
      }
    } catch (err) {
      setFeedback((feedbacks) => {
        return {
          ...feedbacks,
          status: 'error',
        }
      })
    }
  }

  let getFeedbacksByRating = async () => {
    if (feedbacks.status === 'loading' || feedbacks.status === 'end') return
    try {
      setFeedback((feedback) => {
        return {
          ...feedback,
          status: 'loading',
        }
      })
      let feedbacksRequest = await user_api.get_feedbacks_by_rating(ratingsFeedbackPage, feedbacksPage)
      if (feedbacksRequest.status === 200) {
        let status = feedbacksRequest.data.data.length === 0 ? 'end' : 'done'
        if (feedbacksPage === 1) {
          setFeedback((feedback) => {
            return {
              ...feedback,
              status: status,
              data: feedbacksRequest.data.data,
            }
          })
        } else {
          setFeedback((feedback) => {
            return {
              ...feedback,
              status: status,
              data: [...feedback.data, ...feedbacksRequest.data.data],
            }
          })
        }
        setFeedbacksPage((page) => page + 1)
      } else {
        setFeedback((feedbacks) => {
          return {
            ...feedbacks,
            status: 'error',
          }
        })
      }
    } catch (err) {
      console.log(err)
      setFeedback((feedbacks) => {
        return {
          ...feedbacks,
          status: 'error',
        }
      })
    }
  }

  let getFeedbacks = () => {
    console.log('getting feedbacks', feedbacksPage, ratingsFeedbackPage)

    if (feedbacks.status === 'loading' || feedbacks.status === 'end') return

    if (ratingsFeedbackPage === 'all') {
      console.log('alll feedbacks')
      getAllFeedbacksByPage()
    } else {
      console.log('by rating')
      getFeedbacksByRating()
    }
  }

  let setRatingToDisplay = async (rating = 5) => {
    if (rating === 'all') {
      setFeedbacksPage(1)
      setRatingsFeedbackPage('all')
      setFeedback({
        status: 'done',
        data: [],
      })
    } else {
      setFeedbacksPage(1)
      setRatingsFeedbackPage(rating)
      setFeedback({
        status: 'done',
        data: [],
      })
    }
  }

  return {
    feedbacks,
    feedbacksPage,
    getAllFeedbacksByPage,
    getFeedbacks,
    setRatingToDisplay,
  }
}

export default useFeedbacksHook
