import { filter } from 'lodash'
import { useEffect, useState, useRef, useCallback } from 'react'
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Modal,
  Backdrop,
  Fade,
  Box,
  Grid,
  FormControl,
  TextField,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormLabel,
  useMediaQuery,
  useTheme,
  Fab,
  CardContent,
  CardActions,
  OutlinedInput,
  InputAdornment,
  InputLabel,
  Autocomplete,
  TableHead,
} from '@material-ui/core'

// icon
import {Icon} from '@iconify/react'
import CloseIcon from '@mui/icons-material/Close';
import closeIcon from '@iconify/icons-eva/close-circle-outline'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import searchFill from '@iconify/icons-eva/search-fill'
// components
import archiveOutline from '@iconify/icons-eva/archive-outline'
import { Link as RouterLink } from 'react-router-dom'
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill'
import Page from '../components/Page'
import Label from '../components/Label'
import Scrollbar from '../components/Scrollbar'
import SearchNotFound from '../components/SearchNotFound'
import { UserListHead, UserListToolbar, UserMoreMenuUpdate } from '../components/_dashboard/user'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import LoadingScreen from 'components/LoadingScreen'
import userAPI from 'utils/api/users'
import storage from 'utils/storage'
import Bugsnag from '@bugsnag/js'
import QrReader from 'react-qr-reader'
import {CSVLink} from 'react-csv'
import GetAppIcon from '@material-ui/icons/GetApp'
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@material-ui/core/styles'

import BreaklistRemark from  'components/breaklistRemark'


const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone')




dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Manila')
const moment = require('moment-timezone')
moment().tz('Asia/Manila').format()


const TABLE_HEAD = [
  { id: 'name', label: 'Employee Name', alignRight: false },
  { id: 'dayswork', label: 'Days Work', alignRight: false },
  { id: 'hourswork', label: 'Hours Work', alignRight: false },
  { id: 'hourstardy', label: 'Tardiness (Minutes)', alignRight: false },
  { id: 'overtime', label: 'Overtime (Hours)', alignRight: false },
  { id: 'nightdiff', label: 'Night Diff (Hours)', alignRight: false },
  { id: 'restday', label: 'RD Work (Hours)', alignRight: false },
  { id: 'legalholiday', label: 'Legal Holiday (Hours)', alignRight: false },
  { id: 'specialholiday', label: 'Special Holiday (Hours)', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false },
  { id: '' },
]

const MODAL_HEAD = [
  { id: 'date', label: 'Date', alignRight: false },
  { id: 'schedule', label: 'Schedule', alignRight: false },
  { id: 'timein', label: 'Time-in', alignRight: false },
  { id: 'timeout', label: 'Time-out', alignRight: false },
  { id: 'workinghours', label: 'Working Hours', alignRight: false },
  { id: 'lates', label: 'Lates (Minutes)', alignRight: false },
  { id: 'ot', label: 'Overtime (Hours)', alignRight: false },
  { id: 'nightdiff', label: 'Night Diff (Hours)', alignRight: false },
  { id: 'rd', label: 'RestDay (Hours)', alignRight: false },
  { id: 'legalholiday', label: 'Legal Holiday (Hours)', alignRight: false },
  { id: 'specialholiday', label: 'Special Holiday (Hours)', alignRight: false },
  
  { id: 'remarks', label: 'Remarks', alignRight: false},
]

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 3,
  justifyContent: 'center' 
};

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}))

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function applySortFilter(array, comparator, query) {
  // Check if the array is valid and not empty
  if (!Array.isArray(array) || array.length === 0) {
    return []; // Return an empty array if input is invalid or empty
  }

  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  if (query) {
    return filter(
      array,
      (_user) => (_user.displayName ? _user.displayName.toLowerCase().indexOf(query.toLowerCase()) !== -1 : _user.empName ? _user.empName.toLowerCase().indexOf(query.toLowerCase()) !== -1 : '')
    );
  }

  return stabilizedThis.map((el) => el[0]);
}

function applySortFilterSchedule(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })

  if (query) {
    return filter(array, (_user) => (_user.date ? _user.date : ''))
  }
  return stabilizedThis.map((el) => el[0])
}

export default function User() {
  const ref = useRef(null)
  const getTodayDateOnly = () => {
    const today = new Date();
    return new Date(today.getFullYear(), today.getMonth(), today.getDate());
  };
  const today = new Date();

  // Create a date object for yesterday
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  const [page, setPage] = useState(0)
  const [pageSchedule, setPageSchedule] = useState(0)
  const [order, setOrder] = useState('asc')
  const [selected, setSelected] = useState([])
  const [orderBy, setOrderBy] = useState('name')
  const [filterName, setFilterName] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [rowsPerPageSchedule, setRowsPerPageSchedule] = useState(5)
  const [users, setUsers] = useState([])
  const [open, setOpen] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const [id, setId] = useState()
  const [name, setName] = useState()
  const [user, setUser] = useState({})
  const [action, setAction] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [openUpdates, setOpenUpdates] = useState(false)
  const d = new Date();
  const [date, setDate] = useState(d.setDate(d.getDate() - 1))
  const [fromDate, setFromDate] = useState(dayjs(yesterday).format('YYYY-MM-DD'))
  const [toDate, setToDate] = useState(dayjs(yesterday).format('YYYY-MM-DD'))
  const [daysWork, setDaysWork] = useState('')
  const [hoursWork, setHoursWork] = useState('')
  const [hoursTardy, setHoursTardy] = useState(0)
  const [overtime, setOvertime] = useState(0)
  const [nightdiff, setNightdiff] = useState(0)
  // const [addRemarksMode, setAddRemarksMode] = useState(null)
  const [remarks, setRemarks] = useState('')
  const [specificSchedule, setSpecificSchedule] = useState([])
  const [resultRequest, setresultRequest] = useState([])
  const [openDialog, setOpenDialog]= useState(false)
  const [submittedBy, setSubmittedBy] = useState('');
  const [cutoff, setCutoff] = useState(1);
  const [company, setCompany] = useState([])
  const [selectedCompany, setSelectedCompany] = useState('');
  const [openQR, setOpenQR] = useState(false)
  const [count, setCount] = useState()
  const csvLinkRef = useRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


  let addedRemarks = useRef({})

  const saveRemark = useCallback((id, remark) => {
    console.log(`remark for ${id} is ${remark}`)
    let savedRemarks = addedRemarks.current
    savedRemarks[`${id}`] = remark
    addedRemarks.current= savedRemarks
    console.log(addedRemarks.current)
  }, [])

  const handleInputChange = (event) => {
    setSubmittedBy(event.target.value);
  };
  /*const [specificSchedule, setSpecificSchedule] = useState([])*/

  useEffect(() => {
    const load = async () => {
      const local_user = await storage.getUser()
      if (!local_user) return

      const user = JSON.parse(local_user)
      setUser(user)
      const fomattedDate = moment(date).format('YYYY-MM-DD')
      if (user.role >= 3) {
        const getCompany = await userAPI.get_group_store(user._id);
        if (getCompany.status === 200) {
          if(getCompany.data.report.store.length > 0) {
            setCompany(getCompany.data.report.store);
            setSelectedCompany(getCompany.data.report.store[0])
          }
          else {
            setCompany([])
            setSelectedCompany()
          }
          
          const data = {
            "store": getCompany.data.report.store[0]
          }
        }
        else {
          setUsers()
        }
      }
    }

    load()
  }, [])
  const updateHoursTardy = (id, data) => {
    setUsers(prevData =>
      prevData.map(item =>
        item._id === id ? { ...item, hourstardy: data.hoursTardy, overtime: data.overtime, nightdiff: data.nightdiff, restday: data.restday  } : item
      )
    );
  };

  const UpdateSubmit = (data) => {
    updateHoursTardy(data.id, data);
  }
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = users.map((n) => n.name)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name)
    let newSelected = []
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
    }
    setSelected(newSelected)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleFilterByName = (event) => {
    setFilterName(event.target.value)
  }

  const handleChangePageSchedule = (event, newPage) => {
    setPageSchedule(newPage)
  }

  const handleChangeRowsPerPageSchedule = (event) => {
    setRowsPerPageSchedule(parseInt(event.target.value, 10))
    setPageSchedule(0)
  }

  const handleFilterByNameSchedule = (event) => {
    setFilterName(event.target.value)
  }

  const handleClose = () => {
    // setAddRemarksMode(null)
    setOpen(null)
  };


  const handleChangeFromDate = async (date) => {
    const dateOnly = date ? dayjs(date).format('YYYY-MM-DD') : null;
    setFromDate(dateOnly)

     // Date Validation
    if (dateOnly && toDate) {
      const from = dayjs(dateOnly);
      const to = dayjs(toDate);
      const diffInDays = to.diff(from, 'day');

      if (diffInDays > 15) {
        alert('Date range must not exceed 16 days');
        setFromDate(null); 
        setToDate(null)
      }
    }
  }

  const handleChangeToDate = async (date) => {
    const dateOnly = date ? dayjs(date).format('YYYY-MM-DD') : null;
    setToDate(dateOnly)

    if (dateOnly && fromDate) {
      const from = dayjs(fromDate);
      const to = dayjs(dateOnly);
      const diffInDays = to.diff(from, 'day');

      if (diffInDays > 15) {
        alert('Date range must not exceed 16 days');
        setToDate(null);
        setFromDate(null)
      }
    }
  }

  function isDateIncluded(date, array) {
    return array.some(item => item.date === date);
  }

  const handleCellClick = async (id) => {
    setLoading(true);
    const data = {
      "id": id,
      "from": fromDate,
      "to": toDate
    }
    try {
      const result = await userAPI.get_schedule_with_range(data);
      setSpecificSchedule(result.data);
      // setSpecificReport(result.data); // Uncomment if needed
      setOpen(id);
    } catch (error) {
      console.error('Error fetching schedule:', error);
    } finally {
      setLoading(false);
    }
  }

  // const handleAddRemarks = (index) => {
  //   setAddRemarksMode(index)
  // }

  // const handleSaveRemarks = () => {
  //   setAddRemarksMode(null)
  // }

  /*  const handleBlur = async () => {
      console.log("Success")
    }*/

  const handleGenerateBreaklist = async () => {
    //remove previous remarks added
     addedRemarks.current = {} 


    setLoading(true)
    if (user.role >= 3) {
      if(!selectedCompany){
        alert("Please select store")
      } 
      else {
        if (fromDate > toDate) {
          alert("From date must be before or equal to end date")
        }
        else {
            const data = {
              "from": fromDate,
              "to": toDate,
            "store": selectedCompany
          }
          const result = await userAPI.get_breaklist(data)
          if(result.data.success) {
            setUsers(result.data.data) 
            setresultRequest(JSON.parse(result.config.data))
            setLoading(false)
          }
          else {
            setUsers([]) 
            alert("Invalid Dates. Breaklist date already submitted and saved.")
            setLoading(false)
          }  
        }  
      }
    }
    else {
      if (fromDate > toDate) {
        alert("From date must be before or equal to end date")
      }
      else {
        const data = {
          "from": fromDate,
          "to": toDate,
          "store": user.company
        }
        const result = await userAPI.get_breaklist(data)
        if(result.data.success) {
          setUsers(result.data.data) 
          setresultRequest(JSON.parse(result.config.data))
          setLoading(false)
        }
        else {
          setUsers([]) 
          alert("Invalid Dates. Breaklist date already submitted and saved.")
          setLoading(false)
        }  
      } 
    }




    setPageSchedule(0)
    
    setLoading(false)
  }

  const handleSaveBreaklist = async () => {
    setOpenDialog(false);
    setLoading(true)
    const data = {
      "from": resultRequest.from,
      "to": resultRequest.to,
      "store": resultRequest.store,
      "employees": users,
      "generatedby": submittedBy,
      "employeecount": users.length,
      "cutoff": cutoff,
      "remarks": addedRemarks.current
    }
    setLoading(true);


    //addedRemarks.current = {}

    try {
      const result = await userAPI.post_save_breaklist(data);
      if (result.status === 200) {
        // setUsers(result.data.data)
        alert('Saving breaklist Success');
        addedRemarks.current = {}
      } else {
        alert('Something went wrong, please try again!');
      }
    } catch (error) {
      alert('Something went wrong, please try again!');
    } finally {
      setLoading(false);
      setSubmittedBy("");
      setOpenDialog(false);
    }

  };

  const handleSubmit = () => {
  setOpenDialog(true)
  }

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0

  const filteredUsers = applySortFilter(users, getComparator(order, orderBy), filterName)

  const filteredSchedule = specificSchedule ? applySortFilterSchedule(specificSchedule, getComparator(order, orderBy), filterName) : false;

  const isUserNotFound = filteredUsers.length === 0

  // const handleInputChange = (e, id, field) => {
  //   const { value } = e.target;
  //   setInputValues(prevState => ({
  //     ...prevState,
  //     [id]: {
  //       ...prevState[id],
  //       [field]: value
  //     }
  //   }));
  // };

  const handleSaveUpdates = () => {
    console.log("Hellow")
  }

  const handleClickOpen = () => {
    setOpen(true)
    setIsOpen(false)
  }

  const handleClickOpenUpdates = (name) => {
    setOpenUpdates(true)
  }

  const handleCloseUpdates = () => {
    setOpenUpdates(false)
  }

  const getCsvData = () => {
    const csvData = []
    csvData.push([`Company: `, `${user.company}`])
    csvData.push([`Date: `, `${fromDate} to ${toDate}`])
    csvData.push(['', '', '', '', '', ''])
    csvData.push(['', '', '', '', '', ''])
    csvData.push(['Employee', 'Days Work', 'Hours Work', 'Tardiness( Minutes )', 'Overtime( Hours )', 'Night Diff( Hours )', 'RD Work( Hours )'])
    if(users) {
      users.map((data) => {
        csvData.push([data.empName, data.dayswork, data.hourswork, data.hourstardy, data.overtime, data.nightdiff, data.restday])
      })  
    }

    return csvData
  }

  const handleExportBreaklist = async () => {
    csvLinkRef.current.link.click();
  }

  const [inputValues, setInputValues] = useState(
    filteredSchedule.reduce((acc, row) => {
      acc[row._id] = {
        hoursTardy: row.hoursTardy,
        overtime: row.overtime,
        nightdiff: row.nightdiff,
        restday: row.restday,
      };
      return acc;
    }, {})
  );

  const labelDisplayedRows = ({ from, to, count }) => {
    return `${from}-${to} of ${count}`;
  };


  const handleStoreChange = async (value) => {
    if (value === selectedCompany || value === null) return
    setLoading(true)
    const fomattedDate = moment(date).format('YYYY-MM-DD')
    setSelectedCompany(value)
    setLoading(false)
  }

  const handleOpenQRModal = async () => {
    setOpenQR(true)
  }

  const handleCloseQR = () => {
    setOpenQR(false)
  }

  const handleError = (err) => {
    alert(err)
  }

  // const load = async () => {
  //   setLoading(true)
  //   const local_user = await storage.getUser()
  //   if (!local_user) return

  //   const user = await JSON.parse(local_user)
  //   const fomattedDate = moment(date).format('YYYY-MM-DD')
  //   let result
  //   let data
  //   let length
  //   if (user.role === 3) {
  //     const getCompany = await userAPI.get_group_store(user._id);
  //     if (getCompany.status === 200) {
  //       setCompany(getCompany.data.report.store);
  //       setSelectedCompany(getCompany.data.report.store)
  //       data = {
  //         store: getCompany.data.report.store[0],
  //       }
  //       result = await userAPI.get_record_bystore(data, fomattedDate)
  //       length = result.data.length - 1
  //       setUsers(result.data.records)
  //       setCount(result.data.count)
  //     }
  //     else {
  //       setUsers()
  //     }
  //   }
  //   else {
  //     const fomattedDate = moment(date).format('YYYY-MM-DD')
  //     data = {
  //       store: user.company,
  //     }
  //     result = await userAPI.get_record_bystore(data, fomattedDate)
  //     length = result.data.length - 1
  //     setUsers(result.data.records)
  //     setCount(result.data.count)
  //   }
  //   /*const getCompany = await userAPI.get_user_company()*/
    
  //   setUser(user)
    
  //   setLoading(false)
  // }
  // useEffect(() => {
  //   load()
  // }, [])

  const handleScan = async (res) => {
    const url = new URL(res);
    const pathSegments = url.pathname.split("/");
    const id = pathSegments[1];
    const data = {
      "uid": user._id,
      "storeid": id
    }
    if (res) {
      const result = await userAPI.post_group_store(data)
      if(result.status === 200) {
        // load()
        setOpenQR(false)
        alert("Success")
      }
      else {
        alert("Something went wrong please try again later!")
      }
    } else {
      alert("No link found in the response.");
    }
  };

  const handleDeleteStore = async () => {
    setLoading(true)
      const data = {
      "store": selectedCompany,
        "id": user._id
      }
    const result = await userAPI.delete_group_store(data)
    const getCompany = await userAPI.get_group_store(user._id);
    if (getCompany.status === 200) {
      if(getCompany.data.report.store.length > 0) {
        setCompany(getCompany.data.report.store);
        setSelectedCompany(getCompany.data.report.store[0])
      }
      else {
        setCompany([])
        setSelectedCompany()
      }
      
      const data = {
        "store": getCompany.data.report.store[0]
      }
    }
    else {
      setUsers()
    }
    setLoading(false)
  }

  // const remarkRow = filteredSchedule.find((row, index) => index === addRemarksMode)
  // const employeeRecordData = filteredUsers.find(row => row._id === open)

  return (
    <Page title="Breaklist | Time In">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h4" gutterBottom>
            Breaklist
          </Typography>
          {/* <Button
            variant="contained"
            component={RouterLink}
            to="#"
            startIcon={<Icon icon={plusFill} />}
          >
            New User
          </Button> */}
        </Stack>
      {
        isMobile ?
          <>
           <Grid container spacing={3}>
           {user && user.role >= 3 && (
            <Grid item xs={12} md={6}>
                <FormControl sx={{ mt: 4, width: "100%" }}>
                  <Box display="flex" alignItems="center">
                    <Autocomplete
                      id="store-select"
                      value={selectedCompany === null ? company[0] : selectedCompany}
                      onChange={(event, value) => handleStoreChange(value)}
                      options={company}
                      renderInput={(params) => (
                        <TextField {...params} label="Store" />
                      )}
                      sx={{
                        flexGrow: 1,
                        whiteSpace: 'nowrap',
                        overflow: 'auto',
                        textOverflow: 'ellipsis',
                        pt: 1
                      }}
                    />
                    <IconButton color="primary" aria-label="add store" onClick={handleOpenQRModal} sx={{ ml: 1 }}>
                      <AddIcon />
                    </IconButton>
                    <IconButton color="primary" aria-label="delete store" onClick={handleDeleteStore} sx={{ ml: 1 }}>
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </FormControl>
              
            </Grid>
            )}
                <Grid item xs={12}>
                  <SearchStyle
                    value={filterName}
                    onChange={handleFilterByName}
                    placeholder="Search user by name"
                    sx={{
                      width: '100%'
                    }}
                    startAdornment={
                      <InputAdornment position="start">
                        <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                      </InputAdornment>
                    }
                  />
                  {/*<UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} 
                  />*/}
                </Grid>
                
                <Grid item xs={6}>
                  <FormControl sx={{ width: "100%" }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        label="From"
                        value={fromDate}
                        minDate={new Date('2017-01-01')}
                        maxDate={yesterday}
                        renderInput={(params) => <TextField {...params} />}
                        onChange={(date) => handleChangeFromDate(date)}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl sx={{ width: "100%" }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        label="To"
                        value={toDate}
                        minDate={new Date('2017-01-01')}
                        renderInput={(params) => <TextField {...params} />}
                        onChange={(date) => handleChangeToDate(date)}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl sx={{ width: "100%" }}>
                    <Button
                      variant="contained"
                      to="#"
                      onClick={handleGenerateBreaklist}
                    >
                      Generate
                    </Button>
                  </FormControl>
                </Grid>
              </Grid>

              {/* add margins and show it on the right most side */}
              <Typography variant="h6" gutterBottom
                sx={{ mt: 2, ml: 2, mr: 2 , textAlign: 'center'}}
              >
                { filteredUsers.length } Employees Found
              </Typography>
              <Grid container spacing={2}>
        {filteredUsers
        // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row) => {
          const { _id, empName, dayswork, hourswork, hourstardy, nightdiff, overtime, restday, legalholiday, specialholiday } = row;

          // Formatting numbers
          const formattedHoursWork = !isNaN(hourswork) ? parseFloat(hourswork).toFixed(1) : '0.00';
          const formattedHoursTardy = !isNaN(hourstardy) ? parseFloat(hourstardy).toFixed(2) : '0.00';
          const formattedOvertime = !isNaN(overtime) ? parseFloat(overtime).toFixed(2) : '0.00';
          const formattedNightdiff = !isNaN(nightdiff) ? parseFloat(nightdiff).toFixed(2) : '0.00';
          const formattedRestday = !isNaN(restday) ? parseFloat(restday).toFixed(2) : '0.00';

          return (
            <Grid item xs={12} sm={6} md={4} key={_id}>
              <Card sx={{ 
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                boxShadow: 2,
                borderRadius: 2,
                p: 2,
                bgcolor: 'background.paper',
                '&:hover': {
                  boxShadow: 5,
                  cursor: 'pointer'
                }
                
               }} onClick={() => handleCellClick(_id)}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Employee: {empName}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Days Worked: {dayswork}  Hours Worked: {formattedHoursWork} 
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Hours Tardy: {formattedHoursTardy} Overtime: {formattedOvertime}  
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Night Diff: {formattedNightdiff} Rest Day: {formattedRestday}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Legal Holiday: {legalholiday ? legalholiday : "0.00"} Special Holiday: {specialholiday && specialholiday > 0 ? specialholiday : "0.00"}
                  </Typography>
                </CardContent>
                <CardActions>
                  <UserMoreMenuUpdate id={_id} action={UpdateSubmit} name={empName} tardi={formattedHoursTardy} ot={formattedOvertime} nightdiff={formattedNightdiff} rd={formattedRestday} />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleCellClick(_id)}
                  >
                    View
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          );
        })}

        {filteredUsers.length === 0 && (
          <Grid item xs={12}>
            <Typography align="center">No users found</Typography>
          </Grid>
        )}

        <Grid container sx={{ direction: 'row', justifyContent: 'center' }}>
          <Button
            variant="contained"
            color="success"
            sx={{ m: 2, mr: 0, width: "100%" }}
            onClick={handleSubmit}
            disabled={filteredUsers.length === 0}
          >
            Save
          </Button>
        </Grid>
      </Grid>
      <Fab 
        color="primary" 
        aria-label="scroll back to top" 
        sx={{ 
          position: 'fixed', 
          bottom: 26, 
          left: '50%', 
          transform: 'translateX(-50%)', 
          opacity : 0.8
        }} 
        onClick={() => window.scrollTo(0, 0)}
      >
        <KeyboardArrowUpIcon />
      </Fab>
          </>
        :
        <>
        {
          isLoading 
          ? 
            <Box sx={{ height: '50vh' }}>
              <LoadingScreen />
            </Box>
          :
            <Card >
                 <Grid container spacing={2}>
              
              <Grid item xs={12} md={5}>
              {user && user.role >= 3 && (
                <FormControl fullWidth sx={{ mt: 2, ml: 3}}>
                  <Box display="flex" alignItems="center">
                    <Autocomplete
                      id="store-select"
                      value={selectedCompany === null ? company[0] : selectedCompany}
                      onChange={(event, value) => handleStoreChange(value)}
                      options={company}
                      placeholder='Store'
                      renderInput={(params) => (
                        <TextField {...params} label="Store" />
                      )}
                      sx={{
                        flexGrow: 1,
                        whiteSpace: 'nowrap',
                        overflow: 'auto',
                        textOverflow: 'ellipsis',
                        pt: 1
                      }}
                    />
                    <IconButton color="primary" aria-label="add store" onClick={handleOpenQRModal} sx={{ ml: 1 }}>
                      <AddIcon />
                    </IconButton>
                    <IconButton color="primary" aria-label="delete store" onClick={handleDeleteStore} sx={{ ml: 1 }}>
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </FormControl>
              )}
                <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
               

            </Grid>
                <Grid item xs={12} md={2}>
                  <FormControl sx={{ mt:isMobile?1: 2.5, ml: 3, width: "85%" }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        label="From"
                        value={fromDate}
                        minDate={new Date('2017-01-01')}
                        maxDate={yesterday}
                        renderInput={(params) => <TextField {...params} />}
                        onChange={(date) => handleChangeFromDate(date)}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={2}>
                  <FormControl sx={{ mt:isMobile?1: 2.5, ml: 3, width: "86.5%" }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        label="To"
                        value={toDate}
                        minDate={new Date('2017-01-01')}
                        renderInput={(params) => <TextField {...params} />}
                        onChange={(date) => handleChangeToDate(date)}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={3}>
                  <Box sx={{display:'flex', justifyContent:'center'}}>
                  <FormControl sx={{ mt:isMobile? 1:4, width: "50%" }}>
                      <Button
                        variant="contained"
                        to="#"
                        onClick={handleGenerateBreaklist}
                      >
                        Generate
                      </Button>
                    </FormControl>
                  </Box>
                </Grid>
              </Grid>
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  {users ? (
                    <Table>
                      <TableHead>
                        <UserListHead
                          order={order}
                          orderBy={orderBy}
                          headLabel={TABLE_HEAD}
                          rowCount={users ? users.length : 0}
                          numSelected={selected.length}
                          onRequestSort={handleRequestSort}
                          onSelectAllClick={handleSelectAllClick}
                        />
                      </TableHead>
                      <TableBody>
                        {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {                 
                          const {
                            _id,
                            empName,
                            dayswork,
                            hourswork,
                            hourstardy,
                            nightdiff,
                            overtime,
                            restday,
                            legalholiday,
                            specialholiday
                          } = row
                          let formattedHoursWork = !isNaN(hourswork) && hourswork !== null && hourswork !== undefined 
                            ? parseFloat(hourswork).toFixed(1) 
                            : '0.00';
                          let formattedHoursTardy = !isNaN(hourstardy) && hourstardy !== null && hourstardy !== undefined 
                            ? parseFloat(hourstardy).toFixed(2) 
                            : '0.00';
                          let formattedOvertime = !isNaN(overtime) && overtime !== null && overtime !== undefined 
                            ? parseFloat(overtime).toFixed(2) 
                            : '0.00';
                          let formattedNightdiff =  !isNaN(nightdiff) && nightdiff !== null && nightdiff !== undefined 
                            ? parseFloat(nightdiff).toFixed(2) 
                            : '0.00';
                          let formattedRestday =  !isNaN(restday) && restday !== null && restday !== undefined 
                            ? parseFloat(restday).toFixed(2) 
                            : '0.00';

                          const isItemSelected = selected.indexOf(_id) !== -1;
                          return (
                            <TableRow
                              hover
                              key={_id}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                              
                            >
                              {/*<TableCell padding="checkbox">
                                <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, name)} />
                              </TableCell>*/}
                              <TableCell
                                component="th"
                                scope="row"
                                padding="none"
                                onClick={() => handleCellClick(_id)}
                                sx={{
                                  ml: 2,
                                  fontWeight: 'bold',
                                  textDecoration: 'underline',
                                  cursor: 'pointer',
                                  '&:hover': {
                                    color: 'primary.main',
                                  },
                                }}
                              >
                                <Stack direction="row" alignItems="center" spacing={2}>
                                  <Avatar sx={{ml: 2}} alt={empName} className="ml-3"/>
                                  <Typography variant="subtitle2" noWrap>
                                    {empName}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell align="left">
                                {dayswork}
                              </TableCell>
                              <TableCell align="left">
                                {formattedHoursWork < 0 ? (0).toFixed(2) : formattedHoursWork}
                              </TableCell>
                              <TableCell align="left">
                                {hoursTardy >= 0 ? formattedHoursTardy : hoursTardy}
                              </TableCell>
                              <TableCell align="left">
                                {formattedOvertime}
                              </TableCell>
                              <TableCell align="left">
                                {formattedNightdiff}
                              </TableCell>
                              <TableCell align="left">
                                {formattedRestday}
                              </TableCell>
                              <TableCell align="left">
                                {legalholiday ? legalholiday : "0.00"}
                              </TableCell>
                              <TableCell align="left">
                                {specialholiday && specialholiday > 0 ? specialholiday : "0.00"}
                              </TableCell>
                              <TableCell align="left">
                                    <UserMoreMenuUpdate id={_id} action={UpdateSubmit} name={empName} tardi={formattedHoursTardy} ot={formattedOvertime} nightdiffval={formattedNightdiff} rd={formattedRestday} />
                              </TableCell>
                            </TableRow>
                            
                          )
                        })}
                        <TableRow style={{backgroundColor: '#e0e0e0'}}>
                          <TableCell align="left">
                            <Typography variant="subtitle2" noWrap>
                              Total
                            </Typography>
                          </TableCell>
                          <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                            {filteredUsers.reduce((sum, user) => sum + (user.dayswork || 0), 0)}
                          </TableCell>
                          <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                            {filteredUsers.reduce((sum, user) => {
                              const hoursWork = parseFloat(user.hourswork);
                              return sum + (!isNaN(hoursWork) && hoursWork !== null && hoursWork !== undefined 
                                ? Number(Number(hoursWork).toFixed(1)) 
                                : 0);
                            }, 0).toFixed(2)}
                          </TableCell>
                          <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                            {filteredUsers.reduce((sum, user) => sum + (!isNaN(user.hourstardy) && user.hourstardy !== null && user.hourstardy !== undefined ? parseFloat(user.hourstardy) : 0), 0).toFixed(2)}
                          </TableCell>
                          <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                            {filteredUsers.reduce((sum, user) => {
                              const overtime = parseFloat(user.overtime);
                              return sum + (!isNaN(overtime) && overtime !== null && overtime !== undefined ? overtime : 0);
                            }, 0).toFixed(2)}
                          </TableCell>
                          <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                            {filteredUsers.reduce((sum, user) => {
                              const nightdiff = parseFloat(user.nightdiff);
                              return sum + (!isNaN(nightdiff) && nightdiff !== null && nightdiff !== undefined ? nightdiff : 0);
                            }, 0).toFixed(2)}
                          </TableCell>
                          <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                            {filteredUsers.reduce((sum, user) => sum + (parseFloat(user.restday) || 0) * (!isNaN(user.restday) && user.restday !== null && user.restday !== undefined ? 1 : 0), 0).toFixed(2)}
                          </TableCell>
                          <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                            {filteredUsers.reduce((sum, user) => {
                              const legalHoliday = parseFloat(user.legalholiday);
                              return sum + (!isNaN(legalHoliday) && legalHoliday !== null && legalHoliday !== undefined ? legalHoliday : 0);
                            }, 0).toFixed(2)}
                          </TableCell>
                          <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                            {filteredUsers.reduce((sum, user) => sum + (user.specialholiday && user.specialholiday > 0 ? parseFloat(user.specialholiday) : 0), 0).toFixed(2)}
                          </TableCell>
                          <TableCell align="left" sx={{ fontWeight: 'bold' }} />
                        </TableRow>
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                      {isUserNotFound && (
                        <TableBody>
                          <TableRow>
                            <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                              <SearchNotFound searchQuery={filterName} />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                    </Table>
                  ) : (
                    ''
                  )}
                </TableContainer>
              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={users ? users.length : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={"Rows per page"}
                labelDisplayedRows={labelDisplayedRows}

              />

              <Grid container sx={{ direction: 'row', justifyContent: 'flex-end' }}>
                <Button
                  variant="contained"
                  color="success"
                  to="#"
                  sx={{ m: 2, width: "19%" }}
                  onClick={handleSubmit}
                  disabled={!users || users.length === 0}
                >
                  Save
                </Button>
{/*                <Button
                  variant="contained"
                  color="primary"
                  to="#"
                  sx={{ m: 2, width: "19%" }}
                  onClick={handleExportBreaklist}
                  disabled={!users || users.length === 0}
                >
                  Export
                </Button>*/}
              </Grid>
            </Card>
        }
        </>
      }
        
        
        <Modal
          aria-labelledby="spring-modal-title"
          aria-describedby="spring-modal-description"
          open={open !== null}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open !== null}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: isMobile ? '90%' : '70%',
                maxHeight: '80vh', // Limits the modal's height to 80% of the viewport
                overflowY: 'auto', // Enables vertical scrolling
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: 2,
              }}
            >
              <Typography align="center">{filteredUsers.find(user => user._id === open)?.empName  + ' (Record)' ?? ''} </Typography>
              <Button sx={{ position: 'absolute', right: 0, top: 12, zIndex: 10}} onClick={() => setOpen(null)}>
                <Icon icon={closeIcon} width={25} height={25} color="#b2b2b2" />
              </Button>
              {
                // isMobile && addRemarksMode === null ?
                isMobile ?
                <Box sx={{ mt: 4 }}>
                  <Typography variant="h6" gutterBottom 
                    sx={{ ml: 2, textAlign: 'right'}}
                  >
                    {filteredSchedule.length} found
                  </Typography>
                {filteredSchedule ? (
                  <Scrollbar>
                  <Grid container spacing={2}>
                    {filteredSchedule
                      // .slice(pageSchedule * rowsPerPageSchedule, pageSchedule * rowsPerPageSchedule + rowsPerPageSchedule)
                      .map((row, index) => {
                        const { _id, name, date, from, to, timeIn, timeOut, hoursTardy, overtime, nightdiff, hourswork } = row;
                        const formattedDate = moment(date).format('YYYY-MM-DD');
              
                        return (
                          <Grid item xs={12} sm={6} md={4} key={`${_id}-${index}`}
                            // sx={{}}
                          >
                            <Card 
                             
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'space-between',
                              boxShadow: 2,
                              borderRadius: 2,
                              height: '113px',
                              p: 2,
                              mt: 2,
                              mb: 2,
                              bgcolor: 'background.paper',
                              '&:hover': {
                                boxShadow: 5,
                                cursor: 'pointer'
                              }
                            }}
                            >
                              {/* <CardContent> */}
                                <Typography variant="h6" gutterBottom>
                                  {name}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                  Date: {formattedDate} Shift: {from} to {to}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                  Time In: {timeIn} Time Out: {timeOut}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                  Hours Worked: {hourswork < 0 ? (0).toFixed(2) : parseFloat(hourswork).toFixed(2)} Tardy Hours: {hoursTardy}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                  Night Differential: {nightdiff} Overtime: {overtime}
                                </Typography>
                              {/* </CardContent> */}
                            </Card>
                          </Grid>
                        );
                      })}
              
                    {filteredSchedule.length === 0 && (
                      <Grid item xs={12}>
                        <Typography align="center">No data found</Typography>
                      </Grid>
                    )}
                    </Grid>
                  </Scrollbar>
                  
                ) : (
                  <Typography>No data found</Typography>
                )}
              
                {/* <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={specificSchedule.length}
                  rowsPerPage={rowsPerPageSchedule}
                  page={pageSchedule}
                  onPageChange={handleChangePageSchedule}
                  onRowsPerPageChange={handleChangeRowsPerPageSchedule}
                  labelRowsPerPage={"Rows per page"}
                /> */}
              </Box>
                  // : addRemarksMode !== null ?
                  //   <Box sx={{ my: 2 }}>
                  //     <Typography>{`Date: ${employeeRecordData.empName}`}</Typography>
                  //     <Typography>{`Date: ${remarkRow.date}`}</Typography>
                  //     <TextField
                  //       fullWidth
                  //       label="Remarks"
                  //       variant="outlined"
                  //       multiline
                  //       rows={4}
                  //       value={remarks}
                  //       onChange={(e) => setRemarks(e.target.value)}
                  //     />
                  //     <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 2 }}>
                  //       <Button variant="contained" color="primary" onClick={handleSaveRemarks}>
                  //         Save
                  //       </Button>
                  //       <Button variant="outlined" color="secondary" onClick={() => setAddRemarksMode(null)}>
                  //         Cancel
                  //       </Button>
                  //     </Stack>
                  //   </Box>
                  // :
                  : <>
                  <TableContainer sx={{ minWidth: 800, mt:4}}>
                  {filteredSchedule ? (
                    <Table>
                      <UserListHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={MODAL_HEAD}
                        rowCount={users ? users.length : 0}
                        numSelected={selected.length}
                        onRequestSort={handleRequestSort}
                        onSelectAllClick={handleSelectAllClick}
                      />
                      <TableBody>
                        {filteredSchedule.slice(pageSchedule * rowsPerPageSchedule, pageSchedule * rowsPerPageSchedule + rowsPerPageSchedule).map((row, index) => {
                          const {
                            _id,
                            name,
                            date,
                            from,
                            to,
                            timeIn,
                            timeOut,
                            hoursTardy,
                            overtime,
                            nightdiff,
                            hourswork,
                            rd,
                            legalholiday,
                            specialholiday, 
                            remarks
                          } = row


                          const formattedDate = moment(date).format('YYYY-MM-DD');
                          const isItemSelected = selected.indexOf(name) !== -1


                          const remarkId = `${_id}-${date}-${timeIn}-${timeOut}`
                          let remark = remarks || addedRemarks.current[`${remarkId}`] || 'No remarks yet.'


                          return (
                            <TableRow
                              hover
                              key={`${_id}-${index}`}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              {/*<TableCell padding="checkbox">
                                <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, name)} />
                              </TableCell>*/}
                              <TableCell component="th" scope="row" padding="none">
                                <Stack direction="row" alignItems="center" spacing={2}>
                                  <Typography variant="subtitle2" noWrap sx={{ ml: 2 }}>
                                    {formattedDate}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell align="left">{from} to {to}</TableCell>
                              <TableCell align="left">{timeIn}</TableCell>
                              <TableCell align="left">{timeOut}</TableCell>
                              <TableCell 
                                align="left" 
                                style={{ 
                                  color: hourswork <= 0 ? 'red' : 'inherit', 
                                  // cursor: hourswork <= 0 && timeOut ? 'pointer' : 'default'
                                }} 
                                // onClick={() => hourswork <= 0 && timeOut && handleAddRemarks(index)}
                                // onMouseOver={(e) => {
                                //   if (hourswork <= 0 && timeOut) {
                                //     e.target.style.backgroundColor = '#f0f0f0';
                                //   }
                                // }}
                                // onMouseOut={(e) => {
                                //   if (hourswork <= 0 && timeOut) {
                                //     e.target.style.backgroundColor = 'inherit';
                                //   }
                                // }}
                              >
                                {!timeIn && !timeOut && hourswork <= 0 ? "Absent" : hourswork <= 0 ? 0 : Number(hourswork).toFixed(1)}
                              </TableCell>
                              <TableCell align="left">{hoursTardy}</TableCell>
                              <TableCell align="left">{overtime}</TableCell>
                              <TableCell align="left">{nightdiff}</TableCell>
                              <TableCell align="left">{rd}</TableCell>
                              <TableCell align="left">{legalholiday}</TableCell>
                              <TableCell align="left">{specialholiday && specialholiday <= 0 ? 0 : specialholiday}</TableCell>
                              <TableCell align="left"><BreaklistRemark id = {remarkId} onSaveRemark = {saveRemark} remarks = {remark}/></TableCell>
                            </TableRow>
                          )
                        })}
                        <TableRow style={{backgroundColor: '#e0e0e0'}}>
                          <TableCell align="left" sx={{ fontWeight: 'bold' }} colSpan={4}>Total</TableCell>
                          <TableCell align="left" sx={{ fontWeight: 'bold' }}>{filteredSchedule.reduce((prev, curr) => prev + Number(curr.hourswork), 0).toFixed(1)}</TableCell>
                          <TableCell align="left" sx={{ fontWeight: 'bold' }}>{filteredSchedule.reduce((prev, curr) => prev + Number(curr.hoursTardy), 0).toFixed(1)}</TableCell>
                          <TableCell align="left" sx={{ fontWeight: 'bold' }}>{filteredSchedule.reduce((prev, curr) => prev + Number(curr.overtime), 0).toFixed(1)}</TableCell>
                          <TableCell align="left" sx={{ fontWeight: 'bold' }}>{filteredSchedule.reduce((prev, curr) => prev + Number(curr.nightdiff), 0).toFixed(1)}</TableCell>
                          <TableCell align="left" sx={{ fontWeight: 'bold' }}>{filteredSchedule.reduce((prev, curr) => prev + Number(curr.rd), 0).toFixed(1)}</TableCell>
                          <TableCell align="left" sx={{ fontWeight: 'bold' }}>{filteredSchedule.reduce((prev, curr) => prev + Number(curr.legalholiday), 0).toFixed(1)}</TableCell>
                          <TableCell align="left" sx={{ fontWeight: 'bold' }}>{filteredSchedule.reduce((prev, curr) => prev + Number(curr.specialholiday), 0).toFixed(1)}</TableCell>
                        </TableRow>
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6}></TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                      {isUserNotFound && (
                        <TableBody>
                          <TableRow>
                            <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                              <SearchNotFound searchQuery={filterName} />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                    </Table>
                  ) : (
                    'No data found'
                  )}
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={specificSchedule.length}
                  rowsPerPage={rowsPerPageSchedule}
                  page={pageSchedule}
                  onPageChange={handleChangePageSchedule}
                  onRowsPerPageChange={handleChangeRowsPerPageSchedule}
                  labelRowsPerPage={"Rows per page"}
                  labelDisplayedRows={labelDisplayedRows}
  
                />
                </>
              }
             
              {/*<Button variant="contained" color="success" onClick={handleSaveUpdates} sx={{ mt: 2, px: 17 }}>
                Submit
              </Button>*/}
            </Box>
          </Fade>
        </Modal>
        <Modal
          aria-labelledby="spring-modal-title"
          aria-describedby="spring-modal-description"
          open={openDialog}
          onClose={()=>{setOpenDialog(false)}}
          closeAfterTransition
          BackdropComponent={Backdrop}

          BackdropProps={{
           timeout: 500,
          }}
        >
          <Fade in={openDialog}>
            <Box alignContent="center" sx={style}>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button sx={{mb: 1}} onClick={()=>{setOpenDialog(false)}}>
                  <Icon icon={closeIcon} width={20} height={20} color="#b2b2b2" />
                </Button>
              </Box>
              <Typography align= "center" variant="h6" >
                Are you sure you want to submit this break list? Once submitted, the store accounting department can review it and forward it to payroll.
              </Typography>
              <TextField
                label="Submitted By:"
                fullWidth
                value={submittedBy}
                onChange={handleInputChange}
                margin="normal"
                variant="outlined"
              />
              <Button disabled={submittedBy? false : true} onClick={handleSaveBreaklist} variant="contained" color="success" sx={{ mt: 2, px: 19 }}>
                Submit
              </Button>
            </Box>
          </Fade>
        </Modal>
        <Modal
          aria-labelledby="spring-modal-title"
          aria-describedby="spring-modal-description"
          open={openDialog}
          onClose={()=>{setOpenDialog(false)}}
          closeAfterTransition
          BackdropComponent={Backdrop}

          BackdropProps={{
           timeout: 500,
          }}
        >
          <Fade in={openDialog}>
            <Box alignContent="center" sx={style}>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button sx={{mb: 1}} onClick={()=>{setOpenDialog(false)}}>
                  <Icon icon={closeIcon} width={20} height={20} color="#b2b2b2" />
                </Button>
              </Box>
              <Typography align= "center" variant="h6" >
                Are you sure you want to submit this break list? Once submitted, the store accounting department can review it and forward it to payroll.
              </Typography>
              <TextField
                label="Submitted By:"
                fullWidth
                value={submittedBy}
                onChange={handleInputChange}
                margin="normal"
                variant="outlined"
              />
              <FormControl required sx={{mt: 3}}>
                <FormLabel id="demo-row-radio-buttons-group-label">Cutoff</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  defaultValue="1"
                >
                  <FormControlLabel value="1" control={<Radio />} label="1st Cutoff" onChange={(e)=>setCutoff(e.target.value)}/>
                  <FormControlLabel value="2" control={<Radio />} label="2nd Cutoff" onChange={(e)=>setCutoff(e.target.value)}/>
                </RadioGroup>
              </FormControl>
              <Button disabled={submittedBy? false : true} onClick={handleSaveBreaklist} variant="contained" color="success" sx={{ mt: 2, px: 19 }}>
                Submit
              </Button>
            </Box>
          </Fade>
        </Modal>

        <Dialog open={openUpdates} onClose={handleClose}>
          <DialogTitle>Update Values?</DialogTitle>
          <DialogContent>
            <TextField
              error
              autoFocus
              margin="dense"
              id="name"
              label="Tardy Minutes"
              type="number"
              fullWidth
              variant="standard"
            />
            <TextField
              error
              margin="dense"
              id="name"
              label="Overtime"
              type="number"
              fullWidth
              variant="standard"
            />
            <TextField
              error
              margin="dense"
              id="name"
              label="Night Diff"
              type="number"
              fullWidth
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseUpdates}>Cancel</Button>
            <Button>Submit</Button>
          </DialogActions>
        </Dialog>
        <CSVLink
          ref={csvLinkRef}
          filename={`Breaklist summary for ${user.company} - ${fromDate} to ${toDate}.csv`}
          data={getCsvData()}
          style={{ display: 'none' }}  // Hide the CSVLink button
        />
      </Container>

      <Dialog open={openQR} onClose={handleCloseQR}>
        <DialogTitle>Scan store QR code.
          <IconButton
            aria-label="close"
            onClick={handleCloseQR}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle >
        <DialogContent>
            <>
              <QrReader
                delay={3000}
                onError={handleError}
                onScan={handleScan}
                // chooseDeviceId={()=>selected}
                style={{ width: '250px', heigth: '200px' }}
              // className={'qrScanner'}
              />
              {/*<DialogTitle style={{ display: "flex", justifyContent: "center" }}><a href="https://www.sparkletimekeeping.com/store/create">Register store account</a></DialogTitle>*/}
            </>
        </DialogContent>
      </Dialog>
    </Page>
  )
}
