import { filter } from 'lodash';
import { useEffect, useState } from 'react';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
  IconButton,
  Menu,
  MenuItem,
  Fab,
  useMediaQuery,
  useTheme,
  Grid,
  FormControl,
  InputLabel,
  Box,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  Autocomplete,
  TableHead
} from '@material-ui/core';
import { KeyboardArrowUp as KeyboardArrowUpIcon, Sort as SortIcon } from '@material-ui/icons';
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import UserMoreMenuRecord from '../components/_dashboard/user/UserMoreMenuRecord';
import { UserListHead, UserListToolbar } from '../components/_dashboard/user';
import UserArchiveCard from '../components/UserArchiveCard';
import QrReader from 'react-qr-reader'
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import userAPI from 'utils/api/users';
import storage from 'utils/storage';
import Bugsnag from '@bugsnag/js';
import LoadingScreen from 'components/LoadingScreen'

const moment = require('moment-timezone')
moment().tz('Asia/Manila').format()

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email address', alignRight: false },
  { id: 'company', label: 'Company', alignRight: false },
  { id: 'phone', label: 'Phone', alignRight: false },
  { id: 'role', label: 'Role', alignRight: false },
  { id: 'isVerified', label: 'Verified', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'date', label: 'Archive Date', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false },
  { id: '' },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  // Check if the array is valid and not empty
  if (!Array.isArray(array) || array.length === 0) {
    return []; // Return an empty array if input is invalid or empty
  }

  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  if (query) {
    return filter(
      array,
      (_user) => (_user.displayName ? _user.displayName.toLowerCase().indexOf(query.toLowerCase()) !== -1 : '')
    );
  }

  return stabilizedThis.map((el) => el[0]);
}

export default function UserArchive() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [action, setAction] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [users, setUsers] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [user, setUser] = useState()
  const [openQR, setOpenQR] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const d = new Date();
  const [date, setDate] = useState(d.setDate(d.getDate() - 1))
  const [selectedCompany, setSelectedCompany] = useState('')
  const [count, setCount] = useState()
  const [company, setCompany] = useState([])

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const load = async () => {
      const local_user = await storage.getUser();
      if (!local_user) return;

      const user = JSON.parse(local_user);
      setUser(user)
      const fomattedDate = moment(date).format('YYYY-MM-DD')
      if (user.role >= 3) {
        const getCompany = await userAPI.get_group_store(user._id);
        if (getCompany.status === 200) {
          setCompany(getCompany.data.report.store);
          if(getCompany.data.report.store.length > 0) {
            setSelectedCompany(getCompany.data.report.store[0])
            const data = {
              store: getCompany.data.report.store[0],
            }
            const storeResult = await userAPI.get_user_bystore(data)
            const length = storeResult.data.length - 1
            
            setUsers(storeResult.data)
            setCount(storeResult.data.count)  
          } else {
            setUsers([])
            setCount(0)
            setSelectedCompany()
          }
        }
      }
      else {
        const result = await userAPI.get_users_archived(user._id);
        if (!result.ok) {
          Bugsnag.notify(result);
          return;
        } else {
          setUsers(result.data);
        }   
      }
      
    };

    load();
  }, [action]);

  const handleDeleteStore = async () => {
    setLoading(true)
      const data = {
      "store": selectedCompany,
        "id": user._id
      }
    const result = await userAPI.delete_group_store(data)
    const getCompany = await userAPI.get_group_store(user._id);
    if (getCompany.status === 200) {
      setCompany(getCompany.data.report.store);
      if(getCompany.data.report.store.length > 0) {
        setSelectedCompany(getCompany.data.report.store[0])
        const data = {
          store: getCompany.data.report.store[0],
        }
        const storeResult = await userAPI.get_user_bystore(data)
        const length = storeResult.data.length - 1
        
        setUsers(storeResult.data)
        setCount(storeResult.data.count)  
      } else {
        setUsers([])
        setCount(0)
      }

    }
    else {
      setUsers()
    }
    setLoading(false)
  }
  
  const filteredUsers = applySortFilter(users, getComparator(order, orderBy), filterName);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredUsers.length) : 0;


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = users.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleSortMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSortMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSortChange = (property) => {
    handleRequestSort(null, property);
    handleSortMenuClose();
  };



  const handleStoreChange = async (value) => {
    if (value === selectedCompany || value === null) return
    setLoading(true)
    const fomattedDate = moment(date).format('YYYY-MM-DD')
    const data = {
      store: value
    }
    const result = await userAPI.get_archiveduser_bystore(data, fomattedDate)
    setSelectedCompany(value)
    setUsers(result.data)
    setCount(result.data.count)
    setLoading(false)
  }

  const handleOpenQRModal = async () => {
    setOpenQR(true)
  }

  const handleError = (err) => {
    alert(err)
  }

  const handleScan = async (res) => {
    const url = new URL(res);
    const pathSegments = url.pathname.split("/");
    const id = pathSegments[1];
    const data = {
      "uid": user._id,
      "storeid": id
    }
    if (res) {
      const result = await userAPI.post_group_store(data)
      if (result.status === 200) {
        // load()
        setOpenQR(false)
        alert("Success")
      }
      else {
        alert("Something went wrong please try again later!")
      }
    } else {
      alert("No link found in the response.");
    }
  };

  const handleChangeDate = async (date) => {
    setLoading(true)
    const fomattedDate = moment(date).format('YYYY-MM-DD')
    setDate(date)
    const data = {
      store: selectedCompany,
    }
    const result = await userAPI.get_record_bystore(data, fomattedDate)
    setUsers(result.data.records)
    setCount(result.data.count)
    setLoading(false)
  }

  // const load = async () => {
  //   setLoading(true)
  //   const local_user = await storage.getUser()
  //   if (!local_user) return

  //   const user = await JSON.parse(local_user)
  //   const fomattedDate = moment(date).format('YYYY-MM-DD')
  //   let result
  //   let data
  //   let length
  //   if (user.role === 3) {
  //     const getCompany = await userAPI.get_group_store(user._id);
  //     if (getCompany.status === 200) {
  //       setCompany(getCompany.data.report.store);
  //       setSelectedCompany(getCompany.data.report.store)
  //       data = {
  //         store: getCompany.data.report.store[0],
  //       }
  //       result = await userAPI.get_record_bystore(data, fomattedDate)
  //       length = result.data.length - 1
  //       setUsers(result.data.records)
  //       setCount(result.data.count)
  //     }
  //     else {
  //       setUsers()
  //     }
  //   }
  //   else {
  //     const fomattedDate = moment(date).format('YYYY-MM-DD')
  //     data = {
  //       store: user.company,
  //     }
  //     result = await userAPI.get_record_bystore(data, fomattedDate)
  //     length = result.data.length - 1
  //     setUsers(result.data.records)
  //     setCount(result.data.count)
  //   }
  //   /*const getCompany = await userAPI.get_user_company()*/

  //   setUser(user)

  //   setLoading(false)
  // }

  const handleCloseQR = () => {
    setOpenQR(false)
  }

  // useEffect(() => {
  //   load()
  // }, [])

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <Page title="User Archive | Time In">
      {isLoading ? (
        <Box sx={{ height: '50vh' }}>
          <LoadingScreen />
        </Box>
      ) : (
        <>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" sx={{ ml: isMobile ? 2 : 0 }} gutterBottom>
              User Archive
            </Typography>
          </Stack>

        <Grid container spacing={isMobile?2:0} justifyContent="start" alignItems="center">
          {user && user.role >= 3 && (
            <>
              <Grid item xs={12} md={6}>
              
                <Box display="flex" justifyContent="start" sx={{ ml: 2.5, width: "100%"}}>
                  <FormControl fullWidth sx={{ width: "100%", display: "flex", alignItems: "start" }}>
                    <Box display="flex" alignItems="center" width={isMobile ? '90%': "100%"}>
                      <Autocomplete
                        id="store-select"
                        value={selectedCompany === null ? company[0] : selectedCompany}
                        onChange={(event, value) => handleStoreChange(value)}
                        options={company}
                        placeholder='Store'
                        renderInput={(params) => (
                          <TextField {...params} label="Store" />
                        )}
                        sx={{
                          flexGrow: 1,
                          whiteSpace: 'nowrap',
                          overflow: 'auto',
                          textOverflow: 'ellipsis',
                          pt: 1
                        }}
                      />
                      <IconButton color="primary" aria-label="add store" onClick={handleOpenQRModal}>
                        <AddIcon />
                      </IconButton>
                      <IconButton color="primary" aria-label="add store" onClick={handleDeleteStore}>
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </FormControl>
                </Box>
              
              </Grid>

            </>
          )}
        {isMobile ? (
          <Grid item xs={12} md={6}>
            <Box display="flex" justifyContent="center">
              <TextField
                fullWidth
                label="Search User"
                value={filterName}
                onChange={handleFilterByName}
                sx={{ maxWidth: '90%' }}
              />
            </Box>
          </Grid>
        ) : (
          <Grid item xs={12} md={6}>
            <Box display="flex" justifyContent="center">
              <TextField
                fullWidth
                label="Search User"
                value={filterName}
                onChange={handleFilterByName}
                sx={{ maxWidth: '80%' }}
              />
            </Box>
          </Grid>
        )}
        
      </Grid>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2} sx={{ mx: isMobile ? 2 : 0 }}>
          <Typography variant="subtitle2" >
            {filteredUsers.length} items
          </Typography>
          <IconButton onClick={handleSortMenuOpen}>
            <SortIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleSortMenuClose}
          >
            {TABLE_HEAD.map((headCell) => (
              <MenuItem key={headCell.id} onClick={() => handleSortChange(headCell.id)}>
                Sort by {headCell.label}
              </MenuItem>
            ))}
          </Menu>
        </Stack>

          {isMobile ? (
            <>
              {filteredUsers.map((user) => (
                <UserArchiveCard key={user._id} user={user} handleAction={setAction} />
              ))}
            </>
          ) : (
            <Card>

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  {users ? (
                    <Table>
                      <TableHead>
                        <UserListHead
                          order={order}
                          orderBy={orderBy}
                          headLabel={TABLE_HEAD}
                          rowCount={users ? users.length : 0}
                          numSelected={selected.length}
                          onRequestSort={handleRequestSort}
                          onSelectAllClick={handleSelectAllClick}
                        />
                      </TableHead>
                      <TableBody>
                        {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                          const {
                            _id,
                            firstName,
                            displayName,
                            lastName,
                            email,
                            phone,
                            role,
                            isOnBoarded,
                            company,
                            image,
                            isVerified,
                            updatedAt
                          } = row;
                          let name = `${firstName} ${lastName}`;
                          if (firstName === null || lastName === null) {
                            name = displayName;
                          }
                          const date = new Date(updatedAt);
                          const formattedDate = date.toLocaleDateString();
                          const isItemSelected = selected.indexOf(name) !== -1;
                          return (
                            <TableRow
                              hover
                              key={_id}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              <TableCell component="th" scope="row" padding="none">
                                <Stack direction="row" alignItems="center" spacing={2}>
                                  <Avatar sx={{ ml: 2 }} alt={name} src={image} />
                                  <Typography variant="subtitle2" noWrap>
                                    {name}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell align="left">{email}</TableCell>
                              <TableCell align="left">{company}</TableCell>
                              <TableCell align="left">{phone}</TableCell>
                              <TableCell align="left">
                                <Label variant="ghost" color={'info'}>
                                  {role === 1 ? 'Store' : 'Employee'}
                                </Label>
                              </TableCell>
                              <TableCell align="left">{isVerified ? 'Yes' : 'No'}</TableCell>
                              <TableCell align="left">
                                <Label variant="ghost" color={!isOnBoarded ? 'error' : 'success'}>
                                  {isOnBoarded ? 'Complete' : 'Incomplete'}
                                </Label>
                              </TableCell>
                              <TableCell align="left">
                                <Label variant="ghost" color={!isOnBoarded ? 'error' : 'success'}>
                                  {formattedDate}
                                </Label>
                              </TableCell>
                              <TableCell align="right">
                                <UserMoreMenuRecord id={_id} action={setAction} />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                      {isUserNotFound && (
                        <TableBody>
                          <TableRow>
                            <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                              <SearchNotFound searchQuery={filterName} />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                    </Table>
                  ) : (
                    ''
                  )}
                </TableContainer>
              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={users ? users.length : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={"Rows per page"}
              />
            </Card>
          )}

          <Fab color="primary" aria-label="scroll back to top" sx={{ position: 'fixed', bottom: 16, right: 16 }} onClick={() => window.scrollTo(0, 0)}>
            <KeyboardArrowUpIcon />
          </Fab>

          <Dialog open={openQR} onClose={handleCloseQR}>
            <DialogTitle>Scan store QR code.
              <IconButton
                aria-label="close"
                onClick={handleCloseQR}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle >
            <DialogContent>
              <>
                <QrReader
                  delay={3000}
                  onError={handleError}
                  onScan={handleScan}
                  // chooseDeviceId={()=>selected}
                  style={{ width: '250px', heigth: '200px' }}
                // className={'qrScanner'}
                />
                {/*<DialogTitle style={{ display: "flex", justifyContent: "center" }}><a href="https://www.sparkletimekeeping.com/store/create">Register store account</a></DialogTitle>*/}
              </>
            </DialogContent>
          </Dialog>
        </>
      )}
    </Page>
  );
}
