import { filter } from 'lodash'
import { useEffect, useState } from 'react'
// material
import {
  useMediaQuery,
  useTheme,
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Modal,
  Backdrop,
  Fade,
  Box,
  Grid,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  TextField,
  IconButton,
  DialogTitle,
  DialogContent,
  Dialog,
  Autocomplete,
  TableHead,
  CardContent,
  CardHeader
} from '@material-ui/core'

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
// components
import Page from '../components/Page'
import Label from '../components/Label'
import Scrollbar from '../components/Scrollbar'
import SearchNotFound from '../components/SearchNotFound'
import { UserListHead, UserListToolbar } from '../components/_dashboard/user'
import AdminMoreMenu from '../components/_dashboard/user/AdminMoreMenu'
import userAPI from 'utils/api/users'
import storage from 'utils/storage'
import Bugsnag from '@bugsnag/js'
import QrReader from 'react-qr-reader'
import CloseIcon from '@mui/icons-material/Close';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import LoadingScreen from 'components/LoadingScreen'
import { TabContext, TabPanel } from '@mui/lab';
import { Tab, Tabs } from '@mui/material';
import { addDays } from 'date-fns'

const moment = require('moment-timezone')
moment().tz('Asia/Manila').format()
const current_date = `${moment().tz('Asia/Manila').toISOString(true)}`
const TABLE_HEAD = [
  { id: 'displayName', label: 'Name', alignRight: false },
  { id: 'date', label: 'Date', alignRight: false },
  { id: 'startShift', label: 'start shift', alignRight: false },
  { id: 'endShift', label: 'end shift', alignRight: false },
  { id: 'breakMin', label: 'break min', alignRight: false },
  { id: 'totalHours', label: 'total hours', alignRight: false },
  { id: 'time-in', label: 'time-in', alignRight: false },
  { id: 'break-in', label: 'break-in', alignRight: false },
  { id: 'break-out', label: 'break-out', alignRight: false },
  { id: 'time-out', label: 'time-out', alignRight: false },
/*  { id: 'action', label: 'action', alignRight: false },*/
]

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })

  if (query) {
    return filter(array, (_user) => (_user.Employee.displayName ? _user.Employee.displayName.toLowerCase().indexOf(query.toLowerCase()) !== -1 : ''))
  }
  return stabilizedThis.map((el) => el[0])
}

export default function User() {
  const [page, setPage] = useState(0)
  const [order, setOrder] = useState('asc')
  const [selected, setSelected] = useState([])
  const [company, setCompany] = useState([])
  const [selectedCompany, setSelectedCompany] = useState('')
  const [orderBy, setOrderBy] = useState('name')
  const [filterName, setFilterName] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [users, setUsers] = useState([])
  const [usersSched, setUsersSched] = useState([])
  const [user, setUser] = useState()
  const [open, setOpen] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [openQR, setOpenQR] = useState(false)
  const [id, setId] = useState()
  const [name, setName] = useState()
  const [count, setCount] = useState()
  const d = new Date();
  const [date, setDate] = useState(d.setDate(d.getDate() - 1))
  const [selectedSchedDate, setSelectedSchedDate] = useState(new Date(Date.now() - 86400000))
  const [doneAction, setDoneAction] = useState('')
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  const load = async () => {
    setLoading(true)
    const local_user = await storage.getUser()
    if (!local_user) return

    const user = await JSON.parse(local_user)
    const fomattedDate = moment(date).format('YYYY-MM-DD')
    let result
    let data
    let length
    if (user.role >= 3) {
      const getCompany = await userAPI.get_group_store(user._id);
      if (getCompany.status === 200) {
        if(getCompany.data.report.store.length > 0) {
          setCompany(getCompany.data.report.store);
          setSelectedCompany(getCompany.data.report.store[0])
          data = {
            store: getCompany.data.report.store[0],
          }
          
          const dataSched = {
            store: getCompany.data.report.store[0],
            date: moment(addDays(selectedSchedDate, 0)).format('L'),
          }
          result = await userAPI.get_record_bystore(data, fomattedDate)
          const resultSched = await userAPI.get_schedule_v2_bystore(dataSched)
          length = result.data.length - 1
          setUsers(result.data.records)
          setCount(result.data.count)  
          if (resultSched.status === 200) {
            setUsersSched(resultSched.data.filteredRecords)
          }
        } else {
          setCompany([])
          setSelectedCompany()
          setUsersSched([])
          setUsers([])
          setCount(0)
        }
        
      }
      else {
        setUsers()
      }
    }
    else {
      const fomattedDate = moment(date).format('YYYY-MM-DD')
      data = {
        store: user.company,
      }
      const dataSched = {
        store: user.company,
        date: moment(addDays(selectedSchedDate, 0)).format('L'),
      }
      result = await userAPI.get_record_bystore(data, fomattedDate)
      const resultSched = await userAPI.get_schedule_v2_bystore(dataSched)
      length = result.data.length - 1
      setUsers(result.data.records)
      setCount(result.data.count)
      if (resultSched.status === 200) {
        setUsersSched(resultSched.data.filteredRecords)
      }
    }
    /*const getCompany = await userAPI.get_user_company()*/
    
    setUser(user)
    
    setLoading(false)
  }
  useEffect(() => {
    load()
  }, [])
/*  useEffect(() => {
    const load = async () => {
      const local_user = await storage.getUser()
      if (!local_user) return

      const user = JSON.parse(local_user)
      const result = await userAPI.get_record_bystore("Star Concorde Group", date)
      const getCompany = await userAPI.get_user_company()
      if (!result.ok) {
        Bugsnag.notify(result)
        return
      }
      setUsers(result.data)
      setCompany(getCompany.data)
    }

    load()
  }, [filterName])*/

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = users.map((n) => n.name)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name)
    let newSelected = []
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
    }
    setSelected(newSelected)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleFilterByName = (event) => {
    setFilterName(event.target.value)
  }

  const handleClose = () => setOpen(false);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0

  const filteredUsers = users && users.length > 0 
    ? applySortFilter(users, getComparator(order, orderBy), filterName) 
    : '';
  const isUserNotFound = filteredUsers.length === 0

  const renderTime = (_time) => {
    let _date = new Date(_time)
    var hours = _date.getHours()
    var minutes = _date.getMinutes()
    var ampm = hours >= 12 ? 'pm' : 'am'
    hours = hours % 12
    hours = hours ? hours : 12 // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes
    var strTime = hours + ':' + minutes + ' ' + ampm
    return strTime
  }

  const filterByStatus = (_data, type) => {
    if(!_data || _data.length === 0) return '-'
    
    let _d = _data.filter((_d) => (_d.status === type ? _d : ''))
    if (_d.length > 0) {
      _d = _d[0]
    }
    if (_d) {
      if(typeof(_d.time) === "string") {
        return {
          time: _d.time === undefined ? 'n/a' : _d.time,
          location: _d.address === undefined ? 'n/a' : _d.address,
          workmate: _d.workmate === undefined ? '' : 'Workmate( ' +_d.workmate + ' )'
        }
      }
      else {
        return {
          time: _d.time === undefined ? 'n/a' : renderTime(_d.time),
          location: _d.address === undefined ? 'n/a' : _d.address,
          workmate: _d.workmate === undefined ? '' : 'Workmate( ' +_d.workmate + ' )'
        }   
      }   
    } else {
      return '-'
    }
  }

  const checkIfDone = async (action) => {
    setDoneAction(action)
    const fomattedDate = moment(date).format('YYYY-MM-DD')
    setDate(date)
    const data = {
      store: selectedCompany,
    }
    const result = await userAPI.get_record_bystore(data, fomattedDate)
    setUsers(result.data.records)
    setCount(result.data.count)
  }

  const handleChangeDate = async (date) => {
    setLoading(true)
    setSelectedSchedDate(date)
    let data
    const fomattedDate = moment(date).format('YYYY-MM-DD')
    setDate(date)
    if(user && user.role >= 3) {
      data = {
        store: selectedCompany,
      }
      const result = await userAPI.get_record_bystore(data, fomattedDate)
      setUsers(result.data.records)
      setCount(result.data.count)  
      const dataSched = {
        store: selectedCompany,
        date: moment(addDays(date, 0)).format('L'),
      }
      const resultSched = await userAPI.get_schedule_v2_bystore(dataSched)
      if (resultSched.status === 200) {
        setUsersSched(resultSched.data.filteredRecords)
      }
    }
    else {
      data = {
        store: user.company,
      }
      const result = await userAPI.get_record_bystore(data, fomattedDate)
      setUsers(result.data.records)
      setCount(result.data.count)
      const dataSched = {
        store: user.company,
        date: moment(addDays(date, 0)).format('L'),
      }
      const resultSched = await userAPI.get_schedule_v2_bystore(dataSched)
      if (resultSched.status === 200) {
        setUsersSched(resultSched.data.filteredRecords)
      }
    }
    setLoading(false)
  }

  const handleStoreChange = async (value) => {
    if (value === selectedCompany || value === null) return
    setLoading(true)
    const fomattedDate = moment(date).format('YYYY-MM-DD')
    setSelectedCompany(value)
    const data = {
      store: value
    }
    const result = await userAPI.get_record_bystore(data, fomattedDate)
    setUsers(result.data.records)
    setCount(result.data.count)
    const dataSched = {
      store: value,
      date: moment(addDays(selectedSchedDate, 0)).format('L'),
    }
    const resultSched = await userAPI.get_schedule_v2_bystore(dataSched)
    if (resultSched.status === 200) {
      setUsersSched(resultSched.data.filteredRecords)
    }
    console.log("check data: ",  result)
    console.log("check date1: ",  resultSched)
    setLoading(false)
  }

  const handleOpenQRModal = async () => {
    setOpenQR(true)
  }

  const handleCloseQR = () => {
    setOpenQR(false)
  }

  const handleError = (err) => {
    alert(err)
  }

  const handleDeleteStore = async () => {
    setLoading(true)
      const data = {
      "store": selectedCompany[0],
        "id": user._id
      }
    const result = await userAPI.delete_group_store(data)
    load()
    setLoading(false)
  }

  const handleScan = async (res) => {
    const url = new URL(res);
    const pathSegments = url.pathname.split("/");
    const id = pathSegments[1];
    const data = {
      "uid": user._id,
      "storeid": id
    }
    if (res) {
      const result = await userAPI.post_group_store(data)
      if(result.status === 200) {
        load()
        setOpenQR(false)
        alert("Success")
      }
      else {
        alert("Something went wrong please try again later!")
      }
    } else {
      alert("No link found in the response.");
    }
  };

  return (
    <Page title="Admin | Time In">
      {isLoading? (
         <Box sx={{ height: '50vh' }}>
         <LoadingScreen />
       </Box>
      ):(
        <>
             <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Admin
          </Typography>
          {/* <Button
            variant="contained"
            component={RouterLink}
            to="#"
            startIcon={<Icon icon={plusFill} />}
          >
            New User
          </Button> */}
        </Stack>

        <Card>
          <Grid container spacing={2}>
            {user && user.role >= 3 && (
              <Grid item xs={10} md={5}>
                <FormControl sx={{ mt: 3, mx: 3, width: "100%" }}>
                  <Box display="flex" alignItems="center">
                    <Autocomplete
                      id="store-select"
                      value={selectedCompany === null ? company[0] : selectedCompany}
                      onChange={(event, value) => handleStoreChange(value)}
                      options={company}
                      placeholder='Store'
                      renderInput={(params) => (
                        <TextField {...params} label="Store" />
                      )}
                      sx={{
                        flexGrow: 10,
                        whiteSpace: 'nowrap',
                        overflow: 'auto',
                        textOverflow: 'ellipsis',
                        pt: 1
                      }}
                    />
                    <IconButton color="primary" aria-label="add store" onClick={handleOpenQRModal} sx={{flexGrow: 1}}>
                      <AddIcon />
                    </IconButton>
                    <IconButton color="primary" aria-label="add store" onClick={handleDeleteStore} sx={{flexGrow: 1}}>
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </FormControl>
              </Grid>
            )}
            <Grid item xs={10} md={3}>
              <FormControl sx={{ mt: 4, ml: 3 }} fullWidth>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="Choose Date"
                    value={date}
                    minDate={new Date('2017-01-01')}
                    renderInput={(params) => <TextField {...params} />}
                    onChange={(newDate: Date | null) => handleChangeDate(newDate)} // Ensure to handle null
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>

            <Grid item xs={11} md={4} mt={1}>
              <FormControl sx={{ mt: 0.5 }} fullWidth>
                <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
              </FormControl>
            </Grid>

            <Grid item xs={10}>
              <FormControl sx={{ ml: 3 }} fullWidth>
                <Typography> Active users count for {moment(date).format('LL')}: {count} </Typography>
              </FormControl>
            </Grid>
          </Grid>
          {isMobile ? (
            <>
             {users || users.length > 0 ? (<>
                {filteredUsers.map((row) => {
                  const isItemSelected = selected.indexOf(row.Employee.displayName) !== -1;
                  return (
                      <Card sx={{ minWidth: 275, marginBottom: 2 }} key={row.Employee._id}>
                        <CardContent>
                          <Typography 
                            variant="h5"
                            fontWeight={'bold'}
                            align='center'
                          >
                            {row.Employee.displayName}
                          </Typography>
                          <Typography align='center' variant="body2" >
                           {moment(date).format('LL')}
                          </Typography>
                          
                          {TABLE_HEAD.map((headCell) => {
                            let value = ''
                            let color = ''
                            if (headCell.id === 'displayName'){
                              color = 'lightblue'
                              return
                            }
                            
                            if (headCell.id === 'date') { 
                              color = 'lightblue'
                              return 
                            }

                            if (headCell.id === 'time-in' || headCell.id === 'break-in' || headCell.id === 'break-out' || headCell.id === 'time-out'){
                              const time = filterByStatus(row.Records[0]?.record, headCell.id).time ?? ''
                              const workmate = filterByStatus(row.Records[0]?.record, headCell.id).workmate ?? ''
                              value = time + " - " + workmate
                              color = 'lightcoral'
                            } else {
                              color = 'lightgreen'
                              value = usersSched.find((user) => user.emp === row.Employee.displayName)?.[headCell.id] ?? ''
                            }
                          
                            return (
                              <>
                              {
                                headCell.id === 'startShift' || headCell.id === 'time-in' ? (
                                <>
                                  <Typography variant="body2" align='center' key={headCell.id}>
                                    <span style={{backgroundColor: headCell.id === 'startShift' ? 'lightgreen' : 'lightcoral'}}> {headCell.id === 'startShift' ? 'Schedule' : 'Time Record' }</span>&nbsp;
                                  </Typography>
                                  <Typography variant="body2" sx={{ backgroundColor: color }} key={headCell.id}>
                                    {headCell.label}: {value}
                                  </Typography>
                                </>
                                ) : (
                                  <Typography variant="body2" sx={{ backgroundColor: color }} key={headCell.id}>
                                    {headCell.label}: {value}
                                  </Typography>
                                )
                              }
                              </>
                            )
                          })}
                        </CardContent>
                      </Card>
                  )
                })}
                </>)
               : (
                <Card sx={{ minWidth: 275, marginBottom: 2 }}>
                  <CardContent>
                    <Typography variant="h6">No Data Available</Typography>
                  </CardContent>
                </Card>
                )
              }
            </>
            ) : ( 
            <>
              <Scrollbar>
                <TableContainer>
                  {users ? (
                    users.length > 0 ? (
                      <Table>
                        <TableHead>
                        <TableRow>
                          <TableCell align="center" colSpan={2} sx={{ borderBottom: '2px solid lightgray', backgroundColor: 'lightblue' }}>
                            User
                          </TableCell>
                          <TableCell align="center" colSpan={4} sx={{ borderBottom: '2px solid lightgray', backgroundColor: 'lightgreen' }}>
                            Schedule
                          </TableCell>
                          <TableCell align="center" colSpan={4} sx={{ borderBottom: '2px solid lightgray', backgroundColor: 'lightcoral' }}>
                            Time Record
                          </TableCell>
                        </TableRow>
                          <UserListHead
                            order={order}
                            orderBy={orderBy}
                            headLabel={TABLE_HEAD}
                            rowCount={users.length}
                            numSelected={selected.length}
                            onRequestSort={handleRequestSort}
                            onSelectAllClick={handleSelectAllClick}
                          />
                        </TableHead>
                        <TableBody>
                          {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                            const isItemSelected = selected.indexOf(row.Employee.displayName) !== -1;
                            return (
                              <TableRow
                                hover
                                key={row.Employee._id}
                                tabIndex={-1}
                                role="checkbox"
                                selected={isItemSelected}
                                aria-checked={isItemSelected}
                              >
                                <TableCell component="th" scope="row" padding="none">
                                  <Stack direction="row" alignItems="center" spacing={2}>
                                    <Typography variant="subtitle2" sx={{ ml: 3 }}>
                                      {row.Employee.displayName}
                                    </Typography>
                                  </Stack>
                                </TableCell>
                                <TableCell align="left">{moment(date).format('LL')}</TableCell>
                                {row.Records.length > 0 ? (
                                  <>
                                    <TableCell align="left"> 
                                      {(() => {
                                        const s = usersSched.find(u => u.emp === row.Employee.displayName)?.startShift;
                                        const timeParts = s?.match(/^(\d{1,2}):(\d{2})$/);
                                        if (!s || !timeParts) return '-';
                                        const [h, m] = s.split(':');
                                        return `${(+h % 12) || 12}:${m} ${+h >= 12 ? 'PM' : 'AM'}`;
                                      })()
                                      }
                                    </TableCell>
                                    <TableCell align="left">{(() => {
                                        const s = usersSched.find((user) => user.emp === row.Employee.displayName)?.endShift;
                                        const timeParts = s?.match(/^(\d{1,2}):(\d{2})$/);
                                        if (!s || !timeParts) return '-';
                                        const [h, m] = s.split(':');
                                        return `${(+h % 12) || 12}:${m} ${+h >= 12 ? 'PM' : 'AM'}`;
                                      })()
                                      }
                                    </TableCell>
                                    <TableCell align="left">{usersSched.find((user) => user.emp === row.Employee.displayName)?.breakMin ?? ''}</TableCell>
                                    <TableCell align="left">{usersSched.find((user) => user.emp === row.Employee.displayName)?.totalHours ?? ''}</TableCell>
                                    <TableCell align="left">{filterByStatus(row.Records[0].record, 'time-in').time + " - " + filterByStatus(row.Records[0].record, 'time-in').workmate}</TableCell>
                                    <TableCell align="left">{filterByStatus(row.Records[0].record, 'break-in').time + " - " + filterByStatus(row.Records[0].record, 'break-in').workmate}</TableCell>
                                    <TableCell align="left">{filterByStatus(row.Records[0].record, 'break-out').time + " - " + filterByStatus(row.Records[0].record, 'break-out').workmate}</TableCell>                               
                                    <TableCell align="left">{filterByStatus(row.Records[0].record, 'time-out').time + " - " + filterByStatus(row.Records[0].record, 'time-out').workmate}</TableCell>
                                    {/*<TableCell align="left"><AdminMoreMenu id={row.Records[0]._id} user={row.Employee._id} action={checkIfDone} data={row} /></TableCell>*/}
                                  </>
                                ) : (
                                  <> 
                                    <TableCell align="left"> - </TableCell>
                                    <TableCell align="left"> - </TableCell>
                                    <TableCell align="left"> - </TableCell>
                                    <TableCell align="left"> - </TableCell>
                                    <TableCell align="left"> - </TableCell>
                                    <TableCell align="left"> - </TableCell>
                                    <TableCell align="left"> - </TableCell>
                                    <TableCell align="left"> - </TableCell>
                                    {/*<TableCell align="left"><AdminMoreMenu id={row.Records._id} user={row.Employee._id} action={checkIfDone} data={row} /></TableCell> */}  
                                  </> 
                                )}
                              </TableRow>
                            )
                          })}
                          {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                              <TableCell colSpan={6} />
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    ) : (
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                              <Typography variant="h6">No Data Available</Typography>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    )
                  ) : (
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                            <Typography variant="h6">No Data Available</Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  )}
                </TableContainer>
              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={users ? users.length : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={"Rows per page"}
              />
            </>
            )
          }
          
        </Card>
      </Container>
      <Dialog open={openQR} onClose={handleCloseQR}>
        <DialogTitle>Scan store QR code.
          <IconButton
            aria-label="close"
            onClick={handleCloseQR}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle >
        <DialogContent>
            <>
              <QrReader
                delay={3000}
                onError={handleError}
                onScan={handleScan}
                // chooseDeviceId={()=>selected}
                style={{ width: '250px', heigth: '200px' }}
              // className={'qrScanner'}
              />
              {/*<DialogTitle style={{ display: "flex", justifyContent: "center" }}><a href="https://www.sparkletimekeeping.com/store/create">Register store account</a></DialogTitle>*/}
            </>
        </DialogContent>
      </Dialog>
        </>
      )}
    
    </Page>
  )
}
