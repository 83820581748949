import SecureLS from 'secure-ls'
const ls = new SecureLS({encodingType: 'aes'})

const key = 'token'
const location = 'geolocation'
const user = 'user'
const store = 'store'
const fcmToken = 'fcmToken'
const scheduleNotify = 'scheduleNotify'

const storeToken = (token) => {
  try {
    ls.set(key, token)
  } catch (error) {
    console.log('Error storing the auth token', error)
  }
}

const storeUser = (data) => {
  try {
    ls.set(user, JSON.stringify(data))
  } catch (error) {
    console.log('Error storing the user', error)
  }
}

const getToken = () => {
  try {
    return ls.get(key)
  } catch (error) {
    console.log('Error getting the auth token', error)
  }
}

const getUser = async () => {
  try {
    return await ls.get(user)
  } catch (error) {
    console.log('Error getting the user', error)
    return false
  }
}

const remove = () => {
  try {
    ls.remove('user')
    ls.remove('uid')
    ls.remove('token')
    ls.remove('sid')
  } catch (error) {
    console.log('Error removing the token', error)
  }
}

const storeFCM = (token) => {
  try {
    ls.set(fcmToken, token)
  } catch (error) {
    console.log('Error storing the fcm token', error)
  }
}

const getFCMToken = () => {
  try {
    return ls.get(fcmToken)
  } catch (error) {
    console.log('Error getting the user', error)
    return false
  }
}

const setScheduleNotify = (reminder) => {
  try {
    ls.set(scheduleNotify, reminder)
  } catch (error) {
    console.log('Error storing the fcm token', error)
  }
}

const getScheduleNotify = () => {
  try {
    return ls.get(scheduleNotify)
  } catch (error) {
    console.log('Error getting the user', error)
    return false
  }
}

const storeLocation = (geolocation) => {
  try {
    ls.set(location, geolocation)
  } catch (error) {
    console.log('Error storing the location', error)
  }
}

const getLocation = () => {
  try {
    return ls.get(location)
  } catch (error) {
    console.log('Error getting the location', error)
  }
}

const setStore = (user) => {
  ls.set('sid', user)
}

const removeStore = async () => {
  ls.remove(store)
  ls.remove('sid')
}

const getStore = () => {
  try {
    return ls.get(store)
  } catch (error) {
    console.log('Error getting the store', error)
  }
}

const _expObject = {
  getToken,
  getUser,
  getLocation,
  remove,
  storeToken,
  storeUser,
  storeLocation,
  setStore,
  getStore,
  removeStore,
  storeFCM,
  getFCMToken,
  setScheduleNotify,
  getScheduleNotify,
}

export default _expObject
