import React, { useEffect, useState, useRef } from 'react'
import { useSnackbar } from 'notistack5'
import { filter } from 'lodash'
// material
import { DataGrid } from '@material-ui/data-grid'
import {
  Card,
  Stack,
  Container,
  Typography,
  Box,
  TextField,
  Grid,
  Paper,
  styled,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Dialog,
  DialogContent,
  DialogTitle,
  AppBar,
  IconButton,
  TablePagination,
  FormControl,
  Modal,
  Backdrop,
  Fade,
  //   List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  InputAdornment,
  Tooltip,
  useMediaQuery,
  useTheme,
  MobileStepper,
  BottomNavigation,
  BottomNavigationAction,
  CardContent,
  CardActions,
  Fab,
  OutlinedInput,
  DialogActions,
  Menu,
  MenuItem,
  InputLabel,
  Select,
  Autocomplete
} from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import archiveOutline from '@iconify/icons-eva/archive-outline'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import closeIcon from '@iconify/icons-eva/close-circle-outline'
import EventIcon from '@mui/icons-material/Event';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search'
import SelectAllIcon from '@mui/icons-material/SelectAll'
import PersonIcon from '@mui/icons-material/Person'
import Scrollbar from '../components/Scrollbar'
import SearchNotFound from '../components/SearchNotFound'
import { UserListHead, UserListToolbarSchedule, UserMoreMenu } from '../components/_dashboard/user'
import { makeStyles } from '@material-ui/styles'
import CloseIcon from '@mui/icons-material/Close'
// components
import Page from '../components/Page'
import LoadingScreen from 'components/LoadingScreen'
import BranchButton from 'components/branch'
import AddIcon from '@mui/icons-material/Add';
// api
import storage from 'utils/storage'
import user_api from 'utils/api/users'
import Bugsnag from '@bugsnag/js'
import { CSVLink } from 'react-csv'
import GetAppIcon from '@material-ui/icons/GetApp'
//DatePicker
/*import 'react-modern-calendar-datepicker/lib/DatePicker.css'
import { Calendar } from "react-modern-calendar-datepicker"*/
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

import { DateRangePicker, DateRange } from 'react-date-range'
import { Icon } from '@iconify/react'
import { addDays } from 'date-fns'

//MultiSelect
// import Select from 'react-select'
import { FixedSizeList as List } from 'react-window'

//Timepicker
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import searchFill from '@iconify/icons-eva/search-fill'
import editOutline from '@iconify/icons-eva/edit-outline'
import trash2Outline from '@iconify/icons-eva/trash-2-outline'
import { eachDayOfInterval } from 'date-fns'
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill'
import QrReader from 'react-qr-reader'
import userAPI from 'utils/api/users'

const moment = require('moment-timezone')
moment().tz('Asia/Manila').format()
const useStyles = makeStyles({
  customWidth: {
    maxWidth: 'none',
    height: '500px',
  },
})

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'position', label: 'Position', alignRight: false },
  { id: 'date', label: 'Date', alignRight: false },
  { id: 'startShift', label: 'Start Shift', alignRight: false },
  { id: 'endShift', label: 'End Shift', alignRight: false },
  { id: 'breakMin', label: 'Break Minutes', alignRight: false },
  { id: 'totalHours', label: 'Total Hours', alignRight: false },
  { id: 'ot', label: 'OT (Hours)', alignRight: false },
  { id: 'nightdiff', label: 'Nightdiff (Hours)', alignRight: false },
  { id: 'rd', label: 'Restday (Hours)', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false },
  { id: '' },
]

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const useStylesButton = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    backgroundColor: "red",
    color: 'black',
    width: '40%'
  },
  buttonEdit: {
    margin: theme.spacing(1),
    color: 'black',
    width: '40%'
  },
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}))

const SelectWithCheckboxes = ({ options, selectedOptions, setSelectedOptions }) => {
  
  const [isOpen, setIsOpen] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [filteredOptions, setFilteredOptions] = useState([])
  const [visibleAlphabetRange, setVisibleAlphabetRange] = useState([0, 10])
  const [filterType, setFilterType] = useState('lastName')
  const listRef = useRef(null)
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(async () => {
    const local_user = await storage.getUser()
    const user = JSON.parse(local_user)
    if(user.role >= 3)
    {
      if(setFilteredOptions.length > 0) {
        setFilteredOptions(
          options
            .filter((option) => {
              if (option) {
                const firstName = option.firstName.toLowerCase();
                const lastName = option.lastName.toLowerCase();
                return filterType === 'firstName'
                  ? firstName.includes(searchQuery.toLowerCase())
                  : lastName.includes(searchQuery.toLowerCase());
              }
              return false; // Ensure only options with Employee are kept
            })
            .sort((a, b) => {
              if (a && b) {
                return filterType === 'firstName'
                  ? a.firstName.localeCompare(b.firstName)
                  : a.lastName.localeCompare(b.lastName);
              }
              return 0;
            })
        );  
      } 
    }
    else {
      if(options) {
        setFilteredOptions(
        options
          .filter((option) => {
            const firstName = option.firstName.toLowerCase()
            const lastName = option.lastName.toLowerCase()
            if (filterType === 'firstName') {
              return firstName.includes(searchQuery.toLowerCase())
            } else {
              return lastName.includes(searchQuery.toLowerCase())
            }
          })
          .sort((a, b) => {
            if (filterType === 'firstName') {
              return a.firstName.localeCompare(b.firstName)
            } else {
              return a.lastName.localeCompare(b.lastName)
            }
          })
        )
      }

    }
  }, [searchQuery, options, filterType])

  useEffect(() => {
    if (isMobile) {
      setIsOpen(true)
    }
  }, [isMobile])

  const handleToggleOption = (option) => {
    setSelectedOptions((prevSelected) =>
      prevSelected.includes(option) ? prevSelected.filter((o) => o !== option) : [...prevSelected, option]
    )
  }

  const handleToggleAll = () => {
    setSelectedOptions((prevSelected) => (prevSelected.length === options.length ? [] : options))
  }

  const handleCancel = () => {
    setSelectedOptions([])
    setIsOpen(false)
  }

  const handleScrollToLetter = (letter) => {
    const index = filteredOptions.findIndex((option) => {
      if (filterType === 'firstName') {
        return option.firstName.startsWith(letter)
      } else {
        return option.lastName.startsWith(letter)
      }
    })
    if (index !== -1 && listRef.current) {
      listRef.current.scrollToItem(index, 'start')
    } else if (listRef.current) {
      const firstNonEmptyIndex = filteredOptions.findIndex((option) => {
        if (filterType === 'firstName') {
          return option.firstName > letter
        } else {
          return option.lastName > letter
        }
      })
      listRef.current.scrollToItem(firstNonEmptyIndex !== -1 ? firstNonEmptyIndex : filteredOptions.length - 1, 'start')
    }

    // Update visible alphabet range
    const letterIndex = alphabet.indexOf(letter)
    setVisibleAlphabetRange([Math.max(0, letterIndex - 5), Math.min(alphabet.length, letterIndex + 5)])
  }

  const capitalize = (str) => {
    return str.toUpperCase();
  }

  const renderRow = ({ index, style }) => (
    <div style={style} key={filteredOptions[index].value}>
      <ListItem button onClick={() => handleToggleOption(filteredOptions[index])}>
        <ListItemIcon>
          <Checkbox checked={selectedOptions.includes(filteredOptions[index])} />
        </ListItemIcon>
        <ListItemText
          primary={
            filterType === 'firstName'
              ? `${capitalize(filteredOptions[index].firstName)} ${capitalize(filteredOptions[index].lastName)}`
              : `${capitalize(filteredOptions[index].lastName)}, ${capitalize(filteredOptions[index].firstName)}`
          }
        />
      </ListItem>
    </div>
  )

  const selectedText =
    selectedOptions.length > 2
      ? `${capitalize(selectedOptions[0].firstName)} ${capitalize(selectedOptions[0].lastName)}, ${capitalize(selectedOptions[1].firstName)} ${capitalize(selectedOptions[1].lastName)} +${selectedOptions.length - 2}`
      : selectedOptions
        .map((option) =>
          filterType === 'firstName'
            ? `${capitalize(option.firstName)} ${capitalize(option.lastName)}`
            : `${capitalize(option.lastName)}, ${capitalize(option.firstName)}`
        )
        .join(', ') || 'Select Personnel'

  return (
    <Box>
      <Tooltip title={selectedOptions.map((option) => option.label).join(', ')} arrow>
        <Button
          variant="outlined"
          size="large"
          // onclick is nothing when on mobile
          onClick={isMobile ? null : () => setIsOpen(!isOpen)}
          endIcon={isMobile ? null : isOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          sx={{
            // width on mobile is 100% while on desktop is 557 px
            width: isMobile ? '100%' : '557px',
            marginTop: '3px',
            position: 'relative',
            height: '56px'
          }}
        >
          {selectedText}
        </Button>
      </Tooltip>
      {isOpen && (
        <Card
          style={{
            position: isMobile ? 'relative' : 'absolute',
            zIndex: 1,
            width: isMobile ? '100%' : '557px',
            maxHeight: isMobile ? '50vh' : '300px',
            overflow: isMobile ? 'scroll' : 'hidden',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', margin: '8px' }}>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              sx={{ flex: 1, marginRight: '8px' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip title="First Name / Last Name" arrow>
                      <Checkbox
                        checked={filterType === 'firstName'}
                        onChange={() => setFilterType(filterType === 'firstName' ? 'lastName' : 'firstName')}
                        inputProps={{ 'aria-label': 'select all' }}
                        icon={<PersonIcon />}
                        checkedIcon={<PersonIcon />}
                      />
                    </Tooltip>
                    <Tooltip title="Select All / Unselect All" arrow>
                      <Checkbox
                        checked={options ? selectedOptions.length === options.length : 0}
                        indeterminate={selectedOptions.length > 0 && selectedOptions.length < options.length}
                        onChange={handleToggleAll}
                        inputProps={{ 'aria-label': 'select all' }}
                        icon={<SelectAllIcon />}
                        checkedIcon={<SelectAllIcon />}
                      />
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
            {/* <Box sx={{ ml: 1 }}>
              <Button variant={filterType === 'firstName' ? 'contained' : 'outlined'} onClick={() => setFilterType('firstName')}>
                First Name
              </Button>
              <Button variant={filterType === 'lastName' ? 'contained' : 'outlined'} onClick={() => setFilterType('lastName')}>
                Last Name
              </Button>
            </Box> */}
          </Box>
          <Box sx={{ display: 'flex', flex: 1 }}>
            <Box sx={{ flex: 1, overflow: 'auto' }}>
              <List
                height={
                  isMobile ? 500 : 240
                }
                itemCount={filteredOptions.length} itemSize={35} ref={listRef} width={530}>
                {renderRow}
              </List>
            </Box>
            <Box sx={{ width: '20px', display: 'flex', flexDirection: 'column', borderLeft: '1px solid #ddd' }}>
              {alphabet.slice(visibleAlphabetRange[0], visibleAlphabetRange[1]).map((letter) => (
                <Box
                  key={letter}
                  sx={{
                    cursor: 'pointer',
                    textAlign: 'center',
                    padding: '2px',
                    fontSize: '12px',
                    userSelect: 'none',
                  }}
                  onClick={() => handleScrollToLetter(letter)}
                >
                  {letter}
                </Box>
              ))}
            </Box>
          </Box>
          <Box
            sx={{
              display: isMobile ? 'none' : 'flex',
              justifyContent: 'flex-end',
              borderTop: '1px solid #ddd',
              padding: '8px',

            }}
          >
            <Button onClick={handleCancel}>Cancel</Button>
            <Button variant="contained" color="primary" onClick={() => setIsOpen(false)} sx={{ ml: 1 }}>
              Done
            </Button>
          </Box>
        </Card>
      )}
    </Box>
  )
}



const Schedule = () => {
  const ref = useRef(null)
  const classes = useStyles()
  const buttonClasses = useStylesButton();
  const { enqueueSnackbar } = useSnackbar()
  const [users, setUsers] = useState([])
  const [user, setUser] = useState()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [totalHours, setTotalHours] = useState()
  const [userGetSchedule, setUserGetSchedule] = useState([])
  const [userSchedule, setUserSchedule] = useState()
  const [isLoading, setLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [viewSchedule, setViewSchedule] = useState(false)
  const [openExportSchedule, setOpenExportSchedule] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [openEditDialog, setOpenEditDialog] = useState(false)
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('name')
  const [otHours, setOtHours] = useState(0)
  const [restday, setRestDayHours] = useState(0)
  const [breakMin, setBreakMin] = useState(0)
  const [selectedId, setSelectedId] = useState('')
  const [ot, setOt] = useState(0)
  const [nightdiff, setNightDiff] = useState(0)
  const [rd, setRd] = useState(0)
  const [position, setPosition] = useState('')
  const [selectedPersonnel, setSelectedPersonnel] = useState('')
  const [timeFrom, setTimeFrom] = useState(new Date())
  const [timeTo, setTimeTo] = useState(new Date())
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [selected, setSelected] = useState([])
  const [formattedDate, setFormattedDate] = useState({})
  const [scheduleResult, setScheduleResult] = useState([])
  const dateObj = new Date()
  const month = dateObj.getUTCMonth() + 1 //months from 1-12
  const day = dateObj.getUTCDate()
  const [filterName, setFilterName] = useState('')
  const year = dateObj.getUTCFullYear()
  const [menuState, setMenuState] = useState({ anchorEl: null, openMenuId: null });

  const [openQR, setOpenQR] = useState(false)
  const d = new Date();
  const [date, setDate] = useState(d.setDate(d.getDate() - 1))
  const [company, setCompany] = useState([])
  const [selectedCompany, setSelectedCompany] = useState('')
  const [count, setCount] = useState()

  const defaultValue = {
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  }
  const [selectedDayRange, setSelectedDayRange] = useState([defaultValue])
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [bottomNavValue, setBottomNavValue] = useState(0);
  const [wizardStep, setWizardStep] = useState(0); // Handles wizard steps within "Set Schedule"


  const capitalize = (str) => {
    return str.toUpperCase();
  }
  const selectedText =
    selected.length > 2
      ? `${capitalize(selected[0].firstName)} ${capitalize(selected[0].lastName)}, ${capitalize(selected[1].firstName)} ${capitalize(selected[1].lastName)} +${selected.length - 2}`
      : selected
        .map((option) =>
          `${capitalize(option.lastName)}, ${capitalize(option.firstName)}`
        )
        .join(', ') || 'No Personnel Selected Yet'


  const options = []

 /* useEffect(() => {
    const load = async () => {
      setLoading(true)
      const local_user = await storage.getUser()
      if (!local_user) {
        Bugsnag.notify(local_user)
        setLoading(false)
        return enqueueSnackbar('Unable to proceed, Kindly Re-log again', { variant: 'warning' })
      }
      const user = JSON.parse(local_user)
      setUser(user)
      const fomattedDate = moment(date).format('YYYY-MM-DD')
      if (user.role >= 3) {
        const getCompany = await userAPI.get_group_store(user._id);
        if (getCompany.status === 200) {
          setCompany(getCompany.data.report.store);
          setSelectedCompany(getCompany.data.report.store)
          const data = {
            store: getCompany.data.report.store[0],
          }
          const result = await userAPI.get_record_bystore(data, fomattedDate)
          result.data.records.map((d) => {
            options.push({ label: d.Employee.displayName, value: d.Employee._id, firstName: d.Employee.firstName, lastName: d.Employee.lastName })
          })
          console.log(options)
          setUsers(options)
          const scheduleData = {
            store: getCompany.data.report.store[0],
            date: moment(addDays(selectedDate, 0)).format('L'),
          }
          const scheduleResult = await user_api.get_schedule_v2_bystore(scheduleData)
          if (scheduleResult.status === 200) {
            setScheduleResult(scheduleResult.data.filteredRecords)
          } else {
            alert(result.msg)
          }
          
        }
        else {
          setUsers()
        }
      }
      else {
        const getUsers = await user_api.get_users(user._id)
        if (!getUsers.ok) {
          setLoading(false)
          Bugsnag.notify(getUsers)
          return enqueueSnackbar('Unable to fetch branches', { variant: 'warning' })
        }
        setLoading(false)
        getUsers.data.map((d) => {
          options.push({ label: d.displayName, value: d._id, firstName: d.firstName, lastName: d.lastName })
        })
        setUsers(options)
        const data = {
          id: user._id,
          date: moment(addDays(selectedDate, 0)).format('L'),
        }
        const result = await user_api.get_schedule_v2(data)
        if (result.status === 200) {
          setScheduleResult(result.data.filteredRecords)
        } else {
          alert(result.msg)
        }
      }
      
    }

    load()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])*/

  const load = async () => {
    setLoading(true)
    const local_user = await storage.getUser()
    if (!local_user) return

    const user = await JSON.parse(local_user)
    const fomattedDate = moment(date).format('YYYY-MM-DD')
    let result
    let data
    let length
    if (user.role >= 3) {
      const getCompany = await userAPI.get_group_store(user._id);
      if (getCompany.status === 200) {
        if(getCompany.data.report.store.length > 0) {
          setCompany(getCompany.data.report.store);
          setSelectedCompany(getCompany.data.report.store[0])
          data = {
            store: getCompany.data.report.store[0],
          }
          result = await userAPI.get_user_bystore(data)
          result.data.map((d) => {
            options.push({label: d.displayName, value: d._id, firstName: d.firstName, lastName: d.lastName})
          })
          setUsers(options)
          length = result.data.length - 1
          setCount(result.data.count)
        } else {
          setUser([])
          setCount(0)
          setSelectedCompany()
        }
        
      }
      else {
        setUsers()
      }
    }
    else {
      const fomattedDate = moment(date).format('YYYY-MM-DD')
      data = {
        store: user.company,
      }
      result = await user_api.get_users(user._id)
      result.data.map((d) => {
        options.push({label: d.displayName, value: d._id, firstName: d.firstName, lastName: d.lastName})
      })
      setUsers(options)
      length = result.data.length - 1
      setCount(result.data.count)
    }
    /*const getCompany = await userAPI.get_user_company()*/

    setUser(user)

    setLoading(false)
  }
  useEffect(() => {
    load()
  }, [])

  useEffect(() => { }, [timeFrom, timeTo])
  function getTimeDifference(startTime, endTime) {
    // Parse the start and end times
    const startParts = startTime.split(':');
    const endParts = endTime.split(':');

    // Create Date objects for the times
    const startDate = new Date();
    startDate.setHours(startParts[0], startParts[1], 0, 0);

    const endDate = new Date(startDate);
    endDate.setHours(endParts[0], endParts[1], 0, 0);

    // Handle the case where end time is on the next day
    if (endDate <= startDate) {
      endDate.setDate(endDate.getDate() + 1);
    }

    // Calculate the difference in milliseconds
    const timeDifferenceMilliseconds = endDate - startDate;

    // Convert the difference to hours and minutes
    const hoursDifference = Math.floor(timeDifferenceMilliseconds / (1000 * 60 * 60));
    const minutesDifference = Math.floor((timeDifferenceMilliseconds % (1000 * 60 * 60)) / (1000 * 60));

    return {
      hours: hoursDifference,
      minutes: minutesDifference
    };
  }
  const handleSubmit = async () => {
    setLoading(true)
    let totalHours = 0
    let totalHours2 = 0
    if (selectedDayRange.startDate === null || selectedDayRange.endDate === null) {
      alert("Please choose date to set schedule")
      setLoading(false)
    }
    else if (selected.length <= 0) {
      alert("Please choose personnel(s)")
      setLoading(false)
    }
    else if (typeof (timeFrom) !== "string" || typeof (timeTo) !== "string") {
      alert("Please choose start shift and end shift")
      setLoading(false)
    }
    else if (!position) {
      alert("Please choose position")
      setLoading(false)
    }
    else {
      if (typeof (timeFrom) === "string" || typeof (timeTo) === "string") {
        const startDate = new Date(`2023-03-15T${timeFrom}`);
        const endDate = new Date(`2023-03-15T${timeTo}`);

        const diffInMilliseconds = endDate - startDate;
        const diffInSeconds = diffInMilliseconds / 1000;
        const diffInMinutes = diffInSeconds / 60;
        const diffInHours = diffInMinutes / 60;
        const hours = breakMin / 60;
        totalHours = getTimeDifference(timeFrom, timeTo)
        totalHours2 = totalHours.hours + (totalHours.minutes / 60) - hours;
      }
      /*        let formattedDayFrom = selectedDayRange.from.day + 1
            let formattedDayTo = selectedDayRange.to.day + 1
            let formattedFromDate = selectedDayRange.from.month + "/" + formattedDayFrom + "/" + selectedDayRange.from.year
            let formattedToDate = selectedDayRange.to.month + "/" + formattedDayTo + "/" + selectedDayRange.to.year*/
      let dates = eachDayOfInterval({
        start: addDays(selectedDayRange[0].startDate, 0),
        end: addDays(selectedDayRange[0].endDate, 0)
      })
     /* if (selectedDayRange[0].startDate <= addDays(new Date(), -31) || selectedDayRange[0].endDate > addDays(new Date(), 31)) {
        alert("Please choose date within the limit")
        setLoading(false)
      }*/
      let verify = true
      let data
      selected.map(r => {
        dates.map(async (d) => {
          if(user && user.role >= 3) {
                data = {
                  uid: r._id || r.value,
                  name: r.displayName || r.label,
              company: selectedCompany,
                  date: moment(addDays(d, 0)).format('L'),
                  from: timeFrom,
                  to: timeTo,
                  totalHours: totalHours2,
                  breakMin: breakMin,
                  position: position,
                  ot: ot,
                  nightdiff: nightdiff,
                  rd: rd
                }  
          }
          else {
            data = {
              uid: r._id || r.value,
              name: r.displayName || r.label,
              company: user.company,
              date: moment(addDays(d, 0)).format('L'),
              from: timeFrom,
              to: timeTo,
              totalHours: totalHours2,
              breakMin: breakMin,
              position: position,
              ot: ot,
              nightdiff: nightdiff,
              rd: rd
            }
          }
          
            const response = await user_api.post_schedule(data)
            if (response.status !== 200) {
            verify = false
          }

        })
      })
      if (verify) {
        alert("Schedule Save")
      setLoading(false)
      }
      else {
        alert("Something went wrong please try again later")
        setLoading(false)
      }
    }
  }
  const handleViewSchedules = async () => {
    setLoading(true)
    if (!user._id) {
      alert('Something went wrong please refresh the page')
    } else if (!selectedDate) {
      alert('Please select date')
    } else {
      if(user.role >= 3) {
            const data = {
              store: selectedCompany,
              date: moment(addDays(selectedDate, 0)).format('L'),
            }
        const result = await user_api.get_schedule_v2_bystore(data)
        if (result.status === 200) {
          setScheduleResult(result.data.filteredRecords)
          } else {
                alert(result.msg)
              }
          }  
      else {
        const data = {
          id: user._id,
          date: moment(addDays(selectedDate, 0)).format('L'),
        }
        const result = await user_api.get_schedule_v2(data)
        if (result.status === 200) {
          setScheduleResult(result.data.filteredRecords)
        } else {
          alert(result.msg)
        }
      }
      
    }
    setLoading(false)
  }

  const handleCloseSchedule = () => {
    setViewSchedule(false)
    setUserGetSchedule([])
  }
  const handleChangePersonnel = async (e) => {
    const result = await user_api.get_schedule(e.value)
    if (result.data.length <= 0) {
      alert('No schedule found')
      setUserGetSchedule([])
    } else {
      setUserGetSchedule(result.data)
    }
  }

  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: '53px', // Adjust the height here
    }),
    container: (provided) => ({
      ...provided,
      width: '38%',
      marginBottom: '25px',
      marginTop: '4px',
    }),
  }
  const handleFilterByName = (event) => {
    setFilterName(event.target.value)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = users.map((n) => n.name)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) return order
      return a[1] - b[1]
    })

    if (query) {
      return filter(array, (_user) => (_user.emp ? _user.emp.toLowerCase().indexOf(query.toLowerCase()) !== -1 : ''))
    }
    return stabilizedThis.map((el) => el[0])
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleExportSchedule = async () => {
    if (!user._id) {
      alert('Something went wrong please refresh the page')
    } else if (!selectedDate) {
      alert('Please select date')
    } else if (!user.company) {
      alert('Something went wrong please refresh the page')
    } else {
      if(user.role >= 3) 
      {
        const data = {
          store: selectedCompany,
          date: moment(addDays(selectedDate, 0)).format('L'),
        }
        const result = await user_api.get_schedule_v2_bystore(data)
        if (result.status === 200) {
          setScheduleResult(result.data.filteredRecords)
        } 
        else 
        {
          alert(result.msg)
        }
      }
      else {
        const data = {
          id: user._id,
          date: moment(addDays(selectedDate, 0)).format('L'),
        }
        const result = await user_api.get_schedule_v2(data)
        if (result.status === 200) {
          setScheduleResult(result.data.filteredRecords)
          setOpenExportSchedule(true)
        } else {
          alert(result.msg)
        }  
      }
      
    }
  }

  const handleCloseExportSchedule = () => {
    setScheduleResult([])
    setOpenExportSchedule(false)
  }

  const handleOpenDeleteDialog = (id) => {
    if (!id) {
      alert('Something went wrong please try again later!')
    }
    else {
      setSelectedId(id)
      setOpenDialog(true)
    }
  }

  const handleOpenEditDialog = (id) => {
    if (!id) {
      alert('Something went wrong please try again later!')
    }
    else {
      setSelectedId(id)
      setOpenEditDialog(true)
    }
  }

  const handleCloseEditDialog = () => {
    setSelectedId()
    setOpenEditDialog(false)
  }

  const handleSubmitUpdates = async () => {
    setLoading(true)
    if (!selectedId) {
      alert("Something went wrong please try again later!")
    }
    else {
      const data = {
        id: selectedId,
        ot: otHours,
        nightdiff: nightdiff,
        rd: restday
      }
      const result = await user_api.edit_schedule(data)
      if (result.status === 200) {
        const datav2 = {
          id: user._id,
          date: moment(addDays(selectedDate, 0)).format('L'),
        }
        const resultGetSchedule = await user_api.get_schedule_v2(datav2)
        if (resultGetSchedule.status === 200) {
          setScheduleResult(resultGetSchedule.data.filteredRecords)
          alert("Update success")
          setOpenEditDialog(false)
          setLoading(false)
        } else {
          alert(result.msg)
          setLoading(false)
        }


      }
      else {
        alert("Something went wrong please try again later!")
        setOpenEditDialog(false)
        setLoading(false)
      }
    }
  }

  const getCsvData = () => {
    const csvData = []
    csvData.push([`Company: `, `${user.company}`])
    csvData.push([`Date: `, `${selectedDate.toLocaleDateString('en-CA')}`])
    csvData.push(['', '', '', '', '', ''])
    csvData.push(['', '', '', '', '', ''])
    csvData.push(['Employee', 'Position', 'Start Shift', 'End Shift','Break Minutes', 'Total Hours'])
    scheduleResult.map((data) => {
      csvData.push([data.emp, data.position, data.startShift, data.endShift, data.breakMin, data.totalHours])
    })
    return csvData
  }

  const groupedByPositionDownload = () => {
    const csvData = []
  
    // Header rows
    csvData.push([`Company: `, `${user.company}`])
    csvData.push([`Date: `, `${selectedDate.toLocaleDateString('en-CA')}`])
    csvData.push([]) // empty row for spacing
    csvData.push([])
  
    // Column headers
    csvData.push(['Position', 'Employee', 'Start Shift', 'End Shift', 'Break Minutes', 'Total Hours'])
  
    // Group scheduleResult by position
    const grouped = scheduleResult.reduce((acc, row) => {
      if (!acc[row.position]) {
        acc[row.position] = []
      }
      acc[row.position].push(row)
      return acc
    }, {})
  
    // Initialize grand totals for all groups
    let grandTotalBreakMin = 0
    let grandTotalHours = 0
  
    // Get sorted positions alphabetically
    const sortedPositions = Object.keys(grouped).sort((a, b) => a.localeCompare(b))
  
    // Iterate over each sorted group
    sortedPositions.forEach((position) => {
      // Sort rows in each group by startShift from earlier to latest.
      const rows = grouped[position].sort((a, b) => a.startShift.localeCompare(b.startShift))
  
      rows.forEach((row, index) => {
        // Convert values to numbers for accurate summation.
        const breakMin = Number(row.breakMin) || 0
        const totalHours = Number(row.totalHours) || 0
  
        // Update grand totals
        grandTotalBreakMin += breakMin
        grandTotalHours += totalHours
  
        // Only display the position on the first row in each group
        csvData.push([
          index === 0 ? row.position : '',
          row.emp,
          row.startShift,
          row.endShift,
          row.breakMin,
          row.totalHours,
        ])
      })
    })
  
    // Add a grand total row at the end for all positions
    csvData.push([
      '',            // Position column left blank
      'Grand Total', // Label in the Employee column
      '',            // Start Shift
      '',            // End Shift
      grandTotalBreakMin,
      grandTotalHours,
    ])
  
    return csvData
  }
  
  const handleChangeBreakMin = (value) => {
    if (value < 0) {
      alert('Negative values not allowed')
    } else {
      setBreakMin(value)
    }
  }
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - scheduleResult.length) : 0

  const filteredUsers = applySortFilter(scheduleResult, getComparator(order, orderBy), filterName)

  const isUserNotFound = filteredUsers.length === 0

  const handleDeleteSchedule = async (id) => {
    setLoading(true)
    if (!id) {
      alert("Something went wrong please try again")
      setLoading(false)
    }
    else {
      const result = await user_api.delete_schedule(id)
      setOpenDialog(false)
      if (result.status === 200) {
        const data = {
          id: user._id,
          date: moment(addDays(selectedDate, 0)).format('L'),
        }
        const result = await user_api.get_schedule_v2(data)
        if (result.status === 200) {
          setScheduleResult(result.data.filteredRecords)
        } else {
          alert(result.msg)
        }
        alert("Delete success")
        setLoading(false)
      }
      else {
        alert("Something went wrong please contact IT administrator")
        setLoading(false)
      }
    }
    setLoading(false)
  }

  const handleDeleteStore = async () => {
    setLoading(true)
      const data = {
      "store": selectedCompany,
        "id": user._id
      }
    const result = await userAPI.delete_group_store(data)
    const getCompany = await userAPI.get_group_store(user._id);
    if (getCompany.status === 200) {
      setCompany(getCompany.data.report.store);
      if(getCompany.data.report.store.length > 0) {
        setSelectedCompany(getCompany.data.report.store[0])
        const data = {
          store: getCompany.data.report.store[0],
        }
        const storeResult = await userAPI.get_user_bystore(data)
        const length = storeResult.data.length - 1
        
        setUsers(storeResult.data)
        setCount(storeResult.data.count)  
      } else {
        setUsers([])
        setCount(0)
      }

    }
    else {
      setUsers()
    }
    setLoading(false)
  }

  const handleMenuOpen = (event, id) => {
    setMenuState({ anchorEl: event.currentTarget, openMenuId: id });
  };

  const handleMenuClose = () => {
    setMenuState({ anchorEl: null, openMenuId: null });
  };

  const handleStoreChange = async (value) => {
    if (value === selectedCompany || value === null) return
    setLoading(true)
    const fomattedDate = moment(date).format('YYYY-MM-DD')
    setSelectedCompany(value)
    const data = {
      store: value
    }
    const result = await userAPI.get_user_bystore(data)
    setUsers(result.data)
    setSelected([])
    setLoading(false)
  }

  const handleOpenQRModal = async () => {
    setOpenQR(true)
  }

  const handleCloseQR = () => {
    setOpenQR(false)
  }

  const handleError = (err) => {
    alert(err)
  }

  const handleScan = async (res) => {
    const url = new URL(res);
    const pathSegments = url.pathname.split("/");
    const id = pathSegments[1];
    const data = {
      "uid": user._id,
      "storeid": id
    }
    if (res) {
      const result = await userAPI.post_group_store(data)
      if (result.status === 200) {
        load()
        setOpenQR(false)
        alert("Success")
      }
      else {
        alert("Something went wrong please try again later!")
      }
    } else {
      alert("No link found in the response.");
    }
  };
  
  const renderPersonnelSelection = () => (
    <Box display="flex" flexDirection="column" alignItems="center" sx={{ padding: '16px', width: '100%' }}>
      {/* Select Store Component */}
      <Box mb={2} display="flex" justifyContent="center" alignItems="center" sx={{ width: '100%' }}>
        {user && user.role >= 3 && (
          <FormControl fullWidth >
            <Box display="flex">
              <Autocomplete
                id="store-select"
                value={selectedCompany === null ? company[0] : selectedCompany}
                onChange={(event, value) => handleStoreChange(value)}
                options={company || company[0]}
                placeholder='Store'
                renderInput={(params) => (
                  <TextField {...params} label="Store" />
                )}
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'auto',
                  textOverflow: 'ellipsis',
                  pt: 1
                }}
                fullWidth
              />
              <IconButton
                color="primary"
                aria-label="add store"
                onClick={handleOpenQRModal}
              >
                <AddIcon />
              </IconButton>
              <IconButton
                color="primary"
                aria-label="delete store"
                onClick={handleDeleteStore}
              >
                <DeleteIcon />
              </IconButton>

            </Box>
          </FormControl>
        )}
      </Box>

      {/* SelectWithCheckboxes Component */}
      <Box mt={.5} width="100%">
        <SelectWithCheckboxes
          options={users}
          selectedOptions={selected}
          setSelectedOptions={setSelected}
        />
      </Box>
    </Box>
  );


  const renderDateSelection = () => (
    <Box>
      <Typography variant="h6">Select Date:</Typography>
      <Box mt={2}>
        <DateRange
          onChange={(item) => setSelectedDayRange([item.selection])}
          editableDateInputs={true}
          moveRangeOnFirstSelection={false}
          direction='horizontal'
          ranges={selectedDayRange}
          maxDate={addDays(new Date(), 31)}
          minDate={addDays(new Date(), -31)}
        />
        {/* <DateRangePicker
          onChange={(item) => setSelectedDayRange([item.selection])}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={1}
          ranges={selectedDayRange}
          direction="horizontal"
          maxDate={addDays(new Date(), 14)}
          minDate={addDays(new Date(), -14)}
        /> */}
        {/* show the list of selected personnel */}
        <Typography variant="subtitle1">Selected Personnel:</Typography>
        <Tooltip title={selected.map((option) => option.label).join(', ')} arrow>
          <Typography>
            {selectedText}
          </Typography>
        </Tooltip>
        <Typography variant="subtitle1">Selected Date Range:</Typography>
        <Typography>
          {moment(selectedDayRange[0].startDate).format('MMM DD, YYYY')} -{' '}
          {moment(selectedDayRange[0].endDate).format('MMM DD, YYYY')}
        </Typography>
      </Box>
    </Box>
  );

  const renderTimeSelection = () => (
    <Box>
      <Box mt={2}>
        <Typography variant="subtitle1">Selected Personnel:</Typography>
        <Tooltip title={selected.map((option) => option.label).join(', ')} arrow>
          <Typography>
            {selectedText}
          </Typography>
        </Tooltip>
        <Typography variant="subtitle1">Selected Date Range:</Typography>
        <Typography>
          {moment(selectedDayRange[0].startDate).format('MMM DD, YYYY')} -{' '}
          {moment(selectedDayRange[0].endDate).format('MMM DD, YYYY')}
        </Typography>
      </Box>
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} md={6}>
          <TextField
            id="time"
            label="Time-in"
            type="time"
            value={timeFrom}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 300, // 5 min
            }}
            fullWidth
            onChange={(e) => setTimeFrom(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="time"
            label="Time-out"
            type="time"
            value={timeTo}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 300, // 5 min
            }}
            fullWidth
            onChange={(e) => setTimeTo(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="breakhour"
            label="No. of breaks (mins)"
            type="number"
            value={breakMin}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              placeholder: 'Enter number of breaks in minutes',
              min: 0,
            }}
            fullWidth
            onChange={(e) => handleChangeBreakMin(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="position"
            label="Position"
            type="text"
            value={position}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              placeholder: 'Enter position',
            }}
            fullWidth
            onChange={(e) => setPosition(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
              <TextField
                id="ot"
                label="OT (Hours)"
                type="number"
                value={ot}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  placeholder: 'Enter number of OT in hours',
                }}
                fullWidth
                onChange={(e) => {
                  setOt(e.target.value);
                }}
                onBlur={(e) => {
                  const value = e.target.value;
                  if (!/^\d*\.?\d+$/.test(value)) {
                    alert("Only positive numbers are allowed in OT");
                    e.target.value = ""; // Clear the input
                    setOt(0);
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="nightdiff"
                label="Night Diff (Hours)"
                type="number"
                value={nightdiff}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  placeholder: 'Enter number of nightdiff in hours',
                  min: 0,
                }}
                fullWidth
                onChange={(e) => {
                  setNightDiff(e.target.value);
                }}
                onBlur={(e) => {
                  const value = e.target.value;
                  if (!/^\d*\.?\d+$/.test(value)) {
                    alert("Only positive numbers are allowed");
                    e.target.value = ""; // Clear the input
                    setNightDiff(0);
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="rd"
                label="RD work (Hours)"
                type="number"
                value={rd}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  placeholder: 'Enter number of RD work in hours',
                  min: 0,
                }}
                fullWidth
                onChange={(e) => {
                  setRd(e.target.value);
                }}
                onBlur={(e) => {
                  const value = e.target.value;
                  if (!/^\d*\.?\d+$/.test(value)) {
                    alert("Only positive numbers or decimals are allowed");
                    e.target.value = ""; // Clear the input
                    setRd(0);
                  }
                }}
              />
            </Grid>
        <Grid container item xs={12} md={12} justifyContent="center">
          <Button
            style={{ height: '50px', width: '50%' }}
            disabled={selected.length === 0 ? true : false} // Button will be disabled if no personnel is selected
            variant="contained"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Grid>
      </Grid>

    </Box>
  );

  const renderViewScheduleCards = () =>
  (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mt={3}>
        <FormControl fullWidth>
          {user && user.role >= 3 
            ?
              <Box display="flex" alignItems="center" justifyContent="center" width="100%">
                <Autocomplete
                  id="store-select"
                  value={selectedCompany === null ? company[0] : selectedCompany}
                  onChange={(event, value) => handleStoreChange(value)}
                  options={company}
                  placeholder='Store'
                  renderInput={(params) => (
                    <TextField {...params} label="Store" />
                  )}
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'auto',
                    textOverflow: 'ellipsis',
                    pt: 1,
                  }}
                  fullWidth
                />
                <IconButton
                  color="primary"
                  aria-label="add store"
                  onClick={handleOpenQRModal}
                >
                  <AddIcon />
                </IconButton>
                <IconButton
                  color="primary"
                  aria-label="delete store"
                  onClick={handleDeleteStore}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>   
            :
              ''  
          }
          
        </FormControl>
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <SearchStyle
          value={filterName}
          onChange={handleFilterByName}
          placeholder="Search user by name"
          sx={{
            height: '100%',
            width: '100%',
            marginTop: '35px !important', // This is valid with sx
          }}
          startAdornment={
            <InputAdornment position="start">
              <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
            </InputAdornment>}
        />
        {/*      <UserListToolbarSchedule
        numSelected={selected.length}
        filterName={filterName}
        onFilterName={handleFilterByName}
        sx={{
          height: '100px',
          width: '20%',
          marginTop: '50px !important', // This is valid with sx
        }}
      />*/}

      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <FormControl sx={{ width: '100%' }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Choose Date"
              value={selectedDate}
              minDate={new Date('2017-01-01')}
              renderInput={(params) => <TextField {...params} />}
              onChange={(date: Date) => setSelectedDate(date)}
            />
          </LocalizationProvider>
        </FormControl>
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Button
          style={{ height: '53px', width: '100%', marginRight: '25px', marginTop: '4px' }}
          variant="contained"
          onClick={handleViewSchedules}
        >
          View Schedule
        </Button>

        <Button
          style={{ height: '53px', width: '100%', marginTop: '4px' }}
          variant="contained"
          onClick={handleExportSchedule}
          disabled={filteredUsers.length === 0 ? true : false} // Button will be disabled if filteredUsers is empty
        >
          Export
        </Button>
      </Stack>
      <Scrollbar>
        <Typography variant="subtitle2">
          {filteredUsers.length} items
        </Typography>
        {filteredUsers ? (
          filteredUsers
            // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row) => {
              const { _id, emp, position, date, startShift, endShift, totalHours, otHours, nightdiff, restday } = row;
              const dateOnly = new Date(selectedDate).toISOString().split('T')[0];
              const dateFormat = new Date(dateOnly);
              const formattedDate = dateFormat.toLocaleDateString('en-US');
              return (
                <Card sx={{ minWidth: 275, marginBottom: 2 }} key={emp}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      Employee: {emp}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Date: {formattedDate}  Position: {position}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Start Shift: {startShift} End Shift: {endShift}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Total Hours: {parseFloat(totalHours).toFixed(2)}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      OT (Hours): {otHours ? parseFloat(otHours).toFixed(2) : 0.00}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Nightdiff (Hours): {nightdiff ? parseFloat(nightdiff).toFixed(2) : 0.00}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      restday (Hours): {restday ? parseFloat(restday).toFixed(2) : 0.00}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<EditIcon />}
                      onClick={() => setSelectedId(_id)}
                      sx={{ marginLeft: 'auto', marginRight: 'auto', width: '100%' }}
                    >
                      Edit
                    </Button>
                  </CardActions>
                  <CardActions>
                    <Button
                      variant="contained"
                      color="error"
                      startIcon={<DeleteIcon />}
                      onClick={() => setSelectedId(_id)}
                      sx={{ marginLeft: 'auto', marginRight: 'auto', marginBottom: '10px', width: '100%' }}
                    >
                      Delete
                    </Button>
                  </CardActions>
                </Card>
              );
            })
        ) : (
          ''
        )}
      </Scrollbar>
      <Fab
        color="primary"
        aria-label="scroll back to top"
        sx={{
          position: 'fixed',
          bottom: 69,
          left: '50%',
          transform: 'translateX(-50%)',
          opacity: 0.8
        }}
        onClick={() => window.scrollTo(0, 0)}
      >
        <KeyboardArrowUpIcon />
      </Fab>
    </>
  );

  return (
    <Page title="Store branches | Time In">
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4">
            Personnel Schedule
          </Typography>
        </Stack>
        {isMobile ? (
          <Container maxWidth="sm">
            <Paper>
              {bottomNavValue === 0 && (
                <>
                  {/* Render the wizard steps within "Set Schedule" */}
                  {wizardStep === 0 && renderPersonnelSelection()}
                  {wizardStep === 1 && renderDateSelection()}
                  {wizardStep === 2 && renderTimeSelection()}

                  <MobileStepper
                    variant="dots"
                    steps={3}
                    position="static"
                    activeStep={wizardStep}
                    nextButton={
                      <Button
                        size="small"
                        onClick={() => setWizardStep(wizardStep + 1)}
                        disabled={wizardStep === 2}
                      >
                        Next
                        <ArrowForwardIosIcon />
                      </Button>
                    }
                    backButton={
                      <Button
                        size="small"
                        onClick={() => setWizardStep(wizardStep - 1)}
                        disabled={wizardStep === 0}
                      >
                        <ArrowBackIosNewIcon />
                        Back
                      </Button>
                    }
                  />
                </>
              )}

              {bottomNavValue === 1 && (
                <Box mt={2}>
                  <Typography variant="h6">View Schedules:</Typography>
                  {renderViewScheduleCards()}
                </Box>
              )}
              {/* Bottom navigation should be fixed at the bottom of the mobile page and should be on top and can be navigated anytime */}

              <BottomNavigation
                value={bottomNavValue}
                onChange={(event, newValue) => setBottomNavValue(newValue)}
                showLabels
                sx={{ width: '75%', position: 'fixed', bottom: 0 }}
              >
                <BottomNavigationAction label="Set Schedule" icon={<EventIcon />} />
                <BottomNavigationAction label="View Schedule" icon={<AccessTimeIcon />} />
              </BottomNavigation>
            </Paper>
          </Container>
        ) : (
          <>
            {isLoading ? (
              <Box sx={{ height: '50vh' }}>
                <LoadingScreen />
              </Box>
            ) : (
              <>
                {users ? (
                  <>
                    <Card sx={{ my: 5 }}>
                      <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={12} ml={3} mb={5} mt={3}>
                            <Box display="flex" alignItems="center" fullWidth>
                              {/* <Select
													    defaultValue={selected}
													    isMulti
													    name="colors"
													    options={users}
													    className="basic-multi-select"
													    classNamePrefix="select"
													    onChange={setSelected}
													    styles={customStyles}
													/> */}
                              <SelectWithCheckboxes
                                options={users}
                                selectedOptions={selected}
                                setSelectedOptions={setSelected}
                              />
                              {user && user.role >= 3 ? (
                              <Box sx={{ marginX: '2.5vh', width: '100%'}}>
                                <FormControl fullWidth sx={{ ml: 1 }}>
                                  <Box display="flex" alignItems="center">
                                  <Autocomplete
                                    id="store-select"
                                    value={selectedCompany === null ? company[0] : selectedCompany}
                                    onChange={(event, value) => handleStoreChange(value)}
                                    options={company}
                                    placeholder='Store'
                                    renderInput={(params) => (
                                      <TextField {...params} label="Store" />
                                    )}
                                    sx={{
                                      flexGrow: 1,
                                      whiteSpace: 'nowrap',
                                      overflow: 'auto',
                                      textOverflow: 'ellipsis',
                                      pt: 1
                                    }}
                                    fullWidth
                                  />
                                    <IconButton
                                      color="primary"
                                      aria-label="add store"
                                      onClick={handleOpenQRModal}
                                      sx={{ ml: 1 }}
                                    >
                                      <AddIcon />
                                    </IconButton>
                                    <IconButton
                                      color="primary"
                                      aria-label="delete store"
                                      onClick={handleDeleteStore}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Box>
                                </FormControl> 
                              </Box>
                              ) : (
                                  '' 
                              )}
                            </Box>
                            <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                              <DateRangePicker
                                onChange={(item) => setSelectedDayRange([item.selection])}
                                showSelectionPreview={true}
                                moveRangeOnFirstSelection={false}
                                months={1}
                                ranges={selectedDayRange}
                                direction="horizontal"
                                maxDate={addDays(new Date(), 31)}
                                minDate={addDays(new Date(), -31)}
                              />
                              {/* <Grid item xs={6} md={5}  ml={3} mb={5} mt={10}> */}
                              <Grid container spacing={2} ml={3} mb={5} mt={3} mr={3}>
                                <Grid item xs={12} md={6}>
                                  <TextField
                                    id="time"
                                    label="Time-in"
                                    type="time"
                                    value={timeFrom}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    inputProps={{
                                      step: 300, // 5 min
                                    }}
                                    fullWidth
                                    onChange={(e) => setTimeFrom(e.target.value)}
                                  />
                                </Grid>
                                <Grid item xs={6} md={6}>
                                  <TextField
                                    id="time"
                                    label="Time-out"
                                    type="time"
                                    value={timeTo}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    inputProps={{
                                      step: 300, // 5 min
                                    }}
                                    fullWidth
                                    onChange={(e) => setTimeTo(e.target.value)}
                                  />
                                </Grid>
                                <Grid item xs={6} md={6}>
                                  <TextField
                                    id="breakhour"
                                    label="No. of breaks (mins)"
                                    type="number"
                                    value={breakMin}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    inputProps={{
                                      placeholder: 'Enter number of breaks in minutes',
                                      min: 0,
                                    }}
                                    fullWidth
                                    onChange={(e) => handleChangeBreakMin(e.target.value)}
                                  />
                                </Grid>
                                <Grid item xs={6} md={6}>
                                  <TextField
                                    id="position"
                                    label="Position"
                                    type="text"
                                    value={position}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    inputProps={{
                                      placeholder: 'Enter position',
                                    }}
                                    fullWidth
                                    onChange={(e) => setPosition(e.target.value)}
                                  />
                                </Grid>
                                <Grid item xs={6} md={6}>
                                  <TextField
                                    id="ot"
                                    label="OT (Hours)"
                                    type="number"
                                    value={ot}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    inputProps={{
                                      placeholder: 'Enter number of OT in hours',
                                      min: 0,
                                    }}
                                    fullWidth
                                    onChange={(e) => {
                                      setOt(e.target.value);
                                    }}
                                    onBlur={(e) => {
                                      const value = e.target.value;
                                      if (!/^\d*\.?\d+$/.test(value)) {
                                        alert("Only positive numbers are allowed");
                                        e.target.value = ""; // Clear the input
                                        setOt(0);
                                      }
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={6} md={6}>
                                  <TextField
                                    id="nightdiff"
                                    label="Night Diff (Hours)"
                                    type="number"
                                    value={nightdiff}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    inputProps={{
                                      placeholder: 'Enter number of nightdiff in hours',
                                      min: 0,
                                    }}
                                    fullWidth
                                    onChange={(e) => {
                                      setNightDiff(e.target.value);
                                    }}
                                    onBlur={(e) => {
                                      const value = e.target.value;
                                      if (!/^\d*\.?\d+$/.test(value)) {
                                        alert("Only positive numbers are allowed");
                                        e.target.value = ""; // Clear the input
                                        setNightDiff(0);
                                      }
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={6} md={6}>
                                  <TextField
                                    id="rd"
                                    label="RD work (Hours)"
                                    type="number"
                                    value={rd}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    inputProps={{
                                      placeholder: 'Enter number of RD work in hours',
                                      min: 0,
                                    }}
                                    fullWidth
                                    onChange={(e) => {
                                      setRd(e.target.value);
                                    }}
                                    onBlur={(e) => {
                                      const value = e.target.value;
                                      if (!/^\d*\.?\d+$/.test(value)) {
                                        alert("Only positive numbers are allowed");
                                        e.target.value = ""; // Clear the input
                                        setRd(0);
                                      }
                                    }}
                                  />
                                </Grid>
                                <Grid container item xs={6} md={12} justifyContent="center">
                                  <Button
                                    style={{ height: '50px', width: '50%' }}
                                    disabled={selected.length === 0 ? true : false} // Button will be disabled if no personnel is selected
                                    variant="contained"
                                    onClick={handleSubmit}
                                  >
                                    Submit
                                  </Button>
                                </Grid>
                              </Grid>
                              {/* </Grid>        		               	          	 */}
                            </Box>
                          </Grid>

                          <Grid item xs={12} md={12} ml={3} mr={3} mb={5} mt={3}>
                            <Box display="flex" sx={{ minWidth: 800 }}>
                              <UserListToolbarSchedule
                                numSelected={selected.length}
                                filterName={filterName}
                                onFilterName={handleFilterByName}
                                style={{ height: '100px', width: '15%' }}
                              />
                              <FormControl sx={{ mt: '4px', mr: '20px', width: '20%' }}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                  <DesktopDatePicker
                                    label="Choose Date"
                                    value={selectedDate}
                                    minDate={new Date('2017-01-01')}
                                    renderInput={(params) => <TextField {...params} />}
                                    onChange={(date: Date) => setSelectedDate(date)}
                                  />
                                </LocalizationProvider>
                              </FormControl>
                              <Button
                                style={{ height: '53px', width: '15%', marginRight: '25px', marginTop: '4px' }}
                                variant="contained"
                                onClick={handleViewSchedules}
                              >
                                View Schedule
                              </Button>
                              <Button
                                style={{ height: '53px', width: '15%', marginRight: '25px', marginTop: '4px' }}
                                variant="contained"
                                onClick={handleExportSchedule}
                                disabled={scheduleResult.length === 0 ? true : false} // Button will be disabled if scheduleResult is empty
                              >
                                Export
                              </Button>
                            </Box>
                            <Scrollbar>
                              {/* <TableContainer sx={{ minWidth: 800, ml: "70px" }}> */}
                              <TableContainer sx={{ minWidth: 800 }}>
                                {scheduleResult ? (
                                  <Table>
                                    <TableHead>
                                      <UserListHead
                                        order={order}
                                        orderBy={orderBy}
                                        headLabel={TABLE_HEAD}
                                        rowCount={scheduleResult.length}
                                        numSelected={selected.length}
                                        onRequestSort={handleRequestSort}
                                        onSelectAllClick={handleSelectAllClick}
                                      />
                                    </TableHead>
                                    <TableBody>
                                      {filteredUsers
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row) => {
                                          const { _id, emp, position, date, startShift, endShift, breakMin, totalHours, otHours, nightdiff, restday } = row
                                          const isMenuOpen = menuState.openMenuId === _id;
                                          const dateOnly = new Date(selectedDate).toISOString().split('T')[0]
                                          const dateFormat = new Date(dateOnly);
                                          const formattedDate = dateFormat.toLocaleDateString('en-US');
                                          const isItemSelected = selected.indexOf(emp) !== -1
                                          console.log(row)
                                          const nameParts = emp.trim().split(" ");
                                          const formattedName =
                                              nameParts.length >= 2
                                                  ? `${nameParts[nameParts.length - 1]}, ${nameParts.slice(0, nameParts.length - 1).join(" ")}`
                                                  : emp;
                                          return (
                                            <TableRow
                                              hover
                                              key={emp}
                                              tabIndex={-1}
                                              role="checkbox"
                                              selected={isItemSelected}
                                              aria-checked={isItemSelected}
                                            >
                                              <TableCell component="th" scope="row" padding="none">
                                                <Stack direction="row" alignItems="center" spacing={2}>
                                                  <Typography variant="subtitle2" noWrap className="ml-3">
                                                    {formattedName}
                                                  </Typography>
                                                </Stack>
                                              </TableCell>
                                              <TableCell align="left">{position}</TableCell>
                                              <TableCell align="left">{formattedDate}</TableCell>
                                              <TableCell align="left">{startShift}</TableCell>
                                              <TableCell align="left">{endShift}</TableCell>
                                              <TableCell align="left">{breakMin}</TableCell>
                                              <TableCell align="left">{parseFloat(totalHours).toFixed(2)}</TableCell>
                                              <TableCell align="left">{otHours ? parseFloat(otHours).toFixed(2) : 0.00}</TableCell>
                                              <TableCell align="left">{nightdiff ? parseFloat(nightdiff).toFixed(2) : 0.00}</TableCell>
                                              <TableCell align="left">{restday ? parseFloat(restday).toFixed(2) : 0.00}</TableCell>
                                              <TableCell align="center">
                                                <IconButton onClick={(event) => handleMenuOpen(event, _id)}>
                                                  <Icon icon={moreVerticalFill} width={20} height={20} />
                                                </IconButton>
                                                <Menu
                                                  open={isMenuOpen}
                                                  anchorEl={menuState.anchorEl}
                                                  onClose={() => handleMenuClose()}
                                                  PaperProps={{
                                                    sx: { width: 200, maxWidth: '100%' },
                                                  }}
                                                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                >
                                                  <MenuItem component={RouterLink} to="#" sx={{ color: 'text.secondary' }}>
                                                    <ListItemIcon>
                                                      <Icon icon={EditIcon} width={24} height={24} style={{ color: 'blue' }} />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                      primary="Edit"
                                                      onClick={() => handleOpenEditDialog(_id)}
                                                      primaryTypographyProps={{ variant: 'body2', style: { color: 'blue' } }}
                                                    />
                                                  </MenuItem>
                                                  <MenuItem component={RouterLink} to="#" sx={{ color: 'text.secondary' }}>
                                                    <ListItemIcon>
                                                      <Icon icon={DeleteIcon} width={24} height={24} style={{ color: 'red' }} />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                      primary="Delete"
                                                      onClick={() => handleOpenDeleteDialog(_id)}
                                                      primaryTypographyProps={{ variant: 'body2', style: { color: 'red' } }}
                                                    />
                                                  </MenuItem>
                                                </Menu>
                                              </TableCell>
                                            </TableRow>
                                          )
                                        })}
                                      {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                          <TableCell colSpan={6} />
                                        </TableRow>
                                      )}
                                    </TableBody>
                                    {isUserNotFound && (
                                      <TableBody>
                                        <TableRow>
                                          <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                                            <SearchNotFound searchQuery={filterName} />
                                          </TableCell>
                                        </TableRow>
                                      </TableBody>
                                    )}
                                  </Table>
                                ) : (
                                  ''
                                )}
                                <TablePagination
                                  rowsPerPageOptions={[5, 10, 25]}
                                  component="div"
                                  count={scheduleResult.length}
                                  rowsPerPage={rowsPerPage}
                                  page={page}
                                  onPageChange={handleChangePage}
                                  onRowsPerPageChange={handleChangeRowsPerPage}
                                  labelRowsPerPage={'Rows per page'}
                                  style={{ 'margin-right': '70px' }}
                                />
                              </TableContainer>
                            </Scrollbar>
                          </Grid>
                        </Grid>
                      </Box>
                    </Card>

                  </>
                ) : (
                  ''
                )}
              </>
            )}
          </>
        )}
      </Container>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={openExportSchedule}
        onClose={handleCloseExportSchedule}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openExportSchedule}>
          <Box sx={style}>
            <Grid container sx={{ pl: 2 }}>
              <Typography variant="h4" style={{ color: '#000', margin: '0px 0px 17px 0px' }}>
                Export Schedule
              </Typography>
              <IconButton
                aria-label="close"
                onClick={handleCloseExportSchedule}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
              {user ? (
                <CSVLink
                  filename={`Schedules for ${user.company} - ${selectedDate.toLocaleDateString('en-CA')}.csv`}
                  data={getCsvData()}
                  style={{
                    display: 'flex',
                    width: '100%',
                    margin: '17px 0px 0px 100px',
                  }}
                >
                  <GetAppIcon sx={{ mr: 1 }} /> <Typography variant="p">Export</Typography>
                </CSVLink>
              ) : (
                ''
              )}
            </Grid>
          </Box>
        </Fade>
      </Modal>
      <Dialog
        open={viewSchedule}
        onClose={handleCloseSchedule}
        maxWidth="sm"
        classes={{ paperScrollPaper: classes.customWidth }}
      >
        <AppBar sx={{ position: 'relative' }}>
          <DialogTitle>Personnel Schedule</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleCloseSchedule}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </AppBar>
        <DialogContent>
          <div style={{ paddingBottom: '.5em' }}></div>

        </DialogContent>
      </Dialog>
      <Dialog open={openDialog}>
        <Button sx={{ ml: 'auto', pt: 2 }} onClick={() => setOpenDialog(false)}>
          <Icon icon={closeIcon} width={32} height={32} color="#b2b2b2" />
        </Button>
        <DialogTitle sx={{ textAlign: 'center' }}>
          <Typography variant="h4" sx={{ maxWidth: '75%', mx: 'auto' }}>
            Are you sure to delete Breaklist?
          </Typography>
        </DialogTitle>
        <DialogActions sx={{ display: 'block', pb: 5, px: 3 }}>
          <Typography variant="h6" sx={{ mb: 6, fontWeight: 400, textAlign: 'center', mx: 'auto' }}>
            Once action processed you may not be able to retrieve the data.
          </Typography>
          <Stack sx={{ my: 2 }}>
            <Button
              color="error"
              size="large"
              variant="contained"
              onClick={() => {
                handleDeleteSchedule(selectedId)
              }}
              disabled={false}
              sx={{ textTransform: 'initial !important' }}
            >
              Delete
            </Button>
            <Button
              onClick={() => {
                setOpenDialog(false)
                setSelectedId('')
              }}
              size="large"
              variant="outlined"
              color="error"
              sx={{ mt: 2 }}
            >
              Cancel
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
      <Dialog open={openEditDialog} onClose={handleCloseEditDialog}>
        <IconButton
          aria-label="close"
          onClick={handleCloseEditDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle>Update Values?</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            id="ot"
            label="Overtime ( Hours )"
            type="number"
            fullWidth
            variant="standard"
            onChange={(e) => setOtHours(e.target.value)}
          />
          <TextField
            margin="dense"
            id="nightdiff"
            label="Night Diff ( Hours )"
            type="number"
            fullWidth
            variant="standard"
            onChange={(e) => setNightDiff(e.target.value)}
          />
          <TextField
            margin="dense"
            id="rd"
            label="Rest Day ( Hours )"
            type="number"
            fullWidth
            variant="standard"
            onChange={(e) => setRestDayHours(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditDialog}>Cancel</Button>
          <Button onClick={handleSubmitUpdates}>Submit</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openQR} onClose={handleCloseQR}>
        <DialogTitle>Scan store QR code.
          <IconButton
            aria-label="close"
            onClick={handleCloseQR}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle >
        <DialogContent>
          <>
            <QrReader
              delay={3000}
              onError={handleError}
              onScan={handleScan}
              // chooseDeviceId={()=>selected}
              style={{ width: '250px', heigth: '200px' }}
            // className={'qrScanner'}
            />
            {/*<DialogTitle style={{ display: "flex", justifyContent: "center" }}><a href="https://www.sparkletimekeeping.com/store/create">Register store account</a></DialogTitle>*/}
          </>
        </DialogContent>
      </Dialog>
    </Page>
  )
}

export default Schedule
