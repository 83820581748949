import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Box from '@material-ui/core/Box'

const MigrationDialog = ({open}) => {
  return (
    <Dialog
      open={open}
      disableEscapeKeyDown
      onClose={() => {}}
      aria-labelledby="migration-dialog-title"
    >
      <DialogTitle id="migration-dialog-title" sx={{textAlign: 'center'}}>
        Migration Notice
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="migration-dialog-description" sx={{textAlign: 'center', mb: 2, lineHeight: 1.25}}>
          Sparkle Timekeeping has a new home.
          <br />
          Visit our updated site at
          <br />
          <a
            href="https://sparkletimekeeping.com"
            target="_blank"
            rel="noopener noreferrer"
            style={{display: 'block', marginTop: '12px', marginBottom: '12px'}}
          >
            sparkletimekeeping.com
          </a>
          You can also access the platform by downloading the Sparkle Timekeeping app:
        </DialogContentText>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2,
            mt: 2,
          }}
        >
          <a
            href="https://play.google.com/store/apps/details?id=com.sparkle.stkmobile&hl=en&pli=1"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
              alt="Google Play Store"
              style={{height: 40}}
            />
          </a>
          <a
            href="https://apps.apple.com/no/app/sparkle-time-keeping/id6670240193"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
              alt="Apple App Store"
              style={{height: 40}}
            />
          </a>
        </Box>
        {/* <Box sx={{textAlign: 'center', mt: 3}}>
          <Button variant="outlined" color="primary" disabled style={{width: '50%'}}>
            Close
          </Button>
        </Box> */}
      </DialogContent>
    </Dialog>
  )
}

export default MigrationDialog
