import React from 'react'
import { filter } from 'lodash'
import { useEffect, useState } from 'react'
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Modal,
  Backdrop,
  Fade,
  Box,
  Grid,
  Dialog,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  DialogTitle,
  CardMedia,
  CardContent,
  CardActions,
  Link,
  Slide,
  FormControl,
  InputLabel,
  MenuItem,
  IconButton,
  Select,
  Autocomplete
} from '@material-ui/core'
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
// components
import Page from '../components/Page'
import Label from '../components/Label'
import Scrollbar from '../components/Scrollbar'
import CloseIcon from '@mui/icons-material/Close';
import QrReader from 'react-qr-reader'
import userAPI from 'utils/api/users'
import storage from 'utils/storage'
import Bugsnag from '@bugsnag/js'


export default function Announcement() {

  const [users, setUsers] = useState([])
  const [open, setOpen] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [name, setName] = useState()
  const [title, setTitle] = useState()
  const [link, setLink] = useState()
  const [description, setDescription] = useState()
  const [photoFileName, setPhotoFileName] = useState()
  const [uploadUrl, setUploadUrl] = useState()
  const [binary, setBinary] = useState()
  const [fileType, setFileType] = useState()
  const [selectedCompany, setSelectedCompany] = useState('')
  const [company, setCompany] = useState([])
  const [announcmentId, setAnnouncementId] = useState()
  const [announcements, setAnnouncements] = useState([])
  const [openQR, setOpenQR] = useState(false)
  const [announcementDetails, setAnnouncementDetails] = useState({})
  const load = async () => {
    const local_user = await storage.getUser()
    if (!local_user) return

    const user = JSON.parse(local_user)
    setUsers(user)
    if (user.role >= 3) {
      const getCompany = await userAPI.get_group_store(user._id);
      if (getCompany.status === 200) {
        if(getCompany.data.report.store.length > 0) {
          setCompany(getCompany.data.report.store);
          setSelectedCompany(getCompany.data.report.store[0])
          const data = {
            store: getCompany.data.report.store[0],
          }
          const announcement = await userAPI.get_store_announcement(data)
          setAnnouncements(announcement.data.data)
        } else {
          setCompany([])
          setSelectedCompany()
          setAnnouncements([])
        }
        
      }
      else {
        setUsers()
      }
    }
    else {
      const data = {
        store:user.company
      }
      const announcement = await userAPI.get_store_announcement(data)
      setAnnouncements(announcement.data.data)  
    }
  }
  useEffect(() => {
    load()
  }, [])
  const handleClose = () => {
    setOpen(false)
  }
  const handleGetUrl = async (e) => {
    const _file = e.target.files
    const to_base64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    });
    const blob = await to_base64(_file[0])
    const data = {
      file_type: _file[0].type
    }
    setFileType(_file[0].type)
    const get_url = await userAPI.request_upload_url(data)
    if (!get_url) {
      alert("Something went wrong please try again later");
    }
    else{
      const { photoFilename, uploadURL } = get_url.data;
      setPhotoFileName(photoFilename)
      setUploadUrl(uploadURL)
      setBinary(atob(blob.split(',')[1]))   
    }
    
  }
  const handleUpload = async () => {
    let data
    if (!title) {
      alert("Please enter title")
    }
    else {
      const array = [];
      if(!binary) {
        alert("Please upload image")
      }
      else {
          for (let i = 0; i < binary.length; i++) {
            array.push(binary.charCodeAt(i));
          }

          const blobData = new Blob([new Uint8Array(array)], {
            type: fileType
          });

          const result = await fetch(uploadUrl, {
            method: 'PUT',
            body: blobData
          }).then(async (response) => {
            const img = response.url.split('?')[0]
            if (response.status) {
              if(users.role >= 3) {
                  data = {
                    title: title,
                    link: link ? link : "",
                    createdBy: users.displayName,
                    uid: users._id,
                    store: selectedCompany,
                    img: img
                  }  
              }
              else {
                data = {
                  title: title,
                  link: link ? link : "",
                  createdBy: users.displayName,
                  uid: users._id,
                  store: users.company,
                  img: img
                  }  
                }  
              
                const r = await userAPI.post_store_announcement(data)
                if (r.status === 200) {
                  alert("Announcement save")
                  load()
                  setOpen(false)
                }
              else {
                alert(r.msg)
              }
            }
          });   
      }

    }
  }
  const handleOpenUpdate = async (e) => {
    const id = e.target.value
    const r = await userAPI.get_store_announcement_by_id(id)
    if(r.status === 200){
      setAnnouncementDetails(r.data.data[0])
      setTitle(r.data.data[0].title)  
      setLink(r.data.data[0].link)  
      setDescription(r.data.data[0].description)  
      setOpenEdit(true)
    }
    else {
      alert(r.msg)
    }
  }
  const handleCloseEdit = () => {
    setTitle("")  
    setLink("")  
    setDescription("")  
    setOpenEdit(false)
  }
  const handleEdit = async () => {
    const data = {
      id: announcementDetails._id,
      title: title,
      link: link,
      description: description
    }
    const r = await userAPI.edit_store_announcement(data)
    if (r.status === 200) {
      let ctr = 0
      alert("Announcement updated")
      load()
      setOpenEdit(false)
    }
  }
  const handleOpenDelete = (e) => {
    setAnnouncementId(e.target.value)
    setOpenDelete(true)
  }
  const handleCloseDelete = () => {
    setOpenDelete(false)
  }
  const handleDelete = async (e) => {
    const r = await userAPI.delete_store_announcement_by_id(announcmentId)
    if(r.status === 200) {
      alert("Announcement deleted")
      load()
      setOpenDelete(false)
    }
    else {
      alert(r.msg)
      setOpenDelete(false)
    }
    
  }

  const handleOpenQRModal = async () => {
    setOpenQR(true)
  }

  const handleCloseQR = () => {
    setOpenQR(false)
  }

  const handleError = (err) => {
    alert(err)
  }

  const handleScan = async (res) => {
    const url = new URL(res);
    const pathSegments = url.pathname.split("/");
    const id = pathSegments[1];
    const data = {
      "uid": users._id,
      "storeid": id
    }
    if (res) {
      const result = await userAPI.post_group_store(data)
      if (result.status === 200) {
        setOpenQR(false)
        alert("Success")
      }
      else {
        alert("Something went wrong please try again later!")
      }
    } else {
      alert("No link found in the response.");
    }
  };

  const handleStoreChange = async (value) => {
    if (value === selectedCompany || value === null) return
    const data = {
      store: value
    }
    const result = await userAPI.get_store_announcement(data)
    setSelectedCompany(value)
    setAnnouncements(result.data.data)
  }

  const handleDeleteStore = async () => {
      const data = {
      "store": selectedCompany,
        "id": users._id
      }
    const result = await userAPI.delete_group_store(data)
    const getCompany = await userAPI.get_group_store(users._id);
    if (getCompany.status === 200) {
      setCompany(getCompany.data.report.store);
      if(getCompany.data.report.store.length > 0) {
        setSelectedCompany(getCompany.data.report.store[0])
        const data = {
          store: getCompany.data.report.store[0],
        }
        const announcement = await userAPI.get_store_announcement(data)
        setAnnouncements(announcement.data.data)
      } else {
        setCompany([])
        setSelectedCompany()
        setAnnouncements([])
      }

    }
    else {
      setUsers()
    }
  }

  return (
    <Page title="User | Time In">
      <Container>
        <Grid container spacing={2} alignItems="center" justifyContent="space-between" mb={5}>
          {/* Broadcast Title */}
          <Grid item xs={12} sm="auto">
            <Typography variant="h4" gutterBottom>
              Broadcast
            </Typography>
          </Grid>
          {users && users.role >= 3 && (
            <Grid item xs={12} sm={6} md={5}>
              <FormControl fullWidth>
                <Box display="flex" alignItems="center">
                  <Autocomplete
                    id="store-select"
                    value={selectedCompany === null ? company[0] : selectedCompany}
                    onChange={(event, value) => handleStoreChange(value)}
                    options={company}
                    placeholder='Store'
                    renderInput={(params) => (
                      <TextField {...params} label="Store" />
                    )}
                    sx={{
                      flexGrow: 1,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      pt: 1,
                    }}
                  />
                  <IconButton
                    color="primary"
                    aria-label="add store"
                    onClick={handleOpenQRModal}
                    sx={{ ml: 1 }}
                  >
                    <AddIcon />
                  </IconButton>
                  <IconButton
                    color="primary"
                    aria-label="add store"
                    onClick={handleDeleteStore}
                    sx={{ ml: 1 }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </FormControl>
            </Grid>
          )}

          {/* New Broadcast Button */}
          <Grid item xs={12} sm="auto">
            <Button
              variant="contained"
              onClick={setOpen}
              fullWidth={users && users.role < 3} // Button takes full width on small screens
              sx={{ whiteSpace: "nowrap" }}
            >
              New Broadcast
            </Button>
          </Grid>
        </Grid>
        
          {announcements.length > 0 
            ?
              <>
                
                {announcements.map(d => {
                    return (
                      <Card sx={{ width: "100%", mt: 5 }}>
                        <CardMedia
                          sx={{ height: 200 }}
                          image={d.img}
                          title="green iguana"
                        />
                        <CardContent>

                          <Typography gutterBottom variant="h5" component="div">
                            {d.title}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {d.description ? d.description : "No description"}
                          </Typography>
                          <Typography variant="body2" color="text.secondary" sx={{mt: 2}}>
                            {!d.link ? <label>No Link </label> : <Link href={d.link} target="_blank">Redirect Link</Link>}
                          </Typography>
                        </CardContent>
                        <CardActions>
                          <Button size="medium" value={d._id} onClick={(e) => handleOpenUpdate(e)}>Edit</Button>
                          <Button size="small" value={d._id} onClick={(e) => handleOpenDelete(e)}>Delete</Button>
                        </CardActions>
                      </Card> 
                    )  
                  })
                }
              </>
               
            :
              <Card>
                <img
                  src="http://com-sparkle-sparkle.herokuapp.com/static/media/nothing.108effa8.svg"
                  loading="lazy"
                />
              </Card>  
          }
        
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>New Broadcast</DialogTitle>
          <DialogContent>
            <TextField
              error
              autoFocus
              margin="dense"
              id="name"
              label="Title"
              type="text"
              fullWidth
              variant="standard"
              onChange={(e)=>{setTitle(e.target.value)}}
            />
            <TextField
              error
              margin="dense"
              id="name"
              label="Link"
              type="text"
              fullWidth
              variant="standard"
              onChange={(e) => {setLink(e.target.value)}}
            />
            <TextField
              error
              margin="dense"
              id="name"
              label="Description"
              type="text"
              fullWidth
              variant="standard"
              onChange={(e) => {setDescription(e.target.value)}}
            />
            <Button
              variant="contained"
              component="label"
              sx={{ mt: 2 }}
            >
              Choose File
              <input
                type="file"
                hidden
                accept="image/*"
                onChange={handleGetUrl}
              />
            </Button>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleUpload }>Upload</Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openEdit} onClose={handleCloseEdit}>
          <DialogTitle>Edit Broadcast -> {announcementDetails.title}</DialogTitle>
          <DialogContent>
            <TextField
              error
              autoFocus
              margin="dense"
              id="name"
              label={announcementDetails.title}
              type="text"
              fullWidth
              variant="standard"
              onChange={(e)=>{setTitle(e.target.value)}}
            />
            <TextField
              error
              margin="dense"
              id="name"
              label={announcementDetails.link ? announcementDetails.link : "Link"}
              type="text"
              fullWidth
              variant="standard"
              onChange={(e) => {setLink(e.target.value)}}
            />
            <TextField
              error
              margin="dense"
              id="name"
              label={announcementDetails.description ? announcementDetails.description : "Description"}
              type="text"
              fullWidth
              variant="standard"
              onChange={(e) => {setDescription(e.target.value)}}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseEdit}>Cancel</Button>
            <Button onClick={handleEdit}>Save</Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openDelete}
          onClose={handleCloseDelete}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">{'Do you wish to proceed with this action?'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Once action processed you may not be able to retrieve the data.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDelete} color="primary">
              No
            </Button>
            <Button onClick={handleDelete} color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openQR} onClose={handleCloseQR}>
          <DialogTitle>Scan store QR code.
            <IconButton
              aria-label="close"
              onClick={handleCloseQR}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle >
          <DialogContent>
            <>
              <QrReader
                delay={3000}
                onError={handleError}
                onScan={handleScan}
                // chooseDeviceId={()=>selected}
                style={{ width: '250px', heigth: '200px' }}
              // className={'qrScanner'}
              />
              {/*<DialogTitle style={{ display: "flex", justifyContent: "center" }}><a href="https://www.sparkletimekeeping.com/store/create">Register store account</a></DialogTitle>*/}
            </>
          </DialogContent>
        </Dialog>
      </Container>
    </Page>
  )
}
