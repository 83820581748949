import React, {useState} from 'react'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'

export default function RatingsSelect({onSelect = () => {}}) {
  const [rating, selectRating] = useState('all')

  const handleChange = (e) => {
    onSelect(e.target.value)
    selectRating(e.target.value)
  }

  return (
    <Box sx={{maxWidth: 200, padding: '0.5em'}}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Sort by rating</InputLabel>
        <Select
          value={rating}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="Sort by rating"
          onChange={handleChange}
        >
          <MenuItem value={5}>Five</MenuItem>
          <MenuItem value={4}>Four</MenuItem>
          <MenuItem value={3}>Three</MenuItem>
          <MenuItem value={2}>Two </MenuItem>
          <MenuItem value={1}>One</MenuItem>
          <MenuItem value={'all'}>All</MenuItem>
        </Select>
      </FormControl>
    </Box>
  )
}
