import { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';

const useNetworkSpeedCheck = () => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const checkConnection = () => {
      const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
      if (connection) {
        const { downlink } = connection;
        if (downlink < 0.5 && downlink > 0) {
          setMessage('Slow internet connection detected. Please wait.');
          setOpen(true);
        } else {
          setOpen(false);
        }
      }
    };

    const checkOfflineStatus = () => {
      if (!navigator.onLine) {
        setMessage('You are offline. Please check your internet connection.');
        setOpen(true);
      }
    };

    const handleConnectionChange = () => {
      checkConnection();
    };

    const interval = setInterval(() => {
      checkConnection();
      checkOfflineStatus();
    }, 2000);

    if (navigator.connection) {
      navigator.connection.addEventListener('change', handleConnectionChange);
    }

    window.addEventListener('offline', checkOfflineStatus);
    window.addEventListener('online', checkConnection);

    return () => {
      clearInterval(interval);
      if (navigator.connection) {
        navigator.connection.removeEventListener('change', handleConnectionChange);
      }
      window.removeEventListener('offline', checkOfflineStatus);
      window.removeEventListener('online', checkConnection);
    };
  }, []);

  return { open, message, setOpen };
};

export default useNetworkSpeedCheck;
