import React, {useEffect, useState, useContext, useRef, forwardRef, useMemo} from 'react'
import jwt_decode from 'jwt-decode'
// material
import {
  Box,
  Grid,
  Container,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Checkbox,
  Backdrop,
  Fade,
  Modal,
  IconButton,
  Slide,
  Stack,
  TextField,
} from '@material-ui/core'
import CloseIcon from '@mui/icons-material/Close'
import {makeStyles} from '@material-ui/styles'
// components
import Page from '../components/Page'
import {useSnackbar} from 'notistack5'
import {SocketContext} from 'utils/context/socket'
import {useNavigate} from 'react-router-dom'
import PromotionDialog from 'components/promotionDialog'
import {BreakOut, BreakIn, TimeIn, TimeOut, Completed, Loading, BreakSwitch} from 'components/timeButton'
import LocationLoading from 'components/timeButton/LocationLoading'
import HelpButton from 'components/discord'
import FacebookDialog from 'components/facebookDialog'
import page404 from 'pages/Page404'
import NewFeatureDialog from 'components/newFeatureDialog'
import {LazyLoadImage} from 'react-lazy-load-image-component'
import user_api from 'utils/api/users'
import storage from 'utils/storage'
import Clock from 'react-live-clock'
import Bugsnag from '@bugsnag/js'
import QrReader from 'react-qr-reader'
import QRCode from 'qrcode.react'
import UAParser from 'ua-parser-js'
import '../assets/css/horizontal.css'
import GenerateQR from '../assets/svg/QRCODE.svg'
import Workmate from '../assets/svg/WORKMATE.svg'
import BreakInFalse from '../assets/svg/breakTime.png'
import Carousel from 'nuka-carousel'
import {ConnectingAirportsOutlined, HighlightOff as HighlightOffIcon} from '@mui/icons-material'
import {alpha} from '@material-ui/core/styles'
import {Icon} from '@iconify/react'
import useMediaQuery from '@mui/material/useMediaQuery'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import StorefrontIcon from '@mui/icons-material/Storefront'
import QrCodeIcon from '@mui/icons-material/QrCode'
import GroupAddIcon from '@mui/icons-material/GroupAdd'
import SecureLS from 'secure-ls'
import { addDays } from 'date-fns'
import EditIcon from '@mui/icons-material/Edit'
import {FacebookProvider, CustomChat} from 'react-facebook'
const ls = new SecureLS({encodingType: 'aes'})
// ----------------------------------------------------------------------
const moment = require('moment-timezone')
moment().tz('Asia/Manila').format()
const current_date = `${moment().tz('Asia/Manila').toISOString(true)}`

let parser = new UAParser()
let browser = parser.getBrowser()

const useStyles = makeStyles({
  customWidth: {
    width: '80%',
    height: '50%',
  },
})

const dummyCurrentShift = [
  {
    id: '65c02c8e1699de002ec1ab2c',
    timeStatus: 'Time in',
    time: '08:54 AM',
    date: 'Mon, Feb 05',
  },
  {
    id: '65c02c8e1699de002ec1ab2d',
    timeStatus: 'Break in',
    time: '00:00',
    date: '',
  },
  {
    id: '65c02c8e1699de002ec1ab2e',
    timeStatus: 'Break out',
    time: '00:00',
    date: '',
  },
  {
    id: '65c02c8e1699de002ec1ab2f',
    timeStatus: 'Time out',
    time: '05:05 PM',
    date: 'Mon, Feb 05',
  },
]
const dummyStoreCheckList = [
  'I hereby declare that I am physically present upon signing in. Furthermore, I ackknowledge that I have:',
  'An appropriate attire and ID.',
  'Planned to do list/Completed all the task.',
  "Review all GC's and provide feedback",
]
const DashboardApp = () => {
  const classes = useStyles()
  let today = new Date(current_date)
  const isDesktop = useMediaQuery('(min-width: 600px)')
  const navigate = useNavigate()
  const socket = useContext(SocketContext)
  const [status, setStatus] = useState(null)
  const [switchTimeBreak, setSwitchTimeBreak] = useState(false)
  const [curStatus, setCurStatus] = useState(null)
  const [workmateStatus, setWorkmateStatus] = useState(null)
  const [currentDate, setCurrentDate] = useState(null)
  const [user, setUser] = useState({})
  const [basicUser, setBasicUser] = useState({})
  const [report, setReport] = useState({})
  const [workmateReport, setWorkmateReport] = useState({})
  const [isLoading, setLoading] = useState()
  const [totalHours, setTotalHours] = useState()
  const [value, setValue] = useState(null)
  const [validated, setValidated] = useState(false)
  const [validatedChecklist, setValidatedChecklist] = useState(false)
  const [vacCheckBoxChange, setVacCheckboxChange] = useState(false)
  const [groomingCheckboxChange, setGroomingCheckboxChange] = useState(false)
  const [nameplateCheckBoxChange, setNameplateCheckboxChange] = useState(false)
  const [uniformCheckboxChange, setUniformCheckboxChange] = useState(false)
  const [acknowledged, setAcknowledged] = useState(false)
  const [checkBoxChange, setCheckboxChange] = useState(false)
  const [saveScheduleSuccess, setSaveScheduleSuccess] = useState(false)
  const [id, setId] = useState('')
  const [stream, setStream] = useState()
  const [scanMessage, setScanMessage] = useState('No data found')
  const [name, setName] = useState('')
  const [ip, setIp] = useState('')
  const [openQR, setOpenQR] = useState(false)
  const [storeLoc, setStoreLocation] = useState(false)
  const [openWorkmateTimein, setOpenWorkmateTimein] = useState(false)
  const [openTimein, setOpenTimein] = useState(false)
  const [captureStatus, setCaptureStatus] = useState(false)
  const [timeinStatus, setTimeinStatus] = useState(true)
  const [successStatus, setSuccessStatus] = useState(true)
  const [workmateChecklist, setWorkmateChecklist] = useState(true)
  const {enqueueSnackbar} = useSnackbar()
  const [selected, setSelected] = useState(false)
  const [selectedBanner, setSelectedBanner] = useState(false)
  const [confirmSchedule, setConfirmSchedule] = useState(false)
  const [from, setFrom] = useState(new Date())
  const [to, setTo] = useState(new Date())
  const [breakMin, setBreakMin] = useState(0)
  const [position, setPosition] = useState("")
  const [qrUser, setQrUser] = useState({})
  const [banners, setBanners] = useState([])
  const [broadcast, setBroadcast] = useState([])
  const [checklists, setChecklists] = useState([
    'I testify that I am in front of the TS and management representative upon signing in. Also, I signify that I have:',
    'Complete uniform.',
    'Name plate.',
    'Proper grooming.',
    'Vaccination card.',
  ])
  const [coc, setCoc] = useState({})
  const [total, setTotal] = useState()
  const [openEditDialog, setOpenEditDialog] = useState(false)
  const [openEditCompany, setOpenEditCompany] = useState(false)
  const [currentRecordDate, setCurrentRecordDate] = useState()
  const [currentRecord, setCurrentRecord] = useState()
  const [previousLocation, setPreviousLocation] = useState(null)
  const [loadingBanner, setLoadingBanner] = useState(false)
  const [bannerStatus, setBannerStatus] = useState(false)
  const [checklistToggle, setChecklistToggle] = useState(true)
  const [specificSchedule, setSpecificSchedule] = useState({})
  const [firstName, setFirstName] = useState()
  const [lastName, setLastName] = useState()
  /*  const [newFirstName, setNewFirstName] = useState('')
  const [newLastName, setNewLastName] = useState('')*/
  const [appVersion, setAppVersion] = useState(process.env.REACT_APP_VERSION)
  const [location, setLocation] = useState({
    latitude: 0,
    longitude: 0,
  })
  const [promotionDialogState, setpromotionDialogState] = useState({
    showDialog: false,
    promotion: {},
  })
  const [open, setOpen] = useState(false)
  const [dialogStatus, setDialogStatus] = useState(false)
  const [openCurrentShift, setCurrentShift] = useState(false)
  const [initialBanner, setInitialBanner] = useState('')
  const [openOtherStore, setOpenOtherStore] = useState(false)
  const [checkOtherStore, setOtherStore] = useState(false)
  const [updateDialogOpen, setUpdateDialogOpen] = useState(true);
  const [checkSchedule, setCheckSchedule] = useState(false);
  const [confirmScheduleDialog, setConfirmScheduleDialog] = useState(false);
  const [openConfirmScheduleDialog, setOpenConfirmScheduleDialog] = useState(false);
  const [openCreateScheduleDialog, setOpenCreateScheduleDialog] = useState(false);
  const [noScheduleDialog, setNoScheduleDialog] = useState(false);
  const streamRef = useRef(null)
  /*  const [videoStatus, setVideoStatus] = useState(false)
    const [canvasStatus, setCanvasStatus] = useState(true)*/
  let videoRef = useRef(null)
  let photoRef = useRef(null)
  let browserName = browser.name

  const renderTime = (_time) => {
    let _date = new Date(_time)
    /*    var hours = _date.getHours()
        var minutes = _date.getMinutes()
        var ampm = hours >= 12 ? 'pm' : 'am'
        hours = hours % 12
        hours = hours ? hours : 12 // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes
        var strTime = hours + ':' + minutes + ' ' + ampm
        const phTime = moment.tz(strTime, 'h:mm A', 'Asia/Manila')*/
    const userTimeZone = moment.tz.guess()
    const userLocalTime = _date.toLocaleString('en-US', {timeZone: userTimeZone, hour: 'numeric', minute: '2-digit'})
    return userLocalTime
    //return strTime
  }

  const filterByStatus = (_data, type) => {
    let _d = _data.filter((_d) => (_d.status === type ? _d : ''))
    if (_d.length > 0) {
      _d = _d[0]
    }
    if (_d) {
      if (typeof _d.time === 'string') {
        return {
          time: _d.time === undefined ? 'n/a' : _d.time,
          _longitude: _d.location === undefined ? '' : _d.location.longitude,
          _latitude: _d.location === undefined ? 'n/a' : _d.location.latitude,
          workmate: _d.workmate === undefined ? '' : 'Workmate( ' + _d.workmate + ' )',
        }
      } else {
        return {
          time: _d.time === undefined ? 'n/a' : renderTime(_d.time),
          _longitude: _d.location === undefined ? '' : _d.location.longitude,
          _latitude: _d.location === undefined ? 'n/a' : _d.location.latitude,
          workmate: _d.workmate === undefined ? '' : 'Workmate( ' + _d.workmate + ' )',
        }
      }
    } else {
      return '-'
    }
  }

  const handleCloseUpdateDialog = () => {
    window.location.reload()
    setUpdateDialogOpen(false)
  }
  const load = async () => {
    setLoading(true)

    const storedVersion = ls.get('appVersion')

    // Check if running in webview
    const isWebView =
      navigator.userAgent.includes('wv') ||
      (navigator.userAgent.includes('Mobile/') && !navigator.userAgent.includes('Safari'))

    if (storedVersion === undefined || storedVersion === null || storedVersion === '' || storedVersion !== appVersion) 
    {
      setUpdateDialogOpen(true)
    }
    else {
      setUpdateDialogOpen(false)
    }
    ls.set('appVersion', appVersion)  
    let lat = ls.get('latitude')
    let long = ls.get('longitude')
    if (!lat) {
      await geolocation()
    } else {
      setLocation({
        latitude: lat,
        longitude: long,
      })
    }
    const token = await storage.getToken()
    const getUser = await storage.getUser()
    if (!getUser) {
      window.location.href = '/login'
    }
    const user = JSON.parse(getUser)
    const userFromApi = await user_api.get_user(user._id)
    if (user.company === undefined) {
      const inuser = await user_api.get_user(jwt_decode(token)['id'])
    } else {
      await handleStatus(user._id)
      setLoading(false)
      const data = {
        company: user.company,
      }
      const d = {
        store: user.company,
      }
      const getCoc = await user_api.get_company_coc(data)
      if (getCoc.data !== null) {
        setCoc(getCoc.data.link)
      }
    }
    if (!user._id) {
      alert('Something went wrong please try again')
    } else {
      const result = await user_api.get_limited_users(user._id)
      if (result.data.msg !== 'No Records') {
        const resultDate = moment(result.data[0].date)
        const currentDate = moment()
        if (!resultDate.isSame(currentDate, 'day')) {
          setCurrentRecord(null)
        }
        else {
           setCurrentRecord(result.data[0])
           const timeDifference = await getTimeDifference(result.data[0]);
  
        }
      } else {
        setCurrentRecord(null)
      }
    }

    /* const timeIn = filterByStatus(result.data[0].record, 'time-in').time
     const timeOut = filterByStatus(result.data[0].record, 'time-out').time
     if(timeOut === "n/a") {
       setTotal("n/a")
     }
     else {
       const timeOutDate = moment(timeOut, "h:mm A").toDate()
       const timeInDate = moment(timeIn, "h:mm A").toDate()
       const diffInHours = moment.duration(timeOutDate.getTime() - timeInDate.getTime()).asHours();
       setTotal(diffInHours) 
     }*/
    setBasicUser(userFromApi.data)
    const current_date = new Date()
    if (!token) {
      await storage.remove()
      return navigate('/login')
    }
    const inuser = await user_api.get_user(jwt_decode(token)['id'])
    const company = {
      company: user.company,
    }
    if (!user) {
      await storage.remove()
      return navigate('/login')
    }

    /*    const version = await user_api.get_storyblok_version()
        const bannerRequest = await user_api.get_storyblok_banners(version)
        setBanners(bannerRequest.data.story.content.slide)
        setUser(inuser.data)*/
  }
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  }
  useEffect(() => {
    load();
  }, [])

  useEffect(() => {
    if (browserName.includes('Facebook')) {
      setOpen(true)
    }

    load()
  }, [dialogStatus])

  useEffect(() => {
    if (
      // vacCheckBoxChange &&
      // groomingCheckboxChange &&
      // nameplateCheckBoxChange &&
      // uniformCheckboxChange &&
      // checkBoxChange
      acknowledged
    ) {
      getVideo('running')
      setValidatedChecklist(true)
    }
  }, [acknowledged])

  useEffect(() => {
    // setUniformCheckboxChange(false)
    // setNameplateCheckboxChange(false)
    // setGroomingCheckboxChange(false)
    // setVacCheckboxChange(false)
    // setCheckboxChange(false)
    setAcknowledged(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogStatus])

  const geolocation = async () => {
    if (location.latitude === 0) {
      await navigator.geolocation.watchPosition(function (position) {
        setLocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        })
      })
    }
    if (!location) return false
    return location
  }

  const handleUpdateStatus = async (status) => {
    try {
      let success = false
      setLoading(true)
      load()
      const loc = await geolocation()
      /*if (!status) return setLoading(false)*/

      if (location.latitude === 0 && location.longitude === 0) {
        await navigator.geolocation.watchPosition(function (position) {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          })
        })
        alert(
          'Please turn on location.\n \nHaving trouble using the web application? Try using the homescreen app for better user experience.\n \nPlease refresh this page to get prompt on how to add time-keeping to your mobile homescreen.',
        )
        setLoading(false)
      } else {
        let formatStatus = status.replace(' ', '-').toLowerCase()
        let previous = null
        let dataDate = new Date(currentDate)
        if (dataDate.getDate() !== today.getDate() || status !== 'time-in') {
          previous = currentDate
        }
        if (basicUser._id === undefined) {
          load()
          alert('Something went wrong please try again')
          setLoading(false)
        } else {
          const result = await user_api.post_user_status(formatStatus, location, basicUser._id, report._id)
          if (!result.ok) {
            setLoading(false)
            Bugsnag.notify(result)
            return enqueueSnackbar(result.data.msg, {variant: 'error'})
          } else {
            switch (result.data.status) {
              case 'time-in':
                setStatus('Time out')
                break
              case 'time-out':
                setStatus('Time in')
                break
              case 'break-in':
                setStatus('Break out')
                break
              case 'break-out':
                setStatus('Break in')
                break
              default:
                break
            }
            socket.emit('update_status', status)
            enqueueSnackbar(`${status} Success`, {variant: 'success'})
            setLoading(false)
            handleStatus(basicUser._id)
            return {status: 200}
          }
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleUpdateWorkmateStatus = async (status) => {
    let success = false
    try {
      setLoading(true)
      if (!status) return setLoading(false)
      const loc = await geolocation()
      if (location.latitude === 0 && location.longitude === 0) {
        await navigator.geolocation.watchPosition(function (position) {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          })
        })
        alert(
          'Please turn on location.\n \nHaving trouble using the web application? Try using the homescreen app for better user experience.\n \nPlease refresh this page to get prompt on how to add time-keeping to your mobile homescreen.',
        )
        setLoading(false)
      } else {
        let formatStatus = status.replace(' ', '-').toLowerCase()
        let previous = null
        let dataDate = new Date(currentDate)
        if (dataDate.getDate() !== today.getDate() || status !== 'time-in') {
          previous = currentDate
        }
        let workmate = user.displayName
        let processDate = workmateReport._id

        const result = await user_api.post_user_workmate_status(formatStatus, location, value, processDate, workmate)
        if (!result.ok) {
          setLoading(false)
          Bugsnag.notify(result)
          return enqueueSnackbar(result.data.msg, {variant: 'error'})
        }

        switch (result.data.status) {
          case 'time-in':
            setWorkmateStatus('Time out')
            break
          case 'time-out':
            setWorkmateStatus('Time in')
            break
          case 'break-in':
            setWorkmateStatus('Break out')
            break
          case 'break-out':
            setWorkmateStatus('Break in')
            break
          default:
            break
        }

        socket.emit('update_status', status)
        enqueueSnackbar(`${status} Success`, {variant: 'success'})
        setLoading(false)
        handleWorkmateStatus(value)
        return {status: 200}
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleWorkmateStatus = async (_id) => {
    if (!_id) {
      setLoading(false)
      return navigate('/login')
    }
    setLoading(true)
    const result = await user_api.get_user_status(_id)
    if (!result.ok) {
      setLoading(false)
      Bugsnag.notify(result)
      setWorkmateStatus(null)
    }

    if (!result || !result.data) {
      setWorkmateStatus(null)
      return setLoading(false)
    }
    let {status, date} = result.data[0] // get the last data
    let record_date = new Date(date)
    setWorkmateStatus(status)
    setCurrentDate(date)
    setWorkmateReport(result.data[0])
    if (record_date.getDate() !== today.getDate() && status === 'time-out') {
      setWorkmateStatus(null)
      setLoading(false)
      return
    }
    switch (status) {
      case 'time-in':
        setWorkmateStatus('Time in')
        break
      case 'time-out':
        setWorkmateStatus('Time out')
        break
      case 'break-in':
        setWorkmateStatus('Break in')
        break
      case 'break-out':
        setWorkmateStatus('Break out')
        break
      default:
        break
    }
    setLoading(false)
  }

  const handleStatus = async (_id) => {
    if (!_id) {
      return navigate('/login')
    }
    setLoading(true)
    const result = await user_api.get_user_status(_id)

    if (!result.ok) {
      setStatus(null)
      setLoading(false)
    }
    if (!result || !result.data) {
      setStatus(null)
      setLoading(false)
    }

    /*    if (!result.data) return setLoading(false)*/
    let {status, date} = result.data[0] // get the last data

    let record_date = new Date(date)

    // if (record_date.getDate() === today.getDate() && record_date.getUTCMonth() + 1 === today.getUTCMonth() + 1) {
    setStatus(status)
    setCurrentDate(date)
    setReport(result.data[0])
    // } else {
    if (record_date.getDate() !== today.getDate() && status === 'time-out') {
      setStatus(null)
    }
    // }

    switch (status) {
      case 'time-in':
        setStatus('Time in')
        break
      case 'time-out':
        setStatus('Time out')
        break
      case 'break-in':
        setStatus('Break in')
        break
      case 'break-out':
        setStatus('Break out')
        break
      default:
        setStatus(null)
        break
    }
    setLoading(false)
  }
  const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
  })

  const ButtonMemo = React.memo(ButtonContainerMemo)
  const WorkmateButtonMemo = React.memo(WorkmateButtonContainerMemo)

  function ButtonContainerMemo() {
    return <RenderButtonStatus />
  }

  function WorkmateButtonContainerMemo() {
    return <WorkmateRenderButtonStatus />
  }

  const handleSwitchTimeBreak = (statement) => {
    if (statement === true && status === 'Time in') {
      setStatus('Break out')
      RenderButtonStatus()
    } else if (statement === false && status === 'Break in') {
      setStatus('Time in')
      RenderButtonStatus()
    } else if (statement === false && status === 'Break out') {
      setStatus('Time in')
      RenderButtonStatus()
    }
  }

  const RenderButtonStatus = () => {
    const cur_status = status

    //const cur_status = 'Break in'
    switch (cur_status) {
      case null:
        return (
          <>
            {/* <Box>
                  {previousLocation && (
                  <iframe 
                      title="resumeIframe"
                      src={`https://maps.google.de/maps?hl=en&q=${location.latitude},${location.longitude}&ie=UTF8&t=&z=17&iwloc=B&output=embed`}                            
                      width="100%" 
                      height="280" 
                      frameBorder="0"
                      scrolling="no"
                      marginHeight="0"
                      marginWidth="0"
                      allowfullscreen=""
                      key={`landmark-${location.latitude}`} 
                      >
                  </iframe>
                  )}
                  <h4 style={{"margin-left": '80px'}}>Current Location</h4>
                </Box> */}

            <Grid container spacing={3} justifyContent="center" style={{'margin-top': '3px'}}>
              <Grid item>
                <TimeIn request={handleOpenTimein} current_status={cur_status} width="300px" location={location} />
              </Grid>
              <Grid item>
                <TimeOut request={handleOpenTimein} current_status={cur_status} width="300px" location={location} />
              </Grid>
            </Grid>
            <Box sx={{marginTop: '25px', display: 'flex', justifyContent: 'center', paddingLeft: '2.5rem'}}>
              {' '}
              <img src={BreakInFalse} />
            </Box>
          </>
        )
      case 'Time in':
        return (
          <>
            {/* <Box>
                {previousLocation && (
                <iframe 
                    title="resumeIframe"
                    src={`https://maps.google.de/maps?hl=en&q=${location.latitude},${location.longitude}&ie=UTF8&t=&z=17&iwloc=B&output=embed`}                            
                    width="100%" 
                    height="280" 
                    frameBorder="0"
                    scrolling="no"
                    marginHeight="0"
                    marginWidth="0"
                    allowfullscreen=""
                    key={`landmark-${location.latitude}`} 
                    >
                </iframe>
                )}
                <h4 style={{"margin-left": '80px'}}>Current Location</h4>
              </Box> */}
            <Grid container spacing={3} justifyContent="center" style={{'margin-top': '3px'}}>
              <Grid item>
                <TimeIn request={handleOpenTimein} current_status={cur_status} width="300px" location={location} />
              </Grid>
              <Grid item>
                <TimeOut request={handleOpenTimein} current_status={cur_status} width="300px" location={location} />
              </Grid>
            </Grid>
            <BreakSwitch current_status={cur_status} handleSwitchTimeBreak={handleSwitchTimeBreak} />
          </>
        )
      case 'Break in':
        return (
          <>
            {/* <Box>
                {previousLocation && (
                <iframe 
                    title="resumeIframe"
                    src={`https://maps.google.de/maps?hl=en&q=${location.latitude},${location.longitude}&ie=UTF8&t=&z=17&iwloc=B&output=embed`}                            
                    width="100%" 
                    height="280" 
                    frameBorder="0"
                    scrolling="no"
                    marginHeight="0"
                    marginWidth="0"
                    allowfullscreen=""
                    key={`landmark-${location.latitude}`} 
                    >
                </iframe>
                )}
                <h4 style={{"margin-left": '80px'}}>Current Location</h4>
              </Box> */}
            <Grid container spacing={3} justifyContent="center" style={{'margin-top': '3px'}}>
              <Grid item>
                <BreakIn request={handleOpenTimein} current_status={cur_status} width="300px" location={location} />
              </Grid>
              <Grid item>
                <BreakOut request={handleOpenTimein} current_status={cur_status} width="300px" location={location} />
              </Grid>
            </Grid>
            <BreakSwitch current_status={cur_status} handleSwitchTimeBreak={handleSwitchTimeBreak} />
          </>
        )
      case 'Break out':
        return (
          <>
            {/* <Box>
                {previousLocation && (
                <iframe 
                    title="resumeIframe"
                    src={`https://maps.google.de/maps?hl=en&q=${location.latitude},${location.longitude}&ie=UTF8&t=&z=17&iwloc=B&output=embed`}                            
                    width="100%" 
                    height="280" 
                    frameBorder="0"
                    scrolling="no"
                    marginHeight="0"
                    marginWidth="0"
                    allowfullscreen=""
                    key={`landmark-${location.latitude}`} 
                    >
                </iframe>
                )}
                <h4 style={{"margin-left": '80px'}}>Current Location</h4>
              </Box> */}
            <Grid container spacing={3} justifyContent="center" style={{'margin-top': '3px'}}>
              <Grid item>
                <BreakIn request={handleOpenTimein} current_status={cur_status} width="300px" location={location} />
              </Grid>
              <Grid item>
                <BreakOut request={handleOpenTimein} current_status={cur_status} width="300px" location={location} />
              </Grid>
            </Grid>
            <BreakSwitch current_status={cur_status} handleSwitchTimeBreak={handleSwitchTimeBreak} />
          </>
        )
      case 'Time out':
        return (
          <>
            <Completed currentDate={currentDate} />
            <div style={{display: 'flex', justifyContent: 'center', marginTop: '1.5rem'}}>
              <Button
                onClick={() => setStatus(null)}
                sx={{
                  marginTop: '1.5rem',
                  borderRadius: 28,
                  backgroundColor: '#1F98E8',
                  boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.35)!important',
                  color: 'white!important',
                  width: '150px',
                }}
              >
                New Shift
              </Button>
            </div>
          </>
        )
      default:
        return <Loading />
    }
  }

  const WorkmateRenderButtonStatus = () => {
    const cur_status = workmateStatus

    switch (cur_status) {
      case null:
        return <TimeIn request={handleUpdateWorkmateStatus} width="215px" location={location} />
      case 'Time in':
        return (
          <>
            <TimeOut
              request={handleUpdateWorkmateStatus}
              width="215px"
              current_status={cur_status}
              location={location}
            />
            <BreakIn
              request={handleUpdateWorkmateStatus}
              width="215px"
              current_status={cur_status}
              location={location}
            />
            <span> for {` ${new Date(currentDate).toDateString()} `}</span>
          </>
        )
      case 'Break in':
        return (
          <>
            <BreakOut
              request={handleUpdateWorkmateStatus}
              width="215px"
              current_status={cur_status}
              location={location}
            />
            <TimeOut
              request={handleUpdateWorkmateStatus}
              width="215px"
              current_status={cur_status}
              location={location}
            />
            <span> for {` ${new Date(currentDate).toDateString()} `}</span>
          </>
        )
      case 'Break out':
        return (
          <>
            <TimeOut
              request={handleUpdateWorkmateStatus}
              width="215px"
              current_status={cur_status}
              location={location}
            />
            <BreakIn
              request={handleUpdateWorkmateStatus}
              width="215px"
              current_status={cur_status}
              location={location}
            />
            <span> for {` ${new Date(currentDate).toDateString()} `}</span>
          </>
        )
      case 'Time out':
        return (
          <>
            <Completed currentDate={currentDate} />
            <button onClick={() => setWorkmateStatus(null)}>Ok</button>
          </>
        )
      default:
        return <Loading />
    }
  }

  const handleClose = () => {
    setSelected(false)
  }

  const handleOpenTimein = async (cur_status) => {
    const location = await geolocation()
    if (location.latitude === 0) {
      geolocation()
      alert('Please turn on location of mobile and browser, refresh then try again')
      setLoading(false)
    } else {
      const data = {
        store: basicUser.company,
      }
      const checklist = await user_api.get_checklist(data)
      if (checklist.data.data !== 'No records found') {
        setChecklistToggle(checklist.data.data[0].toggle)
        setChecklists(checklist.data.data[0].checklists)
      }
      setSuccessStatus(true)
      setDialogStatus(false)
      setCurStatus(cur_status)
      setOpenTimein(true)
      setTimeinStatus(true)
      setCaptureStatus(false)
    }
    /*if(!ls.get("schedStatus")) {
      setConfirmScheduleDialog(true)
    }
    else {
      const location = await geolocation()
      if (location.latitude === 0) {
        geolocation()
        alert('Please turn on location of mobile and browser, refresh then try again')
        setLoading(false)
      } else {
        const data = {
          store: basicUser.company,
        }
        const checklist = await user_api.get_checklist(data)
        if (checklist.data.data !== 'No records found') {
          setChecklistToggle(checklist.data.data[0].toggle)
          setChecklists(checklist.data.data[0].checklists)
        }
        setSuccessStatus(true)
        setDialogStatus(false)
        setCurStatus(cur_status)
        setOpenTimein(true)
        setTimeinStatus(true)
        setCaptureStatus(false)
      }  
    }*/
    
  }

  const handleCloseTimein = async () => {
    setValidatedChecklist(false)
    setSuccessStatus(true)
    setTimeinStatus(true)
    setDialogStatus(false)
    // setUniformCheckboxChange(false)
    // setNameplateCheckboxChange(false)
    // setGroomingCheckboxChange(false)
    // setVacCheckboxChange(false)
    setAcknowledged(false)
    setOpenTimein(false)
    getVideo('done')
    load()
    setLoading(false)
  }

  const checkAll = async (e) => {
    setCheckboxChange(e)
    setUniformCheckboxChange(e)
    setNameplateCheckboxChange(e)
    setGroomingCheckboxChange(e)
    setVacCheckboxChange(e)
  }


  const handleCloseOtherStore = async () => {
    setOpenOtherStore(false)
    setOtherStore(false)
  }

  const handleOpenWorkmateTimein = async () => {
    setOpenWorkmateTimein(true)
  }

  const handleCloseWorkmateTimein = () => {
    setValue(null)
    setOpenWorkmateTimein(false)
    setValidated(false)
    setLoading(false)
    setValidatedChecklist(false)
    setScanMessage('No data found')
  }
  const handleScan = async (res) => {
    if (res) {
      const result = await user_api.get_user_status(res)
      if (result.data[0].status === 'time-out') {
        setCurStatus('Time in')
      }
      if (result.data[0].status !== 'time-out' && result.data[0].status === 'time-in') {
        setCurStatus('Time out')
      }
      setValue(res)
      const userResult = await user_api.get_user(res)
      setQrUser(userResult.data)
    }
  }

  const handleConfirmScheduleScan = async (res) => {
    if (res) {
      const parts = res.split("/");
      const id = parts[parts.length - 2]
      const result = await user_api.get_store(id)
      if(result.data.company === basicUser.company) {
        setLoading(true)
        let totalHours = 0
        let totalHours2 = 0
        if (typeof (from) !== "string" || typeof (to) !== "string") {
          alert("Please choose start shift and end shift")
          setLoading(false)
        }
        else if (!position) {
          alert("Please choose position")
          setLoading(false)
        }
        else {
          if (typeof (from) === "string" || typeof (to) === "string") {
            const startDate = new Date(`2023-03-15T${from}`);
            const endDate = new Date(`2023-03-15T${to}`);

            const diffInMilliseconds = endDate - startDate;
            const diffInSeconds = diffInMilliseconds / 1000;
            const diffInMinutes = diffInSeconds / 60;
            const diffInHours = diffInMinutes / 60;
            const hours = breakMin / 60;
            totalHours = getTimeDifferenceSchedule(from, to)
            totalHours2 = totalHours.hours + (totalHours.minutes / 60) - hours;
          }
          const now = new Date();
          const data = {
            uid: basicUser._id,
            name: basicUser.displayName,
            company: basicUser.company,
            date: moment(addDays(now, 0)).format('L'),
            from: from,
            to: to,
            totalHours: totalHours2,
            breakMin: breakMin,
            position: position,
            ot: 0,
            nightdiff: 0,
            rd: 0
          }
          const response = await user_api.post_schedule(data)
          if (response.status === 200) {
              alert("Schedule created successfuly")
              setSaveScheduleSuccess(false)
              setOpenCreateScheduleDialog(false)
              setLoading(false)
          }
          else {
            alert("Something went wrong please try again later")
            setLoading(false)
          }
        }  
      }
    }
  }

  const handleError = (err) => {
    console.error(err)
  }

  function handleCheckboxChange(e) {
    setValidated(e.target.checked)
  }

  const handleCheckboxChangeStore = (event) => {
    setOtherStore(event.target.checked)
  }

  const downloadQRCode = () => {
    // Generate download with use canvas and stream
    const canvas = document.getElementById('qr-gen')
    const pngUrl = canvas.toDataURL('image/png')
    let downloadLink = document.createElement('a')
    downloadLink.href = pngUrl

    // Check if running in a React Native WebView environment
    if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
      // Send the image data to the React Native app
      window.ReactNativeWebView.postMessage(pngUrl)
    }
    downloadLink.download = `${name}.png`
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  }

  const handleGenerateQR = (e) => {
    setId(basicUser._id)
    setName(basicUser.displayName)
    setOpenQR(true)
  }

  const handleOpenStore = (e) => {
    setOpenOtherStore(true)
  }
  const handleCloseQR = () => setOpenQR(false)

  const stopStream = (stream) => {
    stream.getTracks().forEach((track) => {
      if (track.readyState === 'live') {
        track.stop()
      }
    })
  }

  const getVideo = async (status) => {
    try {
      if (status === 'running') {
        const stream = await navigator.mediaDevices.getUserMedia({video: true})
        streamRef.current = stream // Store the stream in a ref
        if (videoRef.current) {
          videoRef.current.srcObject = stream
          videoRef.current.play()
          videoRef.current.style.transform = 'scaleX(-1)';
        }
      } else if (status === 'done') {
        if (streamRef.current) {
          stopStream(streamRef.current)
          streamRef.current = null
        }
      }
      setStatus(status)
    } catch (err) {
      console.error('Error accessing camera:', err)
    }
  }

  const takePicture = async () => {
    const location = await geolocation();
    if (location.latitude === 0) {
      await geolocation();
      alert('Please turn on location of mobile and browser');
      setValidatedChecklist(false);
      setSuccessStatus(true);
      setTimeinStatus(true);
      setDialogStatus(false);
      setAcknowledged(false)
      // setUniformCheckboxChange(false);
      // setNameplateCheckboxChange(false);
      // setGroomingCheckboxChange(false);
      // setVacCheckboxChange(false);
      setOpenTimein(false);

      setLoading(false);
    } else {
      setLocation({
        latitude: location.latitude,
        longitude: location.longitude,
      });
      const width = 1000;
      const height = width / (9 / 9);
      let video = videoRef.current;
      let photo = photoRef.current;
      photo.width = width;
      photo.height = height;
      let ctx = photo.getContext('2d');

      // Flip the canvas context horizontally before drawing the image
      ctx.save();
      ctx.scale(-1, 1);  // Horizontal flip
      ctx.drawImage(video, -width, 0, width, height); // Draw flipped image
      ctx.restore();

      ctx.font = '800 30px Veranda';
      ctx.globalAlpha = 0.7;
      ctx.fillRect(0, 0, 600, 400);
      ctx.fillStyle = 'white';
      ctx.fillText(curStatus, 50, 75);
      if (openWorkmateTimein) {
        ctx.fillText(qrUser.displayName, 50, 135);
      } else {
        ctx.fillText(basicUser.displayName, 50, 135);
      }
      ctx.fillText(moment(), 50, 195);
      ctx.fillText('longitude: ' + location.longitude, 50, 255);
      ctx.fillText('latitude: ' + location.latitude, 50, 325);
      // setVacCheckboxChange(false);
      // setGroomingCheckboxChange(false);
      // setNameplateCheckboxChange(false);
      // setUniformCheckboxChange(false);
      // setCheckboxChange(false);
      setAcknowledged(false)
      getVideo('done');
      setCaptureStatus(true);
      setTimeinStatus(false);
    }
  };

  const clearImage = () => {
    let photo = photoRef.current
    let ctx = photo.getContext('2d')
    ctx.clearRect(0, 0, photo.width, photo.height)
    setCaptureStatus(false)
    setTimeinStatus(true)
    getVideo('running')
  }

  const handleTimein = async () => {
    setLoading(true)
    getVideo('running')
    setTimeinStatus(true)
    setDialogStatus(true)

    if (openWorkmateTimein) {
      const result = await handleUpdateWorkmateStatus(curStatus)
      if (result.status === 200) {
        setSuccessStatus(false)
        const canvas = document.getElementById('canvas')
        const pngUrl = canvas.toDataURL('image/png')
        let downloadLink = document.createElement('a')
        downloadLink.href = pngUrl

        // Check if running in a React Native WebView environment
        if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
          // Send the image data to the React Native app
          window.ReactNativeWebView.postMessage(pngUrl)
        }
        downloadLink.download = `${curStatus} - ${moment().format('LL')}.png`
        document.body.appendChild(downloadLink)
        downloadLink.click()
        document.body.removeChild(downloadLink)
        setTimeout(function () {
          setOpenWorkmateTimein(false)
          setLoading(false)
        }, 5000)
      }
    } else {
      const result = await handleUpdateStatus(curStatus)
      if ((result.status === 200)) {
        setSuccessStatus(false)
        const canvas = document.getElementById('canvas')
        const pngUrl = canvas.toDataURL('image/png')
        let downloadLink = document.createElement('a')
        downloadLink.href = pngUrl

        // Check if running in a React Native WebView environment
        if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
          // Send the image data to the React Native app
          window.ReactNativeWebView.postMessage(pngUrl)
        }
        downloadLink.download = `${curStatus} - ${moment().format('LL')}.png`
        document.body.appendChild(downloadLink)
        downloadLink.click()
        document.body.removeChild(downloadLink)
        setTimeout(function () {
          load()
          setOpenTimein(false)
          setLoading(false)
        }, 5000)
      }
    }
    window.location.reload()
  }

  const handleOpenBanner = async () => {
    setLoadingBanner(true)
    setInitialBanner('')
    // const getUser = await storage.getUser()
    // const user = JSON.parse(getUser)
    // const d = {
    //   store: user.company
    // }
    // const getBroadcast = await user_api.get_store_announcement(d)
    // if (getBroadcast.data.data.length > 0) {
    //   setBroadcast(getBroadcast.data.data)
    //   setSelectedBanner(true)
    //   setLoadingBanner(false)
    // }else{
    //   const version = await user_api.get_storyblok_version()
    //   const bannerRequest = await user_api.get_storyblok_banners(version)
    //   setBanners(bannerRequest.data.story.content.slide)
    //   setSelectedBanner(true)
    //   setLoadingBanner(false)
    // }
    const announcement = localStorage.getItem('announceUrl')
    if (announcement) {
      setBroadcast(JSON.parse(announcement))
      setSelectedBanner(true)
      setLoadingBanner(false)
    } else {
      const story = localStorage.getItem('storyUrl')
      setBanners(JSON.parse(story))
      setSelectedBanner(true)
      setLoadingBanner(false)
    }
  }
  const handleCloseBanner = () => {
    const announcement = localStorage.getItem('announceUrl')
    if (announcement) {
      const parsedAnnouncement = JSON.parse(announcement)
      if (parsedAnnouncement.length > 0) {
        const randomIndex = Math.floor(Math.random() * parsedAnnouncement.length)
        setInitialBanner(parsedAnnouncement[randomIndex])
      }
      setSelectedBanner(false)
    } else {
      const story = localStorage.getItem('storyUrl')
      if (story) {
        const parsedStory = JSON.parse(story)
        if (parsedStory.length > 0) {
          const randomIndex = Math.floor(Math.random() * parsedStory.length)
          setInitialBanner(parsedStory[randomIndex])
        }
      }
      setSelectedBanner(false)
    }
  }

  const MINUTE_MS = 15000

  const handleClickCurrentShift = () => {
    if (currentRecord) {
      const formattedDate = moment(currentRecord.date).format('ddd MMM DD')
      setCurrentRecordDate(formattedDate)
      setCurrentShift(true)
    } else {
      setCurrentShift(false)
      alert('Data is being loaded please wait')
    }
  }

  const handleCurrentShiftClose = () => {
    setCurrentShift(false)
  }

  const handleConfirmSchedule = async () => {

    const getSchedule = await user_api.get_specific_schedule(basicUser._id)
    if(getSchedule.data.length > 0) {
      setSpecificSchedule(getSchedule.data[0])
      const EXPIRY_TIME_IN_HOURS = 12
      const currentTime = new Date().getTime()
      const expiryTime = currentTime + EXPIRY_TIME_IN_HOURS * 60 * 60 * 1000
      ls.set("schedStatus", "true")
      ls.set("expiry", expiryTime)
      setConfirmSchedule(true)
      setNoScheduleDialog(false)
      setOpenConfirmScheduleDialog(true)  
      setCheckSchedule(true)  
      window.location.reload()
    }
    else {
      setNoScheduleDialog(true)
    }
  }
  /* useEffect(() => {
     if (selectedBanner) {
       const interval = setInterval(async () => {
         const getUser = await storage.getUser()
         const user = JSON.parse(getUser)
         const d = {
           store: user.company
         }
         const getBroadcast = await user_api.get_store_announcement(d)
         if (getBroadcast.data !== null) {
           setBroadcast(getBroadcast.data.data)
         }
 
       }, MINUTE_MS);
 
       return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
     }
   }, [selectedBanner])*/
  /*  useEffect(() => {

    const interval = setInterval(() => {

      if (!navigator.onLine) {
        alert('You are offline. Please check your internet connection.');
      }

    }, 1000);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [])
*/
  useEffect(() => {
    const fetchData = async () => {
      try {
        const getUser = await storage.getUser()
        const user = JSON.parse(getUser)
        const d = {store: user.company}

        const getBroadcast = await user_api.get_store_announcement(d)
        const announcements = getBroadcast.data.data
        if (announcements.length > 0) {
          const randomNum = Math.floor(Math.random() * announcements.length)
          setInitialBanner(announcements[randomNum])
          localStorage.setItem('announceUrl', JSON.stringify(announcements))
        }
      } catch (error) {
        console.error('Error fetching data:', error)
      } finally {
        setLoadingBanner(false)
      }
    }

    fetchData()
  }, [])

  useEffect(async () => {
    const fetchSchedule = async () => {
      const currentTime = new Date().getTime()
      const expiry = ls.get("expiry")
      if (expiry && currentTime < expiry){
        ls.set("schedStatus", "false")
    }
    try {
      const getUser = await storage.getUser()
      const user = JSON.parse(getUser)
      const getSchedule = await user_api.get_specific_schedule(user._id)
      if(getSchedule.data.length > 0) {
        setSpecificSchedule(getSchedule.data[0])
        setCheckSchedule(true)  
      }
      else {
        ls.remove("schedStatus")
        setCheckSchedule(false)
      }
        
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      setLoadingBanner(false)
    }
  }

    await fetchSchedule()
  }, [])
  function convertToAmPm(time) {
      let [hours, minutes] = time.split(":").map(Number);
      let period = hours >= 12 ? "PM" : "AM";
      hours = hours % 12 || 12;
      return `${hours}:${minutes.toString().padStart(2, "0")} ${period}`;
  }

  function formatDate(inputDate) {
      const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      const [year, month, day] = inputDate.split("-");
      return `${months[Number(month) - 1]} ${Number(day)}, ${year}`;
  }
  
  function getTimeDifference(currentRecord) {

      if(currentRecord && currentRecord.status === "time-out") {
        let breakInRecord
        let breakOutRecord
        let breakTimeDifferenceHours
        const timeInRecord = filterByStatus(currentRecord.record, 'time-in').time;
        const timeOutRecord = filterByStatus(currentRecord.record, 'time-out').time;
        if(currentRecord && filterByStatus(currentRecord.record, 'break-out').time !== "n/a") {
          
          breakInRecord = filterByStatus(currentRecord.record, 'break-in').time;
          breakOutRecord = filterByStatus(currentRecord.record, 'break-out').time; 
          const startBreakInRecord = moment(breakInRecord, "h:mm A");
          const endBreakOutRecord = moment(breakOutRecord, "h:mm A");
          breakTimeDifferenceHours = Math.floor(endBreakOutRecord.diff(startBreakInRecord, "hours", true)); 
        }

        const start = moment(timeInRecord, "h:mm A");
        const end = moment(timeOutRecord, "h:mm A");
        if (end.isBefore(start)) {
            end.add(1, "day");
        }
        const differenceHours = Math.floor(end.diff(start, "hours", true));
        if(currentRecord && filterByStatus(currentRecord.record, 'break-out').time !== "n/a") {
          
          setTotalHours(differenceHours - breakTimeDifferenceHours)
        }
        else {

          setTotalHours(differenceHours)
        }
      }
      
  }

  const handleEditDisplayName = () => {
    setFirstName(basicUser.firstName)
    setLastName(basicUser.lastName)
    setOpenEditDialog(true)
  }

  const handleSetSchedule = () => {
    setFirstName(basicUser.firstName)
    setLastName(basicUser.lastName)
    setOpenEditDialog(true)
  }

  const handleEditCompanyName = async () => {
    setOpenEditCompany(true)
  }
  const handleCloseEditDialog = () => {
    setFirstName()
    setLastName()
    setOpenEditDialog(false)
  }
  const handleCloseEditCompany = () => {
    setOpenEditCompany(false)
  }
  const handleScanStoreQR = async (res) => {
    if (res) {
      const idFromUrl = res.split('/').filter(Boolean)[2]
      const result = await user_api.get_store(idFromUrl)
      const data = {
        company: result.data.company,
      }
      const updateResult = await user_api.edit_user_company(basicUser._id, data)
      if (updateResult.status === 200) {
        setBasicUser(updateResult.data.data)
        alert('Company update success!')
        setOpenEditCompany(false)
      } else {
        alert('Something went wrong please try again later')
      }
    } else {
      alert('No link found in the response.')
    }
  }
  const handleErrorStoreQR = (err) => {
    alert(err)
  }
  const handleSubmitUpdates = async () => {
    const data = {
      firstName: firstName,
      lastName: lastName,
    }
    const result = await user_api.edit_user_profile(basicUser._id, data)
    if (result.status === 200) {
      alert('Update profile success.')
      const user = await user_api.get_user(basicUser._id)
      await setBasicUser(user.data)
      setOpenEditDialog(false)
    } else {
      alert('Something went wrong please try again later!')
    }
  }

  function getTimeDifferenceSchedule(startTime, endTime) {
    // Parse the start and end times
    const startParts = startTime.split(':');
    const endParts = endTime.split(':');

    // Create Date objects for the times
    const startDate = new Date();
    startDate.setHours(startParts[0], startParts[1], 0, 0);

    const endDate = new Date(startDate);
    endDate.setHours(endParts[0], endParts[1], 0, 0);

    // Handle the case where end time is on the next day
    if (endDate <= startDate) {
      endDate.setDate(endDate.getDate() + 1);
    }

    // Calculate the difference in milliseconds
    const timeDifferenceMilliseconds = endDate - startDate;

    // Convert the difference to hours and minutes
    const hoursDifference = Math.floor(timeDifferenceMilliseconds / (1000 * 60 * 60));
    const minutesDifference = Math.floor((timeDifferenceMilliseconds % (1000 * 60 * 60)) / (1000 * 60));

    return {
      hours: hoursDifference,
      minutes: minutesDifference
    };
  }

  const handleSaveSchedule = async () => {
    setLoading(true)
    let totalHours = 0
    let totalHours2 = 0
    if (typeof (from) !== "string" || typeof (to) !== "string") {
      alert("Please choose start shift and end shift")
      setLoading(false)
    }
    else if (!position) {
      alert("Please choose position")
      setLoading(false)
    }
    else {
      setSaveScheduleSuccess(true)
      setLoading(false)
    }
  }

  const handleOpenCreateScheduleDialog = () => {
    setOpenCreateScheduleDialog(true)
  }

  const handleCloseCreateScheduleDialog = () => {
    setOpenCreateScheduleDialog(false)
  }

  return (
    <Page title="Dashboard | Time in">
      <FacebookProvider appId={process.env.REACT_APP_FB_APP_ID} chatSupport>
        <CustomChat pageId={process.env.REACT_APP_FB_PAGE_ID} minimized={false} />
      </FacebookProvider>
      {/*<NewFeatureDialog />*/}
      {!open ? (
        <>
          <Box sx={{p: 2}} style={{width: '100%', backgroundColor: '#2194F3', color: '#fff'}}>
            <Grid container direction="row" justifyContent="space-between">
              <Grid item xs={8} sm={6}>
                <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                  <Icon icon="iconoir:user-circle" style={{fontSize: '2rem', color: 'white'}} />
                  <Box>
                    {/* Display Name with Edit Button */}
                    <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                      <Typography variant="body2" style={{margin: 0}}>
                        {basicUser.displayName ? basicUser.displayName : `Loading`}
                      </Typography>
                      <IconButton
                        onClick={handleEditDisplayName}
                        aria-label="edit-display-name"
                        size="small"
                        sx={{padding: '0'}}
                      >
                        <EditIcon fontSize="small" style={{color: 'white'}} />
                      </IconButton>
                    </Box>

                    {/* Company Name with Edit Button */}
                    <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                      <Typography variant="body2" style={{margin: 0}}>
                        {basicUser.company ? basicUser.company : 'Loading'}
                      </Typography>
                      <IconButton
                        onClick={handleEditCompanyName}
                        aria-label="edit-company-name"
                        size="small"
                        sx={{padding: '0'}}
                      >
                        <EditIcon fontSize="small" style={{color: 'white'}} />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={4} sm={6}>
                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  <Typography variant="body2">
                    <Box fontWeight="fontWeightMedium" display="inline">
                      <Clock format={'ll'} ticking={true} timezone={'Asia/Manila'} />
                    </Box>
                  </Typography>
                  <Typography variant="body2">
                    <Box fontWeight="fontWeightMedium" display="inline">
                      <Clock format={'HH:mm:ss'} ticking={true} timezone={'Asia/Manila'} />
                    </Box>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Container maxWidth="xl" style={{margin: '0px 0px 0px 0px'}}>

            <Box sx={{mt: '0rem'}}>
              <Grid spacing={0} direction="column" alignItems="center" justify="center" style={{minHeight: '60vh'}}>
                <>

                  <Box container>
                    <Typography
                      variant="h7"
                      sx={{
                        ml: '.5rem',
                        display: 'flex',
                        justifyContent: 'end',
                        alignItems: 'end',
                        textAlign: 'end',
                        fontSize: '0.90rem',
                      }}
                    >
                      App Version: {process.env.REACT_APP_VERSION}
                    </Typography>
                        <>
                          {/*<Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>  
                            <Typography
                              variant="h4"
                              sx={{
                                ml: '.5rem',
                                mt: '2rem',
                                display: 'flex',
                                justifyContent: 'start',
                                alignItems: 'start',
                                textAlign: 'start', // Ensures text is centered inside the container
                              }}
                            >
                              My Schedule
                            </Typography>
                            {!ls.get("schedStatus")
                              ?
                                <IconButton
                                  onClick={handleOpenCreateScheduleDialog}
                                  aria-label="edit-display-name"
                                  size="small"
                                  sx={{padding: '0', mt: '2rem'}}
                                >
                                  <EditIcon fontSize="small" style={{color: 'black'}} />
                                </IconButton>   
                              :
                                ''
                            }
                            
                          </Box>
                     <Box
                           sx={{
                             display: "flex",
                             alignItems: "center",
                             border: 1,
                             borderRadius: 1,
                             borderColor: "#2194F3",
                             overflow: "hidden",
                             width: "100%",
                             marginTop: ".5rem",
                           }}
                         >
                           <Box
                             sx={{
                               display: "flex",
                               gap: ".5rem",
                               padding: ".5rem",
                               flex: 1,
                               justifyContent: "space-around",
                             }}
                           >
                             <Box sx={{ textAlign: "center" }}>
                               <Typography variant="caption">Position</Typography>
                               <br/>
                               <Typography variant="caption" sx={{ fontSize: ".6rem", color: "blue" }}>
                                 {specificSchedule?.position ? specificSchedule.position : "-"}
                               </Typography>
                             </Box>
                             <Box sx={{ textAlign: "center" }}>
                               <Typography variant="caption">Start Shift</Typography>
                               <br/>
                               <Typography variant="caption" sx={{ fontSize: ".6rem", color: "green" }}>
                                 {specificSchedule?.from ? convertToAmPm(specificSchedule.from) : "00:00"}
                               </Typography>
                             </Box>
                             <Box sx={{ textAlign: "center" }}>
                               <Typography variant="caption">End Shift</Typography>
                               <br/>
                               <Typography variant="caption" sx={{ fontSize: ".6rem", color: "green" }}>
                                 {specificSchedule?.to ? convertToAmPm(specificSchedule.to) : "00:00"}
                               </Typography>
                             </Box>
                             <Box sx={{ textAlign: "center" }}>
                               <Typography variant="caption">Date</Typography>
                               <br/>
                               <Typography variant="caption" sx={{ fontSize: ".6rem", color: "red" }}>
                                 {specificSchedule?.date ? formatDate(specificSchedule.date.split("T")[0]) : "-"}
                               </Typography>
                             </Box>
                           </Box>
                           <Button
                             variant="contained"
                             sx={{
                               borderRadius: "0 8px 8px 0",
                               height: "65px",
                               minWidth: "40px",
                               backgroundColor: "#2194F3",
                               color: "white",
                               textTransform: "none",
                               fontWeight: "bold",
                               fontSize: ".8rem",
                               "&:hover": { backgroundColor: "#666" },
                             }}
                             disabled={ls.get("schedStatus") === "false" ? true : false}
                             onClick={handleConfirmSchedule}
                           >
                             Confirm
                           </Button>
                         </Box>*/}
                          <Typography
                            variant="h4"
                            sx={{
                              ml: '.5rem',
                              mt: '.5rem',
                              display: 'flex',
                              justifyContent: 'start',
                              alignItems: 'start',
                              textAlign: 'start', // Ensures text is centered inside the container
                            }}
                          >
                            My Time Record
                          </Typography>
                          <Box
                            component="button"
                            border={1}
                            borderRadius={1}
                            borderColor="#2194F3"
                            sx={{
                              display: 'flex',
                              gap: '.5rem',
                              padding: '.5rem',
                              'margin-right': '.5rem',
                              'margin-top': '.5rem',
                              justifyContent: 'center',
                              width: '100%',
                            }}
                            onClick={handleClickCurrentShift}
                          >
                            <Box sx={{alignContent: 'center', textAlign: 'center'}}>
                              <Typography variant="caption" sx={{ color: "#2194F3" }}>Time In</Typography>
                              <br />
                              <Typography variant="caption" sx={{fontSize: '.6rem', color: 'blue'}}>
                                {currentRecord ? filterByStatus(currentRecord.record, 'time-in').time : 'N/A'}
                              </Typography>
                            </Box>
                            <Box sx={{alignContent: 'center', textAlign: 'center'}}>
                              <Typography variant="caption" sx={{ color: "#2194F3" }}>Break In</Typography>
                              <br />
                              <Typography variant="caption" sx={{fontSize: '.6rem', color: 'green'}}>
                                {currentRecord ? filterByStatus(currentRecord.record, 'break-in').time : 'N/A'}
                              </Typography>
                            </Box>
                            <Box sx={{alignContent: 'center', textAlign: 'center'}}>
                              <Typography variant="caption" sx={{ color: "#2194F3" }}>Break Out</Typography>
                              <br />
                              <Typography variant="caption" sx={{fontSize: '.6rem', color: 'green'}}>
                                {currentRecord ? filterByStatus(currentRecord.record, 'break-out').time : 'N/A'}
                              </Typography>
                            </Box>
                            <Box sx={{alignContent: 'center', textAlign: 'center'}}>
                              <Typography variant="caption" sx={{ color: "#2194F3" }}>Time Out</Typography>
                              <br />
                              <Typography variant="caption" sx={{fontSize: '.6rem', color: 'red'}}>
                                {currentRecord ? filterByStatus(currentRecord.record, 'time-out').time : 'N/A'}
                              </Typography>
                            </Box>
{/*                            <Box sx={{alignContent: 'center', textAlign: 'center'}}>
                              <Typography variant="caption">Total Hours</Typography>
                              <br />
                              <Typography variant="caption" sx={{fontSize: '.6rem', color: 'orange'}}>
                                {totalHours ? totalHours : '0'}
                              </Typography>
                            </Box>*/}
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                              <ArrowForwardIosIcon sx={{'margin-left': '3rem', 'margin-top': '.3rem', "height": "47px"}} />
                            </Box>
                          </Box>
                        </>
                  </Box>  
                </>
                {/*  <label>{location.latitude}</label>*/}
                {isLoading ? <Loading /> : <ButtonMemo />}

                {loadingBanner === false ? (
                  broadcast.length > 0 && selectedBanner !== false ? (
                    <Box>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'start',
                          marginBottom: '-15px',
                          zIndex: 1,
                          position: 'relative',
                        }}
                      >
                        <Icon
                          icon="solar:close-square-bold-duotone"
                          style={{fontSize: '2rem', color: '#c4c0c0'}}
                          onClick={() => {
                            handleCloseBanner()
                          }}
                        />
                      </Box>
                      <Carousel
                        autoplay={true}
                        withoutControls={true}
                        wrapAround={true}
                        cellSpacing={10}
                        cellAlign="center"
                        slidesToShow={1.3}
                        transitionMode={'scroll3d'}
                        style={{
                          height: '30vh',
                          width: '40vh',
                          margin: 'auto', // Optional: Center the carousel horizontally
                        }}
                      >
                        {broadcast.map((banner, key) => {
                          return (
                            <div key={banner._id}>
                              <img
                                src={banner.img}
                                style={{height: '25vh'}}
                                onClick={() => {
                                  if (banner.link) {
                                    window.open(banner.link)
                                  } else {
                                    setpromotionDialogState((prevState) => ({
                                      ...prevState,
                                      showDialog: true,
                                      promotion: banner,
                                    }))
                                  }
                                }}
                              />
                              <PromotionDialog
                                showDialog={promotionDialogState.showDialog}
                                promotion={promotionDialogState.promotion}
                                onClose={() => {
                                  setpromotionDialogState((prevState) => ({
                                    ...prevState,
                                    showDialog: false,
                                  }))
                                }}
                              />
                            </div>
                          )
                        })}
                      </Carousel>
                    </Box>
                  ) : (
                    <>
                      {banners.length > 0 && selectedBanner !== false ? (
                        <Box>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'start',
                              marginBottom: '-15px',
                              zIndex: 1,
                              position: 'relative',
                            }}
                          >
                            <Icon
                              icon="solar:close-square-bold-duotone"
                              style={{fontSize: '2rem', color: '#c4c0c0'}}
                              onClick={() => {
                                handleCloseBanner()
                              }}
                            />
                          </Box>
                          <Carousel
                            autoplay={true}
                            withoutControls={true}
                            wrapAround={true}
                            cellSpacing={10}
                            cellAlign="center"
                            slidesToShow={1.3}
                            transitionMode={'scroll3d'}
                            style={{
                              height: '30vh',
                              width: '40vh',
                              margin: 'auto', // Optional: Center the carousel horizontally
                            }}
                          >
                            {banners.map((banner, key) => {
                              return (
                                <div key={banner._uid}>
                                  <img
                                    src={banner.image.filename}
                                    onClick={() => {
                                      if (banner.redirect) {
                                        window.location.href = banner.link
                                      } else {
                                        setpromotionDialogState((prevState) => ({
                                          ...prevState,
                                          showDialog: true,
                                          promotion: banner,
                                        }))
                                      }
                                    }}
                                  />
                                  <PromotionDialog
                                    showDialog={promotionDialogState.showDialog}
                                    promotion={promotionDialogState.promotion}
                                    onClose={() => {
                                      setpromotionDialogState((prevState) => ({
                                        ...prevState,
                                        showDialog: false,
                                      }))
                                    }}
                                  />
                                </div>
                              )
                            })}
                          </Carousel>
                        </Box>
                      ) : (
                        ''
                      )}
                    </>
                  )
                ) : (
                  <Loading />
                )}

                {loadingBanner === false && !selectedBanner && (
                  <>
                    {initialBanner && (
                      <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '25px'}}>
                        <Box
                          component="img"
                          sx={{
                            height: 'auto',
                            width: '25vh',
                            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // Adds a subtle shadow
                          }}
                          alt="Banner"
                          src={bannerStatus ? initialBanner.image.filename : initialBanner.img}
                        />
                        <Box onClick={handleOpenBanner}>
                          <Typography
                            variant="body2"
                            sx={{
                              marginLeft: '10px',
                              fontSize: '12px',
                              color: '#55abed',
                              textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)', // Shadow effect
                            }}
                          >
                            See More &gt;
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  </>
                )}
                {/* <Box sx={{ alignContent: 'center', textAlign: 'center', mt: '5rem' }}>
                  <Box marginTop={3}>
                    <a href="#"
                      fullWidth
                      size="large"
                      variant="contained"
                      style={{ width: "300px", color: "black" }}
                      sx={{ borderRadius: 28 }}
                      onClick={(e) => {
                        handleOpenWorkmateTimein()
                      }}
                    >
                      Workmate - Time in
                    </a>
                  </Box>
                  <Box marginTop={3}>
                    <a href="#"
                      fullWidth
                      size="large"
                      variant="contained"
                      style={{ width: "300px", color: "black" }}
                      onClick={(e) => {
                        handleGenerateQR()
                      }}
                    >
                      Generate QR Code
                    </a>
                  </Box>
                  <Box marginTop={3}>
                    <a href="#"
                      fullWidth
                      size="large"
                      variant="contained"
                      style={{ width: "300px", color: "black" }}
                      onClick={(e) => {
                        handleOpenStore()
                      }}
                    >
                      Other Store Time in
                    </a>
                  </Box>
                </Box> */}
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mt: '5rem',
                    mb: '3rem',
                    flexDirection: 'row',
                    gap: 1, // Adds space between the items
                  }}
                >
                  <Box
                    sx={{width: '300px', textAlign: 'center'}}
                    onClick={(e) => {
                      handleOpenWorkmateTimein()
                    }}
                  >
                    <Stack direction="column" alignItems="center" spacing={1}>
                      <Box
                        sx={{
                          backgroundColor: '#2090eb',
                          borderRadius: '10%',
                          padding: '20px',
                          display: 'inline-flex',
                        }}
                      >
                        <GroupAddIcon fontSize="large" sx={{color: 'white'}} />
                      </Box>
                      <span>Workmate - Time in </span>
                    </Stack>
                  </Box>
                  <Box
                    sx={{width: '300px', textAlign: 'center'}}
                    onClick={(e) => {
                      handleGenerateQR()
                    }}
                  >
                    <Stack direction="column" alignItems="center" spacing={1}>
                      <Box
                        sx={{
                          backgroundColor: '#2090eb',
                          borderRadius: '10%',
                          padding: '20px',
                          display: 'inline-flex',
                        }}
                      >
                        <QrCodeIcon fontSize="large" sx={{color: 'white'}} />
                      </Box>
                      <span>Generate QR Code </span>
                    </Stack>
                  </Box>
                  {/* <Box
                    sx={{ width: '300px', textAlign:'center' }}
                    onClick={(e) => {
                      handleOpenStore();
                    }}
                  >
                    <Stack direction="column" alignItems="center" spacing={1}>
                      <Box
                        sx={{
                          backgroundColor: '#2090eb',
                          borderRadius: '10%', 
                          padding: '20px', 
                          display: 'inline-flex',
                        }}
                      >
                        <StorefrontIcon fontSize="large" sx={{ color: 'white' }}/>
                      </Box>
                      <span>Other Store Time in</span>
                    </Stack>
                  </Box> */}
                </Box>

                <HelpButton />
                {Object.keys(coc).length === 0 ? (
                  ''
                ) : (
                  <div style={{width: '100%', textAlign: 'center', marginTop: '10px'}}>
                    <a size="small" color="inherit" variant="text" target="_blank" href={coc}>
                      Company Code of Conduct
                    </a>
                  </div>
                )}
              </Grid>
            </Box>
            <Dialog open={openWorkmateTimein} onClose={handleCloseWorkmateTimein}>
              <DialogTitle>
                Workmate Scan?
                <IconButton
                  aria-label="close"
                  onClick={handleCloseWorkmateTimein}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <>
{/*                  {browserName.includes('Safari') ? (
                    <>
                      <NewFeatureDialog />
                    </>
                  ) : (
                    ''
                  )}*/}
                  {!validated ? (
                    <FormControlLabel
                      style={{width: '250px', heigth: '200px'}}
                      control={<Checkbox />}
                      onChange={(e) => handleCheckboxChange(e)}
                      label="By checking this you guarantee the presence of your workmate in the vicinity using this time-in."
                    />
                  ) : (
                    <>
                      {!value ? (
                        <>
                          <QrReader
                            delay={3000}
                            onError={handleError}
                            onScan={handleScan}
                            // chooseDeviceId={()=>selected}
                            style={{width: '250px', heigth: '200px'}}
                            // className={'qrScanner'}
                          />
                          <DialogTitle style={{display: 'flex', justifyContent: 'center'}}>{scanMessage}</DialogTitle>
                        </>
                      ) : (
                        <>
                          {!validatedChecklist ? (
                            // <>
                            //   <FormControlLabel
                            //     style={{width: '100%', heigth: '200px'}}
                            //     control={<Checkbox />}
                            //     onChange={(e) => setCheckboxChange(e.target.checked)}
                            //     label={checklists[0]}
                            //   />
                            //   <br />
                            //   <FormControlLabel
                            //     style={{width: '100%', heigth: '200px'}}
                            //     control={<Checkbox />}
                            //     onChange={(e) => setUniformCheckboxChange(e.target.checked)}
                            //     label={checklists[1]}
                            //   />
                            //   <br />
                            //   <FormControlLabel
                            //     style={{width: '100%', heigth: '200px'}}
                            //     control={<Checkbox />}
                            //     onChange={(e) => setNameplateCheckboxChange(e.target.checked)}
                            //     label={checklists[2]}
                            //   />
                            //   <br />
                            //   <FormControlLabel
                            //     style={{width: '100%', heigth: '200px'}}
                            //     control={<Checkbox />}
                            //     onChange={(e) => setGroomingCheckboxChange(e.target.checked)}
                            //     label={checklists[3]}
                            //   />
                            //   <br />
                            //   <FormControlLabel
                            //     style={{width: '100%', heigth: '200px'}}
                            //     control={<Checkbox />}
                            //     onChange={(e) => setVacCheckboxChange(e.target.checked)}
                            //     label={checklists[4]}
                            //   />
                            //   <br />
                            //   <br />
                            //   <label style={{fontWeight: 'bold'}}>
                            //     Note: Telling false testimonies corresponds to a DISMISSAL
                            //   </label>
                            // </>
                            <>
                              <DialogContent>
                                <Typography variant="body1">
                                  <p>I hereby testify that I was physically present
                                  within my assigned workplace, completed my tasks, maintained an organized workspace, and have a starry smile 😊✨. </p><br/>

                                  <p>By clicking <strong>"Acknowledge"</strong>, I affirm this statement's accuracy and understand that false testimony may lead to <strong>disciplinary action, including dismissal.</strong></p>
                                </Typography>
                                <DialogActions sx={{ justifyContent: 'center', mt: 4 }}>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => setAcknowledged(true)}
                                    sx={{py: 1, px: 4}}
                                  >
                                    Acknowledge
                                  </Button>
                                </DialogActions>
                                
                              </DialogContent>
                            </>
                          ) : (
                            <>
                              <Box style={{width: '100%', 'margin-bottom': '25px'}} hidden={dialogStatus}>
                                <label>
                                  Hi {qrUser.displayName} please take a selfie with Team Supervisor or Manager
                                  representative with you.
                                </label>
                                <br />
                                <br />
                                <label style={{fontWeight: 'bold', color: 'red'}}>
                                  Note: If the image displays a blank screen, please click the re-capture button.
                                </label>
                              </Box>
                              <Box style={{width: '100%', 'margin-bottom': '25px'}} playsInline hidden={successStatus}>
                                <label style={{fontWeight: 'bold'}}>
                                  {curStatus} success this page will close in 5 seconds or you can choose to close it
                                  manually.
                                </label>
                              </Box>
                              <Box style={{width: '100%'}} hidden={captureStatus}>
                                <Button
                                  style={{width: '100%'}}
                                  onClick={takePicture}
                                  variant="contained"
                                  sx={{borderRadius: 28}}
                                >
                                  Capture
                                </Button>
                              </Box>
                              <br />
                              <video
                                ref={videoRef}
                                style={{width: '100%', height: '100%'}}
                                hidden={captureStatus}
                                src="something"
                                type="video/mp4"
                                playsInline
                              ></video>
                              <canvas
                                id="canvas"
                                style={{width: '100%', height: '100%'}}
                                ref={photoRef}
                                hidden={timeinStatus}
                              ></canvas>

                              {isLoading ? (
                                <Box style={{width: '100%', 'margin-bottom': '15px'}} hidden={timeinStatus}>
                                  <Loading />
                                </Box>
                              ) : (
                                <div style={{height: '100%'}}>
                                  <Box style={{width: '100%', 'margin-top': '15px'}} hidden={timeinStatus}>
                                    <Button
                                      style={{width: '100%'}}
                                      onClick={handleTimein}
                                      variant="contained"
                                      sx={{borderRadius: 28}}
                                    >
                                      {curStatus}
                                    </Button>
                                  </Box>
                                  <br />
                                  <Box style={{width: '100%'}} hidden={timeinStatus}>
                                    <Button
                                      style={{width: '100%'}}
                                      onClick={clearImage}
                                      variant="contained"
                                      sx={{borderRadius: 28}}
                                    >
                                      Re-capture
                                    </Button>
                                  </Box>
                                </div>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              </DialogContent>
            </Dialog>
            <Modal
              aria-labelledby="spring-modal-title"
              aria-describedby="spring-modal-description"
              open={openQR}
              onClose={handleCloseQR}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={openQR}>
                <Box sx={style}>
                  <Grid container sx={{pl: 2, mb: 2}} style={{display: 'flex', 'justify-content': 'center'}}>
                    <Typography variant="h6" component="h2" style={{color: 'black', fontWeight: '1000'}}>
                      {name}
                    </Typography>
                    <IconButton
                      aria-label="close"
                      onClick={handleCloseQR}
                      sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                  <Grid container sx={{pl: 2}}>
                    <QRCode id="qr-gen" size={300} value={basicUser._id} />
                  </Grid>
                  <Button
                    variant="contained"
                    onClick={downloadQRCode}
                    sx={{
                      mt: 2,
                      px: 17,
                      backgroundColor: '#1F98E8',
                      boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.35)',
                      color: 'white',
                    }}
                  >
                    Print QR
                  </Button>
                </Box>
              </Fade>
            </Modal>
            <Dialog
              open={openCurrentShift}
              onClose={handleCurrentShiftClose}
              classes={{paperScrollPaper: classes.customWidth}}
            >
              <DialogTitle>
                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                  <Typography variant="h6" sx={{textAlign: 'start'}}>
                    {' '}
                    Employee:{' '}
                  </Typography>
                  <Typography variant="h6" sx={{textAlign: 'end'}}>
                    {basicUser ? basicUser.displayName : 'Loading'}
                  </Typography>
                </Box>
              </DialogTitle>
              <DialogContent>
                <hr />
                <Box key={basicUser._id} sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                  <Box>
                    <Typography>Time in</Typography>
                    <Box sx={{display: 'flex', gap: 3}}>
                      <Typography variant="subtitle2">
                        {' '}
                        {currentRecord ? filterByStatus(currentRecord.record, 'time-in').time : '00:00'}{' '}
                      </Typography>
                      <Typography variant="subtitle2">
                        {' '}
                        {currentRecordDate ? currentRecordDate : 'No Record'}
                      </Typography>
                    </Box>
                    <Typography variant="caption"> {currentRecord ? currentRecord._id : 'Loading'} </Typography>
                  </Box>
                </Box>
                <hr />
                <Box key={basicUser._id} sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                  <Box>
                    <Typography>Break in</Typography>
                    <Box sx={{display: 'flex', gap: 3}}>
                      <Typography variant="subtitle2">
                        {' '}
                        {currentRecord ? filterByStatus(currentRecord.record, 'break-in').time : '00:00'}{' '}
                      </Typography>
                      <Typography variant="subtitle2">
                        {' '}
                        {currentRecordDate ? currentRecordDate : 'No Record'}
                      </Typography>
                    </Box>
                    <Typography variant="caption"> {currentRecord ? currentRecord._id : 'Loading'} </Typography>
                  </Box>
                </Box>
                <hr />
                <Box key={basicUser._id} sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                  <Box>
                    <Typography>Break out</Typography>
                    <Box sx={{display: 'flex', gap: 3}}>
                      <Typography variant="subtitle2">
                        {' '}
                        {currentRecord ? filterByStatus(currentRecord.record, 'break-out').time : '00:00'}{' '}
                      </Typography>
                      <Typography variant="subtitle2">
                        {' '}
                        {currentRecordDate ? currentRecordDate : 'No Record'}
                      </Typography>
                    </Box>
                    <Typography variant="caption"> {currentRecord ? currentRecord._id : 'Loading'} </Typography>
                  </Box>
                </Box>
                <hr />
                <Box key={basicUser._id} sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                  <Box>
                    <Typography>Time out</Typography>
                    <Box sx={{display: 'flex', gap: 3}}>
                      <Typography variant="subtitle2">
                        {' '}
                        {currentRecord ? filterByStatus(currentRecord.record, 'time-out').time : '00:00'}{' '}
                      </Typography>
                      <Typography variant="subtitle2">
                        {' '}
                        {currentRecordDate ? currentRecordDate : 'No Record'}
                      </Typography>
                    </Box>
                    <Typography variant="caption"> {currentRecord ? currentRecord._id : 'Loading'} </Typography>
                  </Box>
                </Box>
                <hr />
                <Box
                  key={basicUser._id}
                  sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}
                  style={{'margin-top': '.5rem'}}
                >
                  <Box sx={{display: 'flex', justifyContent: 'start'}}>
                    <Typography color="green"> Recorded </Typography>
                  </Box>
                  <Box sx={{display: 'flex', justifyContent: 'end'}}>
                    <Button
                      variant="warning"
                      size="small"
                      style={{backgroundColor: 'red'}}
                      onClick={handleCurrentShiftClose}
                    >
                      {' '}
                      <Typography variant="caption"> Close </Typography>{' '}
                    </Button>
                  </Box>
                </Box>
              </DialogContent>
            </Dialog>
            <Dialog open={openTimein} onClose={handleCloseTimein}>
              <DialogTitle>
                {curStatus}
                <IconButton
                  aria-label="close"
                  onClick={handleCloseTimein}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
{/*                {browserName.includes('Safari') ? (
                  <>
                    <NewFeatureDialog />
                  </>
                ) : (
                  ''
                )}*/}

                <>
                  {!validatedChecklist ? (
                    // <>
                    //   <FormControlLabel
                    //     style={{width: '100%', heigth: '200px'}}
                    //     control={<Checkbox />}
                    //     onChange={(e) => checkAll(e.target.checked)}
                    //     label="Check All"
                    //   />
                    //   <br />
                    //   <FormControlLabel
                    //     style={{width: '100%', heigth: '200px'}}
                    //     control={
                    //       <Checkbox 
                    //         checked={checkBoxChange}
                    //       />
                    //     }
                    //     onChange={(e) => setCheckboxChange(e.target.checked)}
                    //     label={checklists[0]}
                    //   />
                    //   <br />
                    //   <FormControlLabel
                    //     style={{width: '100%', heigth: '200px'}}
                    //     control={
                    //       <Checkbox 
                    //         checked={uniformCheckboxChange}
                    //       />
                    //     }
                    //     onChange={(e) => setUniformCheckboxChange(e.target.checked)}
                    //     label={checklists[1]}
                    //   />
                    //   <br />
                    //   <FormControlLabel
                    //     style={{width: '100%', heigth: '200px'}}
                    //     control={<Checkbox 
                    //         checked={nameplateCheckBoxChange}
                    //       />}
                    //     onChange={(e) => setNameplateCheckboxChange(e.target.checked)}
                    //     label={checklists[2]}
                    //   />
                    //   <br />
                    //   <FormControlLabel
                    //     style={{width: '100%', heigth: '200px'}}
                    //     control={<Checkbox 
                    //         checked={groomingCheckboxChange}
                    //       />}
                    //     onChange={(e) => setGroomingCheckboxChange(e.target.checked)}
                    //     label={checklists[3]}
                    //   />
                    //   <br />
                    //   <FormControlLabel
                    //     style={{width: '100%', heigth: '200px'}}
                    //     control={<Checkbox 
                    //         checked={vacCheckBoxChange}
                    //       />}
                    //     onChange={(e) => setVacCheckboxChange(e.target.checked)}
                    //     label={checklists[4]}
                    //   />
                    //   <br />
                    //   <br />
                    //   <label style={{fontWeight: 'bold'}}>
                    //     Note: Telling false testimonies corresponds to a DISMISSAL
                    //   </label>
                    // </>
                      <>
                        <DialogContent>
                          <Typography variant="body1">
                            <p>I hereby testify that I was physically present
                            within my assigned workplace, completed my tasks, maintained an organized workspace, and have a starry smile 😊✨. </p><br/>

                            <p>By clicking <strong>"Acknowledge"</strong>, I affirm this statement's accuracy and understand that false testimony may lead to <strong>disciplinary action, including dismissal.</strong></p>
                          </Typography>
                          <DialogActions sx={{ justifyContent: 'center', mt: 4 }}>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => setAcknowledged(true)}
                              sx={{py: 1, px: 4}}
                            >
                              Acknowledge
                            </Button>
                          </DialogActions>
                          
                        </DialogContent>
                      </>
                  ) : (
                    <div id="pageContainer">
                      <Box style={{width: '100%', 'margin-bottom': '25px'}} hidden={dialogStatus}>
                        <label>
                          Hi {user.displayName} please take a selfie with Team Supervisor or Manager representative with
                          you.
                        </label>
                        <br />
                        <br />
                        <label style={{fontWeight: 'bold', color: 'red'}}>
                          Note: If the image displays a blank screen, please click the re-capture button.
                        </label>
                      </Box>
                      <Box
                        style={{width: '100%', 'margin-bottom': '25px'}}
                        hidden={successStatus}
                        playsInline
                        type="video/mp4"
                      >
                        <label style={{fontWeight: 'bold'}}>
                          {curStatus} success this page will close in 5 seconds or you can choose to close it manually.
                        </label>
                      </Box>
                      <Box style={{width: '100%'}} hidden={captureStatus}>
                        <Button
                          style={{
                            width: '100%',
                            backgroundColor: '#1F98E8',
                            boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.35)',
                            color: 'white',
                          }}
                          onClick={takePicture}
                          variant="contained"
                          sx={{borderRadius: 28}}
                        >
                          Capture
                        </Button>
                      </Box>
                      <br />
                      <video
                        ref={videoRef}
                        style={{width: '100%', height: '100%'}}
                        hidden={captureStatus}
                        src="something"
                        type="video/mp4"
                        playsInline
                      ></video>
                      <canvas
                        id="canvas"
                        style={{width: '100%', height: '100%'}}
                        ref={photoRef}
                        hidden={timeinStatus}
                      ></canvas>
                      {isLoading ? (
                        <Loading />
                      ) : (
                        <>

                          <Box style={{width: '100%', 'margin-top': '15px'}} hidden={timeinStatus}>
                            <Button
                              style={{width: '100%'}}
                              onClick={handleTimein}
                              variant="contained"
                              sx={{borderRadius: 28}}
                            >
                              {curStatus}
                            </Button>
                          </Box>
                          <Box style={{width: '100%', 'margin-top': '15px'}} hidden={timeinStatus}>
                            <Button
                              style={{width: '100%'}}
                              onClick={clearImage}
                              variant="contained"
                              sx={{borderRadius: 28}}
                            >
                              Re-capture
                            </Button>
                          </Box>
                        </>
                      )}
                    </div>
                  )}
                </>
              </DialogContent>
            </Dialog>
            <Dialog open={openOtherStore} onClose={() => handleOpenStore()}>
              <DialogTitle>
                Scan Store QR Code
                <IconButton
                  aria-label="close"
                  onClick={handleCloseOtherStore}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
{/*                {browserName.includes('Safari') ? (
                  <>
                    <NewFeatureDialog />
                  </>
                ) : (
                  ''
                )}*/}
                {!checkOtherStore ? (
                  <Box>
                    <FormControlLabel
                      control={<Checkbox checked={checkOtherStore} onChange={handleCheckboxChangeStore} />}
                      label="By checking this you guarantee that you are rendering a duty to this store."
                    />
                  </Box>
                ) : (
                  <>
                    {!value ? (
                      <>
                        <QrReader
                          delay={3000}
                          onError={handleError}
                          onScan={handleScan}
                          // chooseDeviceId={()=>selected}
                          style={{width: '250px', heigth: '200px'}}
                          // className={'qrScanner'}
                        />
                        <DialogTitle style={{display: 'flex', justifyContent: 'center'}}>{scanMessage}</DialogTitle>
                      </>
                    ) : (
                      <>
                        {true && (
                          <>
                            <label style={{fontWeight: 'bold'}}>
                              I testify that I am in front of the TS and management representative upon signing in.{' '}
                              <br />
                              Also, I signify that I have:
                            </label>
                            <br />
                            <FormControlLabel
                              style={{width: '250px', heigth: '200px', 'margin-top': '20px'}}
                              control={<Checkbox />}
                              onChange={(e) => setUniformCheckboxChange(e.target.checked)}
                              label={dummyStoreCheckList[0]}
                            />
                            <br />
                            <FormControlLabel
                              style={{width: '250px', heigth: '200px'}}
                              control={<Checkbox />}
                              onChange={(e) => setNameplateCheckboxChange(e.target.checked)}
                              label={dummyStoreCheckList[1]}
                            />
                            <br />
                            <FormControlLabel
                              style={{width: '250px', heigth: '200px'}}
                              control={<Checkbox />}
                              onChange={(e) => setGroomingCheckboxChange(e.target.checked)}
                              label={dummyStoreCheckList[2]}
                            />
                            <br />
                            <FormControlLabel
                              style={{width: '250px', heigth: '200px'}}
                              control={<Checkbox />}
                              onChange={(e) => setVacCheckboxChange(e.target.checked)}
                              label={dummyStoreCheckList[3]}
                            />
                            <br />
                            <br />
                            <label style={{fontWeight: 'bold'}}>
                              Note: Telling false testimonies corresponds to a DISMISSAL
                            </label>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </DialogContent>
            </Dialog>
            <Dialog open={openEditDialog} onClose={handleCloseEditDialog}>
              <IconButton
                aria-label="close"
                onClick={handleCloseEditDialog}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
              <DialogTitle>Edit Name?</DialogTitle>
              <DialogContent>
                <TextField
                  margin="dense"
                  id="firstName"
                  label="First Name"
                  type="text"
                  fullWidth
                  variant="standard"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <TextField
                  margin="dense"
                  id="lastName"
                  label="Last Name"
                  type="text"
                  fullWidth
                  variant="standard"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseEditDialog}>Cancel</Button>
                <Button onClick={handleSubmitUpdates}>Submit</Button>
              </DialogActions>
            </Dialog>
            <Dialog open={openEditCompany} onClose={handleCloseEditCompany}>
              <DialogTitle>
                Scan store QR code.
                <IconButton
                  aria-label="close"
                  onClick={handleCloseEditCompany}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <>
                  <QrReader
                    delay={3000}
                    onError={handleErrorStoreQR}
                    onScan={handleScanStoreQR}
                    // chooseDeviceId={()=>selected}
                    style={{width: '250px', heigth: '200px'}}
                    // className={'qrScanner'}
                  />
                </>
              </DialogContent>
            </Dialog>

            <Dialog open={openEditDialog} onClose={handleCloseEditDialog}>
              <IconButton
                aria-label="close"
                onClick={handleCloseEditDialog}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
              <DialogTitle>Edit Name?</DialogTitle>
              <DialogContent>
                <TextField
                  margin="dense"
                  id="firstName"
                  label="First Name"
                  type="text"
                  fullWidth
                  variant="standard"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <TextField
                  margin="dense"
                  id="lastName"
                  label="Last Name"
                  type="text"
                  fullWidth
                  variant="standard"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseEditDialog}>Cancel</Button>
                <Button onClick={handleSubmitUpdates}>Submit</Button>
              </DialogActions>
            </Dialog>

            <Dialog open={openCreateScheduleDialog} onClose={() => setOpenCreateScheduleDialog(false)} fullWidth maxWidth="xs">
              <IconButton
                aria-label="close"
                onClick={handleCloseCreateScheduleDialog}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
              <DialogTitle sx={{ fontWeight: "bold", textAlign: "center" }}>My Schedule</DialogTitle>
              <DialogContent sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <TextField
                  label="Time-in"
                  type="time"
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  value={from}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{ sx: { fontSize: "0.9rem" }, step: 300 }}
                  onChange={(e) => setFrom(e.target.value)}
                />
                <TextField
                  label="Time-out"
                  fullWidth
                  type="time"
                  variant="outlined"
                  margin="dense"
                  value={to}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{ sx: { fontSize: "0.9rem" }, step: 300 }}
                  onChange={(e) => setTo(e.target.value)}
                />
                <TextField
                  label="No. of breaks (mins)"
                  fullWidth
                  type="number"
                  variant="outlined"
                  margin="dense"
                  value={breakMin}
                  onChange={(e) => setBreakMin(e.target.value)}
                />
                <TextField
                  label="Position"
                  fullWidth
                  type="text"
                  variant="outlined"
                  value={position}
                  margin="dense"
                  onChange={(e) => setPosition(e.target.value)}
                />
              </DialogContent>
              <DialogActions sx={{ justifyContent: "center" }}>
                <Button
                  variant="contained"
                  onClick={handleSaveSchedule}
                  sx={{
                    backgroundColor: "#2194F3",
                    borderRadius: "8px",
                    padding: "8px 16px",
                    fontWeight: "bold",
                    color: "white",
                    "&:hover": { backgroundColor: "#1976D2" },
                  }}
                >
                  Save
                </Button>
              </DialogActions>
            </Dialog>


          </Container>
        </>
      ) : (
        <FacebookDialog permitted={open} />
      )}
      {/* {selected && (
        <Dialog
          open={selected}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          sx={{overflow: 'hidden'}}
        >
          <DialogContent sx={{p: 0, height: '50vh', overflow: 'hidden'}}>
            <Box component="img" src={selectedBanner} sx={{height: '100%', width: '100px', objectFit: 'cover'}} />
          </DialogContent>
          <DialogActions
            sx={{
              mb: -10,
              textAlign: 'center',
              bgColor: 'rgba(255,255,255,0)',
              zIndex: 20,
              position: 'absolute',
              top: 0,
              right: -15
            }}
          >
            <Button onClick={handleClose} color="primary" sx={{mx: 'auto'}}>
              <HighlightOffIcon sx={{color: 'common.white', fontSize: 32}} />
            </Button>
          </DialogActions>
        </Dialog>
      )}*/}
      <Dialog open={updateDialogOpen} onClose={() => setUpdateDialogOpen(false)}>
        <DialogTitle>
          <Typography variant="h6" fontWeight="bold">
            The app has been updated
          </Typography>
        </DialogTitle>
        <DialogContent>Your app has been updated to the latest version. Enjoy improved performance and new features!</DialogContent>
        <DialogActions>
          <Button onClick={handleCloseUpdateDialog} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={confirmScheduleDialog} onClose={() => setConfirmScheduleDialog(false)} sx={{ textAlign: "center" }}>
        <DialogTitle>
          <Typography variant="h6" fontWeight="bold">
            Please confirm your schedule
          </Typography>
        </DialogTitle>
        <DialogContent>You need to confirm your assigned schedule before you can Time in. Check your <strong>My Schedule</strong>, review your shift, and click <strong>Confirm Schedule</strong> to proceed!</DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button 
            onClick={() => setConfirmScheduleDialog(false)} 
            variant="contained" 
            sx={{ 
              backgroundColor: "#2194F3", 
              borderRadius: "8px", 
              padding: "8px 16px", 
              fontWeight: "bold",
              marginBottom: "20px",
              color: "white", 
              "&:hover": { backgroundColor: "#1976D2" } // Slightly darker on hover
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={noScheduleDialog} onClose={() => setNoScheduleDialog(false)} sx={{ textAlign: "center" }}>
        <DialogTitle>
          <Typography variant="h6" fontWeight="bold">
            No Schedule Found
          </Typography>
        </DialogTitle>
        <DialogContent>You don't have a schedule yet. Please <strong>input your schedule</strong> first to proceed.</DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button 
            onClick={() => {
              setNoScheduleDialog(false);
              window.location.reload();
            }}
            variant="contained" 
            sx={{ 
              backgroundColor: "#2194F3", 
              borderRadius: "8px", 
              padding: "8px 16px", 
              fontWeight: "bold",
              marginBottom: "20px",
              color: "white", 
              "&:hover": { backgroundColor: "#1976D2" } // Slightly darker on hover
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={saveScheduleSuccess} onClose={() => setSaveScheduleSuccess(false)} sx={{ textAlign: "center" }}>
        <IconButton
          aria-label="close"
          onClick={() => setSaveScheduleSuccess(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle>
          <Typography variant="h6" fontWeight="bold">
            Schedule created!
          </Typography>
        </DialogTitle>
        <DialogContent>Please scan store QR Code to finalize your schedule for today.</DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <QrReader
            delay={3000}
            onError={handleError}
            onScan={handleConfirmScheduleScan}
            // chooseDeviceId={()=>selected}
            style={{width: '250px', heigth: '200px'}}
            // className={'qrScanner'}
          />
        </DialogActions>
      </Dialog>

      <Dialog 
        open={openConfirmScheduleDialog} 
        onClose={() => setOpenConfirmScheduleDialog(false)} 
        sx={{ textAlign: "center" }}
      >
      <IconButton
        aria-label="close"
        onClick={() => setOpenConfirmScheduleDialog(false)}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
        <DialogContent 
          sx={{
            backgroundColor: "white",
            borderRadius: "12px",
            padding: "20px",
            boxShadow: 3,
            textAlign: "center",
          }}
        >
          <Typography variant="h6" fontWeight="bold" sx={{ color: "limegreen" }}>
            Schedule Confirmed
          </Typography>
          <Typography variant="body1" sx={{ marginTop: "10px" }}>
            Please time out on time to ensure proper counting of your manhours
          </Typography>
        </DialogContent>
      </Dialog>


    </Page>
  )
}

export default DashboardApp

