import {Icon} from '@iconify/react'
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill'
import personAddOutline from '@iconify/icons-eva/person-add-outline'
import personDeleteOutline from '@iconify/icons-eva/person-delete-outline'
import imageFill from '@iconify/icons-eva/image-outline'
import fileFill from '@iconify/icons-eva/file-text-outline'
import compassFill from '@iconify/icons-eva/compass-outline'
import edit2Outline from '@iconify/icons-eva/edit-2-outline'
import autoSchedule from '@iconify/icons-material-symbols/auto-schedule'
import checkbookRounded from '@iconify/icons-material-symbols/checkbook-rounded'
// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />
let _config = [
  {
    title: 'dashboard',
    path: '/stores/app',
    icon: getIcon(pieChart2Fill),
  },
  {
    title: 'QR code',
    path: '/stores/qr',
    icon: getIcon('mdi:qrcode'),
  },
  {
    title: 'user',
    path: '/stores/user',
    icon: getIcon(personAddOutline),
  },
  {
    title: 'archived user',
    path: '/stores/archive',
    icon: getIcon('mdi:user-off-outline'),
  },
  {
    title: 'time adjustment',
    path: '/stores/adjustment',
    icon: getIcon('mdi:timer-edit-outline'),
  },
  {
    title: 'reports',
    path: '/stores/reports',
    icon: getIcon(fileFill),
  },
  {
    title: 'schedules',
    path: '/stores/schedule',
    icon: getIcon(autoSchedule),
  },
  {
    title: 'announcements',
    path: '/stores/announcements',
    icon: getIcon('mingcute:announcement-line'),
  },
  ,
  {
    title: 'video tutorials',
    path: '/stores/video-tutorials',
    icon: getIcon('radix-icons:video'),
  },
  {
    title: 'admin',
    path: '/stores/admin',
    icon: getIcon('clarity:administrator-line'),
  },
  {
    title: 'store approval',
    path: '/stores/approval',
    icon: getIcon('flowbite:store-outline'),
  },
  {
    title: 'subscription',
    path: '/stores/subscription',
    icon: getIcon('eos-icons:activate-subscriptions-outlined'),
  },
  {
    title: 'breaklist summary',
    path: '/stores/breaklist-info',
    icon: getIcon('clarity:administrator-line'),
  },
  {
    title: 'manage breaklist',
    path: '/stores/manage-breaklist',
    icon: getIcon(checkbookRounded),
  },
  {
    title: 'generated breaklist',
    path: '/stores/breaklist',
    icon: getIcon('icon-park-outline:view-grid-list'),
  },
  {
    title: 'feedbacks',
    path: '/stores/feedbacks',
    icon: getIcon('icon-park-outline:view-grid-list'),
  },
]

let _configWOBranch = [
  {
    title: 'dashboard',
    path: '/stores/app',
    icon: getIcon(pieChart2Fill),
  },
  {
    title: 'QR code',
    path: '/stores/qr',
    icon: getIcon('mdi:qrcode'),
  },
  {
    title: 'user',
    path: '/stores/user',
    icon: getIcon(personAddOutline),
  },
  {
    title: 'archived user',
    path: '/stores/archive',
    icon: getIcon('mdi:user-off-outline'),
  },
  {
    title: 'time adjustment',
    path: '/stores/adjustment',
    icon: getIcon('mdi:timer-edit-outline'),
  },
  {
    title: 'reports',
    path: '/stores/reports',
    icon: getIcon(fileFill),
  },
  {
    title: 'schedules',
    path: '/stores/schedule',
    icon: getIcon(autoSchedule),
  },
  {
    title: 'announcements',
    path: '/stores/announcements',
    icon: getIcon('mingcute:announcement-line'),
  },
  {
    title: 'video tutorials',
    path: '/stores/video-tutorials',
    icon: getIcon('radix-icons:video'),
  },
  {
    title: 'admin',
    path: '/stores/admin',
    icon: getIcon('clarity:administrator-line'),
  },
  {
    title: 'breaklist summary',
    path: '/stores/breaklist-info',
    icon: getIcon('icon-park-outline:view-grid-list'),
  },
  {
    title: 'manage breaklist',
    path: '/stores/manage-breaklist',
    icon: getIcon(checkbookRounded),
  },
  {
    title: 'generated breaklist',
    path: '/stores/breaklist',
    icon: getIcon('material-symbols:list-alt-check-outline-sharp'),
  },
]

let _configCustom = [
  {
    title: 'dashboard',
    path: '/stores/app',
    icon: getIcon(pieChart2Fill),
  },
  {
    title: 'QR code',
    path: '/stores/qr',
    icon: getIcon('mdi:qrcode'),
  },
  {
    title: 'user',
    path: '/stores/user',
    icon: getIcon(personAddOutline),
  },
  {
    title: 'archived user',
    path: '/stores/archive',
    icon: getIcon('mdi:user-off-outline'),
  },
  {
    title: 'time adjustment',
    path: '/stores/adjustment',
    icon: getIcon('mdi:timer-edit-outline'),
  },
  {
    title: 'reports',
    path: '/stores/reports',
    icon: getIcon(fileFill),
  },
  {
    title: 'schedules',
    path: '/stores/schedule',
    icon: getIcon(autoSchedule),
  },
]
let exp_object = {_config, _configWOBranch, _configCustom}
export default exp_object
