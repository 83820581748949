import React, {useEffect} from 'react'
import useFeedbacksHook from './useFeedbacksHook'
import Button from '@material-ui/core/Button'
import List from './List'
import RatingsSelector from './RatingsSelector'
import user_api from 'utils/api/users'

const Feedbacks = () => {
  let {getFeedbacks, feedbacks, setRatingToDisplay} = useFeedbacksHook()

  return (
    <>
      <RatingsSelector
        onSelect={(rating) => {
          setRatingToDisplay(rating)
        }}
      />
      <Button type="button" onClick={() => user_api.download_feedbacks()}>
        Download Responses
      </Button>
      <List feedbacks={feedbacks.data} onLoadMore={feedbacks.status !== 'end' ? getFeedbacks : null} />
    </>
  )
}

export default Feedbacks
