import React, {memo} from 'react'
import moment from 'moment'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'

import Rating from '@material-ui/core/Rating'

const Feedback = memo(
  ({
    rating = 5,
    name = 'Juan dela cruz',
    feedback = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla eu odio ut arcu mattis maximus. Fusce vestibulum tincidunt pharetra. Aliquam eget iaculis erat, eu pellentesque eros. ',
    date = 'January 1, 2025 10:35:00 PM',
  }) => {
    return (
      <>
        <Box sx={{maxWidth: 500, margin: '1em'}}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="h6" component="div">
                {name}
              </Typography>
              <Rating value={rating} />
              <Typography variant="body2">{feedback}</Typography>
            </CardContent>
            <CardActions>
              <Typography variant="caption">{moment(date).format('MMMM Do YYYY, h:mm:ss a')}</Typography>
            </CardActions>
          </Card>
        </Box>
      </>
    )
  },
)

export default Feedback
