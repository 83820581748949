import { filter } from 'lodash'
import React, { useEffect, useState } from 'react'
// import { Icon } from '@iconify/react';
// import plusFill from '@iconify/icons-eva/plus-fill';
// import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  // Button,
  Checkbox,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Button,
  IconButton,
  Modal,
  Fade,
  Box,
  Grid,
  Paper,
  InputLabel,
  FormControl,
  Select,
  MenuItem
} from '@material-ui/core'
import CloseIcon from '@mui/icons-material/Close';
import AppBar from "@mui/material/AppBar";
// components
import Page from '../components/Page'
import Label from '../components/Label'
import Scrollbar from '../components/Scrollbar'
import SearchNotFound from '../components/SearchNotFound'
import { UserListHead, UserListToolbar, UserMoreMenuArchive } from '../components/_dashboard/user'
import OtpInput from 'react-otp-input';
import QrReader from 'react-qr-reader'
import AddIcon from '@mui/icons-material/Add';

import userAPI from 'utils/api/users'
import storage from 'utils/storage'
import Bugsnag from '@bugsnag/js'

const moment = require('moment-timezone')
moment().tz('Asia/Manila').format()
const current_date = `${moment().tz('Asia/Manila').toISOString(true)}`
const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email address', alignRight: false },
  { id: 'company', label: 'Company', alignRight: false },
  { id: 'phone', label: 'Phone', alignRight: false },
  { id: 'role', label: 'Role', alignRight: false },
  { id: 'isVerified', label: 'Verified', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' },
]

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })

  if (query) {
    return filter(array, (_user) => (_user.displayName ? _user.displayName.toLowerCase().indexOf(query.toLowerCase()) !== -1 : ''))
  }
  return stabilizedThis.map((el) => el[0])
}

export default function TimeAdjustment() {
  const [page, setPage] = useState(0)
  const [order, setOrder] = useState('asc')
  const [selected, setSelected] = useState([])
  const [logs, setLogs] = useState([])
  const [orderBy, setOrderBy] = useState('name')
  const [filterName, setFilterName] = useState('')
  const [recordId, setRecordId] = useState('')
  const [password, setPassword] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [users, setUsers] = useState([])
  const [user, setUser] = useState()
  const [doneAction, setDoneAction] = useState('')
  const [passwordDialog, setPasswordDialog] = useState(true)
  const [isValidated, setIsValidated] = useState(false)
  const [isOtpValid, setIsOtpValid] = useState(false)
  const [open, setOpen] = useState(false)
  const [otp, setOtp] = useState('')
  const [company, setCompany] = useState([])
  const [selectedCompany, setSelectedCompany] = useState()
  const [count, setCount] = useState()
  const [isLoading, setLoading] = useState(false)
  const d = new Date();
  const [date, setDate] = useState(d.setDate(d.getDate() - 1))
  const [openQR, setOpenQR] = useState(false)
  const axios = require('axios');
  
  const handleChangeOtp = async () => {
    const data = { email: user.email, token: otp }
    const verifyResult = await userAPI.post_timeAdjustment_otpVerify(data)
    if (verifyResult.data.success) {
      setIsOtpValid(true)
      window.alert("OTP Verified")
    } else {
      window.alert("Invalid Code")
    }
  }


  useEffect(() => {
    const load = async () => {
      const local_user = await storage.getUser()
      if (!local_user) return
      const user = JSON.parse(local_user)
      setUser(user)
      const result = await userAPI.get_limited_usersV2()
      if (!result.ok) {
        Bugsnag.notify(result)
        return
      }
      setUsers(result.data)

      const otpData = { email: user.email }
      const codeResult = await userAPI.get_timeAdjustment_otp(otpData)
      console.log(codeResult)
    }
    load()
  }, [])

  useEffect(() => {
    const load = async () => {
      const local_user = await storage.getUser()
      if (!local_user) return
      const user = JSON.parse(local_user)
      setUser(user)
      const result = await userAPI.get_limited_usersV2()
      if (!result.ok) {
        Bugsnag.notify(result)
        return
      }
      setUsers(result.data)
    }

    load()
  }, [doneAction])

  const handleClosePasswordDialog = () => {
    setPasswordDialog(false)
  }

  const handleGeneratePassword = async () => {
    const result = await userAPI.create_new_password()
    alert("New Password:" + result.data.password)
  }

  const handleValidatePassword = async () => {
    if (password === null || password === "") {
      alert("Please input password")
    }
    else {
      const data = {
        email: user.email,
        password: password
      }
      const result = await userAPI.verify_password(data)
      if (result.status === 200) {
        alert("Password Verified")
        setIsValidated(true)
      }
      else {
        alert("Invalid Password")
        setIsValidated(false)
      }
    }


  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleOpen = async () => {
    const result = await userAPI.get_account_logs(user._id);
    setLogs(result.data)
    setOpen(true);
  }
  const handleClose = () => setOpen(false);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = users.map((n) => n.name)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const renderTime = (_time) => {
    let _date = new Date(_time)
    var hours = _date.getHours()
    var minutes = _date.getMinutes()
    var ampm = hours >= 12 ? 'pm' : 'am'
    hours = hours % 12
    hours = hours ? hours : 12 // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes
    var strTime = hours + ':' + minutes + ' ' + ampm
    return strTime
  }

  const filterByStatus = (_data, type) => {
    let _d = _data.filter((_d) => (_d.status === type ? _d : ''))
    if (_d.length > 0) {
      _d = _d[0]
    }
    if (_d) {
      if (typeof (_d.time) === "string") {
        return {
          time: _d.time === undefined ? 'n/a' : _d.time,
          location: _d.address === undefined ? 'n/a' : _d.address,
          workmate: _d.workmate === undefined ? '' : 'Workmate( ' + _d.workmate + ' )'
        }
      }
      else {
        return {
          time: _d.time === undefined ? 'n/a' : renderTime(_d.time),
          location: _d.address === undefined ? 'n/a' : _d.address,
          workmate: _d.workmate === undefined ? '' : 'Workmate( ' + _d.workmate + ' )'
        }
      }
    } else {
      return '-'
    }
  }

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name)
    let newSelected = []
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
    }
    setSelected(newSelected)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleFilterByName = (event) => {
    setFilterName(event.target.value)
  }

  const handleSearch = async (e) => {
    setRecordId(e.target.value)
    if (e.target.value.length === 24) {
      const result = await userAPI.get_record_by_id(e.target.value)
      if (result.status === 200) {
        setUsers(result.data)
      }
    }
    else {
      setUsers([])
    }

    if (e.target.value === "" || e.target.value === null) {
      const result = await userAPI.get_limited_usersV2()
      if (!result.ok) {
        Bugsnag.notify(result)
        return
      }
      setUsers(result.data)
    }

  }
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0

  const filteredUsers = applySortFilter(users, getComparator(order, orderBy), filterName)

  const isUserNotFound = filteredUsers.length === 0

  const checkIfDone = (action) => {
    setDoneAction(action)
  }


  const handleStoreChange = async (e) => {
    setLoading(true)
    const fomattedDate = moment(date).format('YYYY-MM-DD')
    setSelectedCompany(e.target.value)
    const result = await userAPI.get_record_bystore(e.target.value, fomattedDate)
    setUsers(result.data)
    setCount(result.data.count)
    setLoading(false)
  }

  const handleOpenQRModal = async () => {
    setOpenQR(true)
  }

  const handleCloseQR = () => {
    setOpenQR(false)
  }

  const handleError = (err) => {
    alert(err)
  }

  const handleScan = async (res) => {
    const url = new URL(res);
    const pathSegments = url.pathname.split("/");
    const id = pathSegments[1];
    const data = {
      "uid": user._id,
      "storeid": id
    }
    if (res) {
      const result = await userAPI.post_group_store(data)
      if (result.status === 200) {
        // load()
        setOpenQR(false)
        alert("Success")
      }
      else {
        alert("Something went wrong please try again later!")
      }
    } else {
      alert("No link found in the response.");
    }
  };

  // const load = async () => {
  //   setLoading(true)
  //   const local_user = await storage.getUser()
  //   if (!local_user) return

  //   const user = await JSON.parse(local_user)
  //   const fomattedDate = moment(date).format('YYYY-MM-DD')
  //   let result
  //   let data
  //   let length
  //   if (user.role === 3) {
  //     const getCompany = await userAPI.get_group_store(user._id);
  //     if (getCompany.status === 200) {
  //       setCompany(getCompany.data.report.store);
  //       setSelectedCompany(getCompany.data.report.store)
  //       data = {
  //         store: getCompany.data.report.store[0],
  //       }
  //       result = await userAPI.get_record_bystore(data, fomattedDate)
  //       length = result.data.length - 1
  //       setUsers(result.data.records)
  //       setCount(result.data.count)
  //     }
  //     else {
  //       setUsers()
  //     }
  //   }
  //   else {
  //     const fomattedDate = moment(date).format('YYYY-MM-DD')
  //     data = {
  //       store: user.company,
  //     }
  //     result = await userAPI.get_record_bystore(data, fomattedDate)
  //     length = result.data.length - 1
  //     setUsers(result.data.records)
  //     setCount(result.data.count)
  //   }
  //   /*const getCompany = await userAPI.get_user_company()*/

  //   setUser(user)

  //   setLoading(false)
  // }
  // useEffect(() => {
  //   load()
  // }, [])

  return (
    <>
      {isOtpValid ? !isValidated
        ?
        <Dialog open={passwordDialog}>
          <AppBar sx={{ position: "relative" }}>
            <DialogTitle id="alert-dialog-title">
              Time Adjustment!
              <IconButton
                aria-label="close"
                onClick={handleClosePasswordDialog}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
          </AppBar>
          <DialogContent>
            Enter Password to use this feature!
            <br />
            <br />
            <TextField id="standard-basic" label="Enter Password" variant="standard" value={password} onChange={(e) => setPassword(e.target.value)} required />
            <Button sx={{ ml: 3, mt: 1 }} variant="contained" onClick={handleValidatePassword}>Submit</Button>
          </DialogContent>
        </Dialog>
        :
        <Page title="User Archive | Time In">
          <Container>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <Typography variant="h4" gutterBottom>
                Time Adjustment
              </Typography>
            </Stack>

            <Card>

              <Scrollbar>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    {user && user.role >= 3 && (
                      <FormControl sx={{ mt: 4, ml: 3, width: "85%" }}>
                        <InputLabel id="store-select-label">Store</InputLabel>
                        <Box display="flex" alignItems="center">
                          <Select
                            labelId="store-select-label"
                            id="store-select"
                            value={selectedCompany || company[0] || ''}
                            label="Store"
                            onChange={handleStoreChange}
                            sx={{ flexGrow: 1 }} // Ensures Select takes remaining space
                          >
                           {company.slice().sort((a, b) => a.trim().replace(/\s+/g, ' ').localeCompare(b.trim().replace(/\s+/g, ' '))).map((item) => (
                                <MenuItem key={item} value={item}>
                                  {item}
                                </MenuItem>
                            ))}
                          </Select>
                          <IconButton color="primary" aria-label="add store" onClick={handleOpenQRModal} sx={{ ml: 1 }}>
                            <AddIcon />
                          </IconButton>
                        </Box>
                      </FormControl>
                    )}
                  </Grid>
                  <Grid item>
                    <Box
                      sx={{ display: 'flex', alignItems: 'center', m: 3 }}
                    >
                      <TextField
                       sx={{ width: { xs: '100%', sm: '300px' } }} // Set a smaller width for the search bar
                        id="outlined-basic"
                        value={recordId}
                        onChange={(e) => handleSearch(e)}
                        label="Search by ID"
                        variant="outlined"
                      />
                      <Button
                        sx={{ alignItems: 'end' }}
                        style={{ height: "150%", width: "auto", marginLeft: "10px" }}
                        variant="contained"
                        onClick={handleOpen}
                      >
                        Logs
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
                <TableContainer component={Paper}>
                  <Table sx={{ width: "100%", minWidth: 1000 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Proccess ID</TableCell>
                        <TableCell align="right">Date</TableCell>
                        <TableCell align="right">Time-in</TableCell>
                        <TableCell align="right">Break-in</TableCell>
                        <TableCell align="right">Break-out</TableCell>
                        <TableCell align="right">Time-out</TableCell>
                        <TableCell align="right">Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {users.length > 0
                        ?
                        <>
                          {users.map((row) => (
                            <TableRow
                              key={row._id}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell component="th" scope="row">
                                {row._id}
                              </TableCell>
                              <TableCell align="right">{moment(row.date).format('MMMM-DD-YYYY')}</TableCell>
                              <TableCell align="right">{filterByStatus(row.record, 'time-in').time + " - " + filterByStatus(row.record, 'time-in').workmate}</TableCell>
                              <TableCell align="right">{filterByStatus(row.record, 'break-in').time + " - " + filterByStatus(row.record, 'break-in').workmate}</TableCell>
                              <TableCell align="right">{filterByStatus(row.record, 'break-out').time + " - " + filterByStatus(row.record, 'break-out').workmate}</TableCell>
                              <TableCell align="right">{filterByStatus(row.record, 'time-out').time + " - " + filterByStatus(row.record, 'time-out').workmate}</TableCell>
                              <TableCell align="right"><UserMoreMenuArchive id={row._id} action={checkIfDone} data={row} /></TableCell>
                            </TableRow>
                          ))}
                        </>
                        :
                        ''
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
            </Card>
          </Container>
        </Page>
        :
        <Dialog open={passwordDialog}>
          <AppBar sx={{ position: "relative" }}>
            <DialogTitle id="alert-dialog-title">
              Time Adjustment!
              <IconButton
                aria-label="close"
                onClick={handleClosePasswordDialog}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
          </AppBar>
          <DialogContent>
            We sent an OTP to your email account, please input Otp
            <br />
            <br />
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                renderSeparator={<span>-</span>}
                renderInput={(props) => <input {...props}
                  style={{
                    width: '40px', // Adjust the width as needed
                    height: '40px', // Adjust the height as needed
                    fontSize: '16px', // Adjust the font size as needed
                    margin: '0 5px', // Adjust the margin as needed
                    padding: '5px', // Adjust the padding as needed
                    textAlign: 'center', // Center the text inside the box
                  }}
                />}
              />
              <Button sx={{ ml: 3, mt: 5 }} variant="contained" onClick={handleChangeOtp}>Submit OTP</Button>
            </Box>
            <br />
          </DialogContent>
        </Dialog>

      }
      <Dialog 
        open={open} 
        onClose={handleClose} 
        fullWidth 
        maxWidth={false}
        PaperProps={{
          sx: {
            width: '80%', // Adjust to desired width
            height: '80%', // Adjust to desired height
            maxWidth: 'none', // Ensures it doesn't shrink based on `maxWidth`
          },
        }}
      >
        <DialogTitle>Time adjustment logs</DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 1000}} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Email</TableCell>
                  <TableCell align="right">Name</TableCell>
                  <TableCell align="right">Processid</TableCell>
                  <TableCell align="right">Description</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {logs.length > 0
                  ?
                  <>
                    {logs.map((row) => (
                      <TableRow
                        key={row._id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          {row.email}
                        </TableCell>
                        <TableCell align="right">{row.name}</TableCell>
                        <TableCell align="right">{row.processid}</TableCell>
                        <TableCell align="right">{row.description}</TableCell>
                      </TableRow>
                    ))}
                  </>
                  :
                  ''
                }
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openQR} onClose={handleCloseQR}>
        <DialogTitle>Scan store QR code.
          <IconButton
            aria-label="close"
            onClick={handleCloseQR}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle >
        <DialogContent>
          <>
            <QrReader
              delay={3000}
              onError={handleError}
              onScan={handleScan}
              // chooseDeviceId={()=>selected}
              style={{ width: '250px', heigth: '200px' }}
            // className={'qrScanner'}
            />
            {/*<DialogTitle style={{ display: "flex", justifyContent: "center" }}><a href="https://www.sparkletimekeeping.com/store/create">Register store account</a></DialogTitle>*/}
          </>
        </DialogContent>
      </Dialog>
    </>
  )
}