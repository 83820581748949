import React from 'react'
import Page from '../components/Page'
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Feedbacks from 'components/feedbacks'

const Feedback = () => {
  return (
    <Page title="STK  Feedbacks">
      <Container maxWidth="xl">
        <HeaderBreadcrumbs
          heading="Sparkle Timekeeping feedbacks"
          links={[{name: 'Dashboard', href: '/stores/app'}, {name: 'Feedbacks'}]}
        />

        <Box>
          <Feedbacks />
        </Box>
      </Container>
    </Page>
  )
}

export default Feedback
