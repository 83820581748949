import React from 'react'
import { filter } from 'lodash'
import { useEffect, useState } from 'react'
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Modal,
  Backdrop,
  Fade,
  Box,
  Grid,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide,
  Autocomplete,
  TableHead
} from '@material-ui/core'
import CircularProgress from '@mui/material/CircularProgress';
// components
import Page from '../components/Page'
import Label from '../components/Label'
import Scrollbar from '../components/Scrollbar'
import SearchNotFound from '../components/SearchNotFound'
import { UserListHead, UserListToolbar } from '../components/_dashboard/user'
import AdminMoreMenu from '../components/_dashboard/user/AdminMoreMenu'
import userAPI from 'utils/api/users'
import storage from 'utils/storage'
import Bugsnag from '@bugsnag/js'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
const moment = require('moment-timezone')
moment().tz('Asia/Manila').format()
const current_date = `${moment().tz('Asia/Manila').toISOString(true)}`
const TABLE_HEAD = [
  { id: 'store', label: 'Store', alignRight: false },
  { id: 'feature', label: 'Feature', alignRight: false },
  { id: 'subscription-type', label: 'Subscription Type', alignRight: false },
  { id: 'price', label: 'Price', alignRight: false },
  { id: 'expiry', label: 'Expiry', alignRight: false },
]

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })

  if (query) {
    return filter(array, (_user) => (_user.Employee.displayName ? _user.Employee.displayName.toLowerCase().indexOf(query.toLowerCase()) !== -1 : ''))
  }
  return stabilizedThis.map((el) => el[0])
}

export default function User() {
  const [page, setPage] = useState(0)
  const [order, setOrder] = useState('asc')
  const [selected, setSelected] = useState([])
  const [company, setCompany] = useState([])
  const [subscriptions, setSubscriptions] = useState(["Users", "Time Adjustment", "Schedules", "Announcements"])
  const [subscriptionsRates, setSubscriptionsRate] = useState(["Monthly", "Yearly"])
  const [selectedCompany, setSelectedCompany] = useState('')
  const [selectedSubscription, setSelectedSubscription] = useState("Users")
  const [selectedSubscriptionRate, setSelectedSubscriptionRate] = useState("Monthly")
  const [orderBy, setOrderBy] = useState('name')
  const [filterName, setFilterName] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [users, setUsers] = useState([])
  const [open, setOpen] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [id, setId] = useState()
  const [name, setName] = useState()
  const [count, setCount] = useState()
  const d = new Date();
  const [date, setDate] = useState(d.setDate(d.getDate() - 1))
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
  })
  useEffect(() => {
    const load = async () => {
      setLoading(true)
      const local_user = await storage.getUser()
      if (!local_user) return

      const user = JSON.parse(local_user)
  	  const data = {
  	  	store: selectedCompany
  	  }
      const result = await userAPI.get_subscription(data)
      const getCompany = await userAPI.get_user_company()
      if (!result.ok) {
          Bugsnag.notify(result)
        return
      }   
      setUsers(result.data)
      setCompany(getCompany.data)
      setSelectedCompany(getCompany.data[0])
      setLoading(false)
    }

    load()
  }, [])

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const handleStoreChange = async (value) => {
    if (value === selectedCompany || value === null) return
    setLoading(true)
    setSelectedCompany(value)
    const results = await Promise.all(value.map(async (store) => {
      const data = { store };
      return await userAPI.get_subscription(data)
    }));
    const aggregatedData = results.reduce((acc, current) => acc.concat(current.data), []);
    setUsers(aggregatedData)
    setLoading(false)
  }

  const handleSubmit = async (e) => {
      const data = {
  		store: selectedCompany,
        feature: selectedSubscription,
        length: selectedSubscriptionRate
      }
  	const result = await userAPI.post_subscription(data)
  	if(result) {
        alert(result.data.msg)
  		setOpen(false)
  	}
  }

  return (
    <Page title="Admin | Time In">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Subscriptions
          </Typography>
        </Stack>

        <Card>
          <Grid container spacing={1}>
            <Grid item xs={3} md={4}>
              <FormControl sx={{ mt: 3, ml: 1, width: "100%" }}>
                  <Autocomplete
                    id="demo-simple-select-helper"
                    value={selectedCompany === null ? company[0] : selectedCompany}
                    onChange={(event, value) => handleStoreChange(value)}
                    options={company}
                    placeholder='Store'
                    renderInput={(params) => (
                      <TextField {...params} label="Store" />
                    )}
                    sx={{
                      flexGrow: 1,
                      whiteSpace: 'nowrap',
                      overflow: 'auto',
                      textOverflow: 'ellipsis',
                      pt: 1
                    }}
                  />
              </FormControl>
            </Grid>
            <Grid item xs={3} md={3}>
              <FormControl sx={{ mt: 4, ml: 1, width: "100%" }}>
                <InputLabel id="demo-simple-select-helper-label">Subscriptions</InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  isMulti
                  value={selectedSubscription}
                  label="Star Concorde Group"
                  onChange={(e) => setSelectedSubscription(e.target.value)}
                  className="basic-multi-select"
                  classNamePrefix="select"
                >
                  {
                    subscriptions.map(item => {
                      return (
                        <MenuItem value={item}>{item}</MenuItem>
                   
                      )
                    })
                  }
                </Select>
              </FormControl>
            </Grid>
	  		<Grid item xs={3} md={3}>
	  		  <FormControl sx={{ mt: 4, ml: 1, width: "100%" }}>
	  		    <InputLabel id="demo-simple-select-helper-label">Subscription Length</InputLabel>
	  		    
	  		    <Select
	  		      labelId="demo-simple-select-helper-label"
	  		      id="demo-simple-select-helper"
	  		      isMulti
	  		      value={selectedSubscriptionRate}
	  		      label="Star Concorde Group"
	  		      onChange={(e) => setSelectedSubscriptionRate(e.target.value)}
	  		      className="basic-multi-select"
	  		      classNamePrefix="select"
	  		    >
	  		      {
	  		        subscriptionsRates.map(item => {
	  		          return (
	  		            <MenuItem value={item}>{item}</MenuItem>
	  		       
	  		          )
	  		        })
	  		      }
	  		    </Select>
	  		  </FormControl>
	  		</Grid>
	  		<Grid item xs={2} md={1} ml={1} mt={4}>
	  		    <Button style={{"height": "95%", "width": "100%"}} variant="contained" onClick={handleOpen}>Submit</Button>
	  		</Grid>
          </Grid>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              {users && !isLoading ? (
                <Table>
                  <TableHead>
                    <UserListHead
                      headLabel={TABLE_HEAD}
                    />
                  </TableHead>
                  <TableBody>
                  	{users.map(user => {
                  		return (
                  			<TableRow
                  			  hover
                  			  key={user._id}
                  			  tabIndex={-1}
                  			>
                  			  <TableCell component="th" scope="row" padding="none">
                  			    <Stack direction="row" alignItems="center" spacing={2}>
                  			      <Typography variant="subtitle2" sx={{ml: 3}}>
                  			        {user.store}
                  			      </Typography>
                  			    </Stack>
                  			  </TableCell>
                  			  <TableCell align="left">{user.feature}</TableCell>
                  			  <TableCell align="left">{user.length}</TableCell>
                  			  <TableCell align="left">{user.price}</TableCell>
                  			  <TableCell align="left">{user.expiry}</TableCell>
                  			</TableRow>		
                  		)
                  	})}
                    
                  </TableBody>
                </Table>
              ) : (
                <Box sx={{ "margin-left": "500px", "margin-top": "50px", display: 'flex' }}>
                  <CircularProgress />
                </Box>
              )}
            </TableContainer>
          </Scrollbar>
        </Card>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">{'Do you wish to proceed with this action?'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Are you sure you want to enroll this store with this feature?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              No
            </Button>
            <Button onClick={handleSubmit} color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Page>
  )
}
